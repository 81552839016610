import { AxiosError } from 'axios';
// eslint-disable-next-line import/no-extraneous-dependencies
import Cropper from 'cropperjs';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { FileError } from 'react-dropzone';
import { Auth } from 'components/Auth/Auth';
import { useApiService } from '../../../contexts/ApiServiceContext/ApiServiceContext';
import { ExpressionConfig } from '../../../dorian-shared/types/character/ExpressionConfig';
import { User } from '../../../dorian-shared/types/user/User';
import { bugTracker } from '../../../services/bugTracker/BugTrackerService';
import { logger } from '../../../services/loggerService/loggerService';
import {
  IMAGE_ASPECT_RATIO,
  IMAGE_HEIGHT_UPLOAD,
  IMAGE_MIN_HEIGHT,
  IMAGE_MIN_WIDTH,
  IMAGE_WIDTH_UPLOAD,
  getCanvasBlob,
} from '../Backgrounds/UploadImage';
import {
  FileToUpload, LibraryType, SelectedFilesToUpload, UploadFilesModalStatus,
} from './types';

export function useUploadFilesModal(
  libraryType: LibraryType,
  onUploaded?: (files: FileToUpload[]) => void,
  maxFiles?: number,
) {
  const apiService = useApiService();

  const [filesToUpload, setFilesToUpload] = useState<FileToUpload[]>([]);
  const [expressionNames, setExpressionNames] = useState<ExpressionConfig[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isUploadFinished, setIsUploadFinished] = useState(false);
  const [authUser, setAuthUser] = useState<User | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const user = useMemo(() => new Auth().getUser(), []);

  useEffect(() => {
    setAuthUser(user);
  }, [user]);

  useEffect(() => {
    setIsLoading(true);
    apiService.fetchExpressionNames()
      .then((response) => {
        if (response) {
          setExpressionNames(response);
        }
      })
      .catch((error) => {
        bugTracker().reportError(error);
      })
      .finally(() => setIsLoading(false));
  }, [apiService]);

  useEffect(() => {
    if (maxFiles && filesToUpload.length > maxFiles) {
      setErrorMessage(`You can upload a maximum of ${maxFiles} files at a time`);
    } else {
      setErrorMessage(null);
    }
  }, [filesToUpload.length, maxFiles]);

  const getExpressionName = useCallback((expression: string): string | null => {
    if (!expressionNames) {
      return null;
    }
    const expressionName = expressionNames.find((exprNane) => {
      const isAsValue = exprNane.value.toLowerCase().trim() === expression?.toLowerCase().trim();
      const isAsTitle = exprNane.title.toLowerCase().trim() === expression?.toLowerCase().trim();
      const isAsId = exprNane.id.toString() === expression;
      return isAsValue || isAsTitle || isAsId;
    });
    return expressionName?.value ?? null;
  }, [expressionNames]);

  const checkImageSizes = useCallback(async (file: File) => new Promise((resolve) => {
    const image = new Image();
    image.src = URL.createObjectURL(file);
    image.onload = () => {
      if (image.width < IMAGE_MIN_WIDTH || image.height < IMAGE_MIN_HEIGHT) {
        resolve(false);
      }
      resolve(true);
    };
  }), []);

  const handleDropZoneChange = useCallback(async (files: SelectedFilesToUpload[]) => {
    const newValues = files.map(async (file) => {
      // TODO: Extract to external function
      const match = file.file.name.match(/_+(\w+)\.\w+$/);
      const expression = match?.[1] ?? '';

      const name = file.file.name.replace(/.*\/|\.\w+$/g, '');

      const isImageSizeValid = await checkImageSizes(file.file);

      if (!isImageSizeValid) {
        return {
          ...file,
          name,
          aliasName: name,
          expressionName: null,
          status: UploadFilesModalStatus.Error,
          errors: [{ message: `Image size is less than ${IMAGE_MIN_WIDTH}px x ${IMAGE_MIN_HEIGHT}px`, code: 'error' }],
        } as FileToUpload;
      }

      if (libraryType === LibraryType.Background) {
        return {
          ...file,
          name,
          aliasName: name,
          expressionName: null,
        } as FileToUpload;
      }
      const expressionName = getExpressionName(expression);
      if (!expressionName) {
        return {
          ...file,
          name,
          aliasName: name,
          expressionName: null,
          status: UploadFilesModalStatus.Error,
          errors: [{ message: 'Expression not found', code: 'error' }],
        } as FileToUpload;
      }

      return {
        ...file,
        name,
        aliasName: name,
        expressionName,
      } as FileToUpload;
    });
    const newValuesResolved = await Promise.all(newValues);
    setFilesToUpload(newValuesResolved);
  }, [checkImageSizes, getExpressionName, libraryType]);

  const updateStatus = (alias: string, status: UploadFilesModalStatus) => {
    const index = filesToUpload.findIndex((fileToUpload) => fileToUpload.aliasName === alias);
    if (index !== -1) {
      filesToUpload[index].status = status;
      setFilesToUpload([...filesToUpload]);
    }
  };

  const updateProgress = (alias: string, progress: number) => {
    const index = filesToUpload.findIndex((fileToUpload) => fileToUpload.aliasName === alias);
    if (index !== -1) {
      filesToUpload[index].progress = progress;
      setFilesToUpload([...filesToUpload]);
    }
  };

  const updateStatusError = (alias: string, errors: FileError[] | undefined) => {
    const index = filesToUpload.findIndex((fileToUpload) => fileToUpload.aliasName === alias);
    if (index !== -1) {
      filesToUpload[index].status = UploadFilesModalStatus.Error;
      filesToUpload[index].errors = errors;
      setFilesToUpload([...filesToUpload]);
    } else {
      logger.debug('updateStatusError: index not found');
    }
  };

  const updateFile = (alias: string, file: File) => {
    const index = filesToUpload.findIndex((fileToUpload) => fileToUpload.aliasName === alias);
    if (index !== -1) {
      filesToUpload[index].file = file;
      setFilesToUpload([...filesToUpload]);
    }
  };

  const getCropImagePromises = () => filesToUpload
    .map(async (fileToUpload) => new Promise<void>((resolve, reject) => {
      const image = document.createElement('img');
      image.src = URL.createObjectURL(fileToUpload.file);
      const body = document.querySelector('body');
      if (body) {
        body.appendChild(image);
      }
      const cropperJs: Cropper = new Cropper(image, {
        aspectRatio: IMAGE_ASPECT_RATIO,
        autoCrop: true,
        autoCropArea: 1,
        minCropBoxHeight: libraryType === LibraryType.Character ? IMAGE_WIDTH_UPLOAD : undefined,
        minCropBoxWidth: libraryType === LibraryType.Character ? IMAGE_HEIGHT_UPLOAD : undefined,
        ready: async () => {
          const croppedCanvas = cropperJs.getCroppedCanvas(
            {
              width: IMAGE_WIDTH_UPLOAD,
              height: IMAGE_HEIGHT_UPLOAD,
              imageSmoothingQuality: 'high',
              imageSmoothingEnabled: true,
            },
          );

          if (!croppedCanvas) {
            cropperJs.destroy();
            body?.removeChild(image);
            reject(new Error('Error: Can\'t get croppedCanvas image'));
          }

          const fileType = libraryType === LibraryType.Character ? 'image/png' : 'image/jpeg';
          let blob = new Blob();

          try {
            blob = await getCanvasBlob(croppedCanvas, fileType, 1);
          } catch (error) {
            if (error instanceof Error) {
              bugTracker().reportError(error);
            } else {
              bugTracker().reportError(new Error('Error: Can\'t get cropped image'));
            }
            cropperJs.destroy();
            body?.removeChild(image);
            reject(new Error('Error: Can\'t get cropped image'));
          }

          const file = new File([blob], fileToUpload.file.name);
          updateFile(fileToUpload.aliasName, file);
          cropperJs.destroy();
          body?.removeChild(image);
          resolve();
        },
      });
    }));

  const handleUploadProgress = (progressEvent: ProgressEvent, aliasName: string) => {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    updateProgress(aliasName, percentCompleted);
  };

  const handleUploadClick = async () => {
    if (maxFiles && filesToUpload.length > maxFiles) {
      setErrorMessage(`You can upload a maximum of ${maxFiles} files at a time`);
      return;
    }

    if (!authUser) {
      setErrorMessage('You need to be logged in to upload files');
      return;
    }
    setErrorMessage(null);
    setIsLoading(true);

    try {
      const cropImagePromises = getCropImagePromises();
      await Promise.all(cropImagePromises);
    } catch (error) {
      if (error instanceof Error) {
        bugTracker().reportError(error);
      } else {
        bugTracker().reportError(new Error('Error: Can\'t crop images'));
      }
      setIsLoading(false);
      return;
    }

    const requests: Promise<AxiosError | void>[] = [];

    for (let i = 0; i < filesToUpload.length; i += 1) {
      const fileToUpload = filesToUpload[i];
      if (fileToUpload.status !== UploadFilesModalStatus.Ready) {
        // eslint-disable-next-line no-continue
        continue;
      }
      updateStatus(fileToUpload.aliasName, UploadFilesModalStatus.Uploading);

      const formData = new FormData();
      formData.append('alias', fileToUpload.aliasName);
      formData.append('title', fileToUpload.name);
      formData.append('label', fileToUpload.name);
      formData.append('disabled', 'false');
      formData.append('authorId', authUser.id.toString());
      formData.append('policyId', authUser.role === 'admin' ? '1' : '2');

      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
        onUploadProgress: (
          progressEvent: ProgressEvent,
        ) => handleUploadProgress(progressEvent, fileToUpload.aliasName),
      };
      formData.append('image', fileToUpload.file);

      switch (libraryType) {
        case LibraryType.Character: {
          const request = apiService.uploadCharacterFile(formData, config)
            .then(() => {
              updateStatus(fileToUpload.aliasName, UploadFilesModalStatus.Uploaded);
            })
            .catch((error) => {
              if (error instanceof AxiosError) {
                const errorMessageResponse = error?.response?.data
                  ? error?.response?.data.error
                  : 'Error: Can\'t send image to server';

                // TODO: Remove when https://dorian.atlassian.net/browse/DOR-5081 is done
                const fallbackErrorMessage = 'This alias is already taken.';
                const message = errorMessageResponse === 'Validation error'
                  ? fallbackErrorMessage
                  : errorMessageResponse;

                updateStatusError(fileToUpload.aliasName, [{ message, code: 'error' }]);
              } else if (error instanceof Error) {
                updateStatusError(fileToUpload.aliasName, [{ message: error.message, code: 'error' }]);
              } else {
                updateStatusError(fileToUpload.aliasName, [{ message: 'Unknown upload error', code: 'error' }]);
              }
            });
          requests.push(request);
          break;
        }
        case LibraryType.Background: {
          const request = apiService.uploadBackgroundFile(formData, config)
            .then(() => {
              updateStatus(fileToUpload.aliasName, UploadFilesModalStatus.Uploaded);
            })
            .catch((error) => {
              if (error instanceof AxiosError) {
                const errorMessageResponse = error?.response?.data
                  ? error?.response?.data.error
                  : 'Error: Can\'t send image to server';
                updateStatusError(fileToUpload.aliasName, [{ message: errorMessageResponse, code: 'error' }]);
              } else if (error instanceof Error) {
                updateStatusError(fileToUpload.aliasName, [{ message: error.message, code: 'error' }]);
              } else {
                updateStatusError(fileToUpload.aliasName, [{ message: 'Unknown upload error', code: 'error' }]);
              }
            });
          requests.push(request);
          break;
        }
        default:
          throw new Error('Unknown library type');
      }
    }

    Promise.all(requests).then(() => {
      setIsUploadFinished(true);
    }).finally(() => setIsLoading(false));
  };

  const manageListCallbacks = useMemo(() => ({
    onDelete: (fileToUpload: FileToUpload) => {
      const newValues = filesToUpload.filter((file) => file !== fileToUpload);
      setFilesToUpload(newValues);
    },
    onUploaded: (files: FileToUpload[]) => {
      onUploaded?.(files);
    },
  }), [filesToUpload, onUploaded]);

  return {
    isLoading,
    setIsLoading,
    filesToUpload,
    expressionNames,
    handleDropZoneChange,
    manageListCallbacks,
    handleUploadClick,
    isUploadFinished,
    errorMessage,
  };
}
