import { maxBy, minBy } from 'lodash-es';
import {
  CheckOperator, MemoryCheckValueType,
  MemoryDTO,
  MemoryFormDTO,
  MemoryShowIn,
  MemoryValue,
  StepCheck,
  StepCheckOperatorType,
  StepCheckSwitch,
} from '../../components/pages/Book/MemoryBank/memoryBankTypes';
import { getMemorySlotVariableLink } from '../../components/utils/choiceMemoryUtils';
import { bugTracker } from '../bugTracker/BugTrackerService';
import { MemoryBankService } from '../memoryBankService/MemoryBankService';
import { ChoiceMemoryStack } from './types/ChoiceMemoryStack';

export class ChoiceMemory {
  private readonly choiceMemoryStack: ChoiceMemoryStack[];
  private variables: { [ key: string ]: MemoryFormDTO};
  private memoryBankService: MemoryBankService;

  constructor() {
    this.memoryBankService = new MemoryBankService();
    this.choiceMemoryStack = [];
    this.variables = {};
  }

  async fetchVariables(bookId: number) {
    const memoryBank = await this.memoryBankService.fetchMemoryBank(bookId);
    memoryBank.forEach((variable: MemoryDTO) => {
      this.variables[variable.name] = {
        value: variable.defaultValue,
        showInAlert: variable.showIn.includes(MemoryShowIn.Alert),
        showInPanel: variable.showIn.includes(MemoryShowIn.Panel),
        ...variable,
      };
    });
    this.setCurrentVariables(this.variables);
  }

  push(stackItem: ChoiceMemoryStack) {
    this.choiceMemoryStack.push(stackItem);
  }

  pop(): ChoiceMemoryStack | undefined {
    const removerItem = this.choiceMemoryStack.pop();
    return removerItem;
  }

  get last(): ChoiceMemoryStack | undefined {
    return { ...this.choiceMemoryStack[this.choiceMemoryStack.length - 1] };
  }

  get currentVariables(): { [ key: string ]: MemoryFormDTO} {
    return this.variables;
  }

  saveCurrentVariables() {
    const variables = Object.values(this.variables).map((variable) => ({
      name: variable.name,
      value: variable.value,
      id: variable.id,
    }));
    localStorage.setItem('choiceMemoryVariables', JSON.stringify(variables));
  }

  setCurrentVariables(variables: { [ key: string ]: MemoryFormDTO}) {
    this.variables = variables;
    this.saveCurrentVariables();
  }

  getVariableById(id: number) {
    const variable = Object.values(this.variables)
      .find((variableItem) => Number(variableItem.id) === Number(id));
    if (variable) {
      return variable;
    }
    return undefined;
  }

  extractVariablesFromString(text: string) {
    const variableRegex = /\{([A-Za-z0-9_.]+)\}/g;
    const matches = text.match(variableRegex);
    if (matches) {
      return matches.map((match) => match.substring(1, match.length - 1));
    }
    return [];
  }

  getVariableByName(varName: string) {
    const variable = this.variables[varName];
    if (variable) {
      return variable;
    }
    return undefined;
  }

  setValueByVarName(varName: string, value: string | number) {
    this.variables[varName].value = value;
  }

  getValueById(id: number): MemoryValue | undefined {
    const variable = this.getVariableById(id);
    if (variable) {
      const isNumber = variable.type === 'number';
      return isNumber ? Number(variable.value) : variable.value;
    }
    return undefined;
  }

  setValueById(id: number, value: string | number) {
    const variable = this.getVariableById(id);
    if (variable) {
      this.variables = { ...this.variables, [variable.name]: { ...variable, value } };
    }
    this.setCurrentVariables(this.variables);
  }

  // This method is used in onBeforeTextRender because onBeforeTextRender
  // is called before onNavHistoryStackChanged
  getVariableFromLastStackItem(stepId: number, variableName: string) {
    if (this.choiceMemoryStack.length <= 1) {
      return undefined;
    }
    const stackItem = this.choiceMemoryStack[this.choiceMemoryStack.length - 2];
    if (stackItem) {
      return stackItem.variables[variableName];
    }
    return undefined;
  }

  getGotoBranchIdByCheck(check: StepCheck, gotoSwitches: StepCheckSwitch[]): number | undefined {
    const checkOperator = CheckOperator[check.operator];
    if (!checkOperator) {
      bugTracker().reportError({ name: '[AT-CM]', message: `Operator ${check.operator} is not found` });
      return undefined;
    }

    if (checkOperator.isMultiSelect) {
      try {
        return this._getGotoBranchByMultiSelectedMemory(check, gotoSwitches);
      } catch (error) {
        bugTracker().reportError({ name: '[AT-CM]', message: 'Error in getGotoBranchIdByCheck', cause: error });
        return undefined;
      }
    }

    try {
      return this._getGotoBranchByOneSelectedMemory(check, gotoSwitches);
    } catch (error) {
      bugTracker().reportError({ name: '[AT-CM]', message: 'Error in getGotoBranchIdByCheck', cause: error });
      return undefined;
    }
  }

  private _getGotoBranchByMultiSelectedMemory(check: StepCheck, gotoSwitches: StepCheckSwitch[]) {
    const memoryLinkValue = getMemorySlotVariableLink(check.value);
    if (!memoryLinkValue) {
      throw new Error('MemoryLinkValue is not found');
    }

    if (memoryLinkValue.type !== MemoryCheckValueType.VariableArray) {
      throw new Error('MemoryLinkValue is not an array');
    }

    const memories = memoryLinkValue.variableId.map((id) => this.getVariableById(Number(id)));

    switch (check.operator) {
      case StepCheckOperatorType.Min: {
        const minMemory = minBy(memories, (memory) => Number(memory?.value));
        const minSwitch = gotoSwitches.find((gotoSwitch) => Number(gotoSwitch.value) === minMemory?.id);
        return minSwitch ? Number(minSwitch.gotoBranchId) : undefined;
      }
      case StepCheckOperatorType.Max: {
        const maxMemory = maxBy(memories, (memory) => Number(memory?.value));
        const maxSwitch = gotoSwitches.find((gotoSwitch) => Number(gotoSwitch.value) === maxMemory?.id);
        return maxSwitch ? Number(maxSwitch.gotoBranchId) : undefined;
      }
      default:
        throw new Error(`Unknown operator ${check.operator}`);
    }
  }

  private _getGotoBranchByOneSelectedMemory(check: StepCheck, gotoSwitches: StepCheckSwitch[]) {
    const comparedVariable = this.getVariableById(check.variableId);

    if (!comparedVariable) {
      throw new Error(`Variable ${check.variableId} is not found`);
    }

    const variableValue = this.getValueById(check.variableId);

    if (variableValue === undefined) {
      throw new Error(`Variable ${check.variableId} value is not found`);
    }

    const memoryLink = getMemorySlotVariableLink(check.value);
    const memoryLinkValue = this.getValueById(Number(memoryLink?.variableId));

    if (memoryLink !== undefined && memoryLinkValue === undefined) {
      throw new Error(`Variable ${memoryLink.variableId} value is not found`);
    }

    const slotCheckValue = memoryLink
      ? this.getValueById(Number(memoryLink.variableId)) ?? ''
      : check.value;

    const isNumber = comparedVariable.type === 'number';

    if (isNumber && Number.isNaN(Number(slotCheckValue))) {
      throw new Error(`Check value is not a number in variableId: ${check.variableId}`);
    }

    if (isNumber && Number.isNaN(Number(variableValue))) {
      throw new Error(`Check value is not a number in variableId: ${check.variableId}`);
    }

    const checkValue = isNumber ? Number(slotCheckValue) : String(slotCheckValue).toLowerCase();
    const comparedValue = isNumber ? Number(variableValue) : String(variableValue).toLowerCase();

    const gotoBranchIdTrue = gotoSwitches.find(
      (gotoSwitch: StepCheckSwitch) => String(gotoSwitch.value) === 'true',
    );
    const gotoBranchIdFalse = gotoSwitches.find(
      (gotoSwitch: StepCheckSwitch) => String(gotoSwitch.value) === 'false',
    );

    switch (check.operator) {
      case StepCheckOperatorType.Equal: {
        if (comparedValue === checkValue) {
          return gotoBranchIdTrue ? Number(gotoBranchIdTrue.gotoBranchId) : undefined;
        }
        return gotoBranchIdFalse ? Number(gotoBranchIdFalse.gotoBranchId) : undefined;
      }
      case StepCheckOperatorType.NotEqual: {
        if (comparedValue !== checkValue) {
          return gotoBranchIdTrue ? Number(gotoBranchIdTrue.gotoBranchId) : undefined;
        }
        return gotoBranchIdFalse ? Number(gotoBranchIdFalse.gotoBranchId) : undefined;
      }
      case StepCheckOperatorType.Greater: {
        if (comparedValue > checkValue) {
          return gotoBranchIdTrue ? Number(gotoBranchIdTrue.gotoBranchId) : undefined;
        }
        return gotoBranchIdFalse ? Number(gotoBranchIdFalse.gotoBranchId) : undefined;
      }
      case StepCheckOperatorType.AtLeast: {
        if (comparedValue >= checkValue) {
          return gotoBranchIdTrue ? Number(gotoBranchIdTrue.gotoBranchId) : undefined;
        }
        return gotoBranchIdFalse ? Number(gotoBranchIdFalse.gotoBranchId) : undefined;
      }
      case StepCheckOperatorType.Less: {
        if (comparedValue < checkValue) {
          return gotoBranchIdTrue ? Number(gotoBranchIdTrue.gotoBranchId) : undefined;
        }
        return gotoBranchIdFalse ? Number(gotoBranchIdFalse.gotoBranchId) : undefined;
      }
      case StepCheckOperatorType.AtMost: {
        if (comparedValue <= checkValue) {
          return gotoBranchIdTrue ? Number(gotoBranchIdTrue.gotoBranchId) : undefined;
        }
        return gotoBranchIdFalse ? Number(gotoBranchIdFalse.gotoBranchId) : undefined;
      }
      default:
        throw new Error(`Unknown operator ${check.operator}`);
    }
  }
}
