export enum MemoryCheckValueType {
  Variable = 'variable',
  VariableArray = 'variable[]',
}

export type MemorySingleLink = {
  type: MemoryCheckValueType.Variable;
  variableId: string;
}

export type MemoryArrayLink = {
  type: MemoryCheckValueType.VariableArray;
  variableId: string[];
}

export type MemoryLinkValue = MemorySingleLink | MemoryArrayLink;

export type MemoryValue = string | number;

export enum MemoryType {
  String = 'string',
  Number = 'number',
  Boolean = 'boolean',
  Character = 'character',
}

export const MemoryTypeLabel: Record<MemoryType, string> = {
  [MemoryType.String]: 'Text',
  [MemoryType.Number]: 'Number',
  [MemoryType.Boolean]: 'Yes/No',
  [MemoryType.Character]: 'Role',
};

export enum MemoryShowIn {
  Alert = 'alert',
  Panel = 'panel',
}

export type MemoryDTO = {
  id: number,
  type: MemoryType,
  name: string,
  defaultValue: MemoryValue,
  displayName: string,
  defaultChangeDescription: string,
  icon: number,
  showIn: Array<MemoryShowIn>,
  bookId: number,
}

export enum StepCheckOperatorType {
  Equal = 'equal',
  NotEqual = 'notEqual',
  Greater = 'greater',
  AtLeast = 'atLeast',
  Less = 'less',
  AtMost = 'atMost',
  Min = 'min',
  Max = 'max',
}

export type CardMemorySlotData = {
  name: string,
  value: MemoryValue,
  operator: StepCheckOperatorType,
}

export type MemoryFormDTO = Omit<MemoryDTO, 'defaultValue'> & {
    value: MemoryValue,
    showInAlert: boolean,
    showInPanel: boolean,
}

export type MemoryIcon = {
  id: number,
  bookId: number,
  userId: number,
  key: string,
  label: string,
  type: string,
  url: string,
}

export type StepCheck = {
  variableId: number,
  operator: StepCheckOperatorType,
  value: MemoryValue | MemoryLinkValue,
}

export type StepCheckSwitch = {
  value: boolean | string,
  gotoBranchId: number,
}

export type StepCheckOperator = {
  title: string,
  short: string,
  isMultiSelect: boolean,
}

export const CheckOperator: Record<StepCheckOperatorType, StepCheckOperator> = {
  [StepCheckOperatorType.Equal]: {
    title: 'Exactly',
    short: '=',
    isMultiSelect: false,
  },
  [StepCheckOperatorType.NotEqual]: {
    title: 'Not exactly',
    short: '≠',
    isMultiSelect: false,
  },
  [StepCheckOperatorType.Greater]: {
    title: 'More than',
    short: '>',
    isMultiSelect: false,
  },
  [StepCheckOperatorType.AtLeast]: {
    title: 'At least',
    short: '≥',
    isMultiSelect: false,
  },
  [StepCheckOperatorType.Less]: {
    title: 'Less than',
    short: '<',
    isMultiSelect: false,
  },
  [StepCheckOperatorType.AtMost]: {
    title: 'At most',
    short: '≤',
    isMultiSelect: false,
  },
  [StepCheckOperatorType.Min]: {
    title: 'Lowest',
    short: 'Min',
    isMultiSelect: true,
  },
  [StepCheckOperatorType.Max]: {
    title: 'Highest',
    short: 'Max',
    isMultiSelect: true,
  },
};
