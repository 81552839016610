import React from 'react';
import { RouteComponentProps } from 'react-router';
// import { baseURL } from '../api';
import { Home } from '../pages/Home';

const LIVE_HOSTS = ['dorian.live', 'www.dorian.live', 'editor.live.4dorian.net']
const STAGING_DOMAIN = '.staging.test.4dorian.net'

const REDIRECT_HASHES = [
  '#info-collect',
  '#info-use',
  '#info-shared',
  '#cookies-tracking',
  '#social-logins',
  '#third-party',
  '#info-time-kept',
  '#info-safety',
  '#info-minors',
  '#privacy-rights',
  '#do-not-track',
  '#do-not-track',
  '#privacy-updates',
  '#contact',
  '#write-with-us',
  '#sign-in',
  '#lost-password',
  '#privacy-policy',
  '#congratulations',
  '#terms-of-use',
  '#copyright-policy'
]

interface RedirectConfig {
  enabled: boolean
  url: string
}

const getRedirectConfig = (): RedirectConfig => {
  const hostname = String(window.location.hostname).trim().toLowerCase()

  if (LIVE_HOSTS.includes(hostname)) {
    return {
      enabled: true,
      url: 'https://home.dorian.live/',
    }
  }
  if (hostname.endsWith(STAGING_DOMAIN)) {
    return {
      enabled: true,
      url: 'https://dorian.squarespace.com/',
    }
  }

  return {
    enabled: false,
    url: 'https://dorian.squarespace.com/',
  }
}

export function LandingPageRedirect(props: RouteComponentProps) {
  const { history } = props

  const config = getRedirectConfig()
  if (!config.enabled || REDIRECT_HASHES.includes(history.location.hash)) {
    return <Home {...props} />
  }

  location.assign(config.url)
  
  return null
}
