import axios, { AxiosInstance } from 'axios';
import { omit } from 'lodash-es';
import { api, getPorpoiseSubdomain } from '../../components/api';
import { User, UserDocument } from '../../dorian-shared/types/user/User';
import {
  IdentityProvider,
  UserAuthenticationProps,
  UserGroup,
  UserLoginProvider,
  UserRegisterProps,
  UserResendProps,
  UserResetProps,
  UserVerifyProps,
} from '../../shared/types';
import { logger } from '../loggerService/loggerService';

interface UserProfile {
  auth_time: Date,
  email: string,
  email_verified: boolean,
  exp: number,
  groups: UserGroup,
  hash: string,
  iat: number,
  login_provider: UserLoginProvider,
  nickname: string | null,
  picture: string,
  signup_date: Date,
  sub: string,
  username: string | null,
  family_name: string | null, // last name
  given_name: string | null, // first name
}

class UserApi {
  private service: AxiosInstance;

  private readonly userURI: string;
  private profileLegacy: User | undefined = undefined;
  private profile: UserProfile | undefined = undefined;

  constructor() {
    this.userURI = `${getPorpoiseSubdomain('user')}/`;
    this.service = axios.create({
      baseURL: this.userURI,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  // Need save to local storage because when page is reloaded we lost auth profile data
  private setProfileToLocalStorage(data: UserProfile) {
    if (data) {
      localStorage.setItem('profile', JSON.stringify(data));
    }
  }

  private getProfileFromLocalStorage() : UserProfile | undefined {
    const data = localStorage.getItem('profile');

    if (data) {
      return JSON.parse(data);
    }
    return undefined;
  }

  private setProfileLegacyToLocalStorage(data: User) {
    if (data) {
      localStorage.setItem('user', JSON.stringify(data));
      this.profileLegacy = data;
    }
  }

  private getProfileLegacyFromLocalStorage() : User | undefined {
    const data = localStorage.getItem('user');
    if (data) {
      return JSON.parse(data);
    }
    return undefined;
  }

  async register(data: UserRegisterProps) {
    const response = await this.service.post('api/register', data);
    return response.data;
  }

  async verify(data: UserVerifyProps) {
    const response = await this.service.post('api/verify', data);
    return response.data;
  }

  async resendCode(data: UserResendProps) {
    const response = await this.service.post('api/resend', data);
    return response.data;
  }

  async authenticate(data: UserAuthenticationProps) {
    const response = await this.service.post('api/authenticate', data);
    this.setProfileToLocalStorage(response.data.data.user);
    return response.data;
  }

  async reset(data: UserResetProps) {
    const response = await this.service.post('api/reset', data);
    return response.data;
  }

  // I can't find UserResetConfirm type in shared/types/user.ts
  async resetConfirm(data: {
    username: string;
    verificationCode: string;
    newPassword: string;
  }) {
    const response = await this.service.post('api/resetConfirm', data);
    return response.data;
  }

  async fetchProfile() {
    const response = await this.service.get('/oauth/profile');
    this.setProfileToLocalStorage(response.data.data);
    this.profile = response.data.data;
    return response.data;
  }

  async checkAuthentication() {
    try {
      const response = await this.service.get('oauth/profile');
      // if (response.status === 401) {
      //   return false;
      // }
      const { error } = response.data;
      const isAuthenticated = !error;
      if (isAuthenticated) {
        return true;
      }
      this.profileLegacy = undefined;
      localStorage.clear();
      return false;
    } catch (e) {
      this.profileLegacy = undefined;
      localStorage.clear();
      return false;
    }
  }

  getProfile() {
    if (!this.profile) {
      this.profile = this.getProfileFromLocalStorage();
    }
    return this.profile;
  }

  getProfileLegacy(): User | undefined {
    if (!this.profileLegacy) {
      this.profileLegacy = this.getProfileLegacyFromLocalStorage();
      if (!this.profileLegacy) {
        return undefined;
      }
    }
    const profile = this.getProfile();
    return { ...this.profileLegacy, username: profile?.username ?? '' };
  }

  // For compatibility with legacy code. Some components use Auth.setUser() method
  updateProfileLegacy(user: Partial<User>) {
    if (this.isAuthenticated() && user) {
      this.profileLegacy = { ...this.profileLegacy, ...user, username: this.profile?.username ?? '' } as User;
      this.setProfileLegacyToLocalStorage(this.profileLegacy);
    } else {
      logger.debug('User is not authenticated');
    }
  }

  isAuthenticated(): boolean {
    return typeof this.getProfileFromLocalStorage() !== 'undefined';
  }

  async fetchProfileLegacy(): Promise<User> {
    const apiMaintenanceResponse = await api.get('/v1/user/profile');
    const { user } = apiMaintenanceResponse.data;

    this.setProfileLegacyToLocalStorage(user);
    this.profileLegacy = user;
    return user;
  }

  login(provider?: IdentityProvider) {
    if (provider !== undefined) {
      const path = `oauth/login?origin=${encodeURIComponent(window.location.toString())}&provider=${encodeURIComponent(provider)}`;
      window.location.href = `${this.userURI}${path}`;
    } else {
      const path = `oauth/login?origin=${encodeURIComponent(window.location.toString())}`;
      window.location.href = `${this.userURI}${path}`;
    }
  }

  async logout() {
    localStorage.clear();
    sessionStorage.clear();
    // redirect to cognito logout
    window.location.href = `${this.userURI}/oauth/logout`;
  }

  async putUserName(userName: string) {
    const response = await this.service.post('api/data/username', { username: userName });
    await this.fetchProfile();
    return response.data;
  }

  async putAndUpdateProfileLegacy(userData: Partial<User>) {
    await api.put('/v1/user/profile', { ...userData });
    const profileLegacy = { ...this.profileLegacy, ...userData } as User;
    this.setProfileLegacyToLocalStorage(profileLegacy);
  }

  async putProfile(userData: Partial<User>) {
    if (userData.username
        && this.profile?.username
        && userData.username !== this.profile?.username) {
      await this.putUserName(userData.username);
    }
    const legacyUserData = omit(userData, ['username']);
    await this.putAndUpdateProfileLegacy(legacyUserData);
  }

  async fetchDocuments() {
    const response = await api.get('/v1/maintenance/documents').catch(()=>{
      throw new Error('Server error')
    });
    if (response.status === 401) {
      throw new Error('Not authenticated')
    }
    const { documents } = response.data;
    if (documents) {
      this.setDocumentsToLocalstorage(response.data.documents);
    }
    return documents;
  }

  private setDocumentsToLocalstorage(documents: UserDocument) {
    localStorage.setItem('documents', JSON.stringify(documents));
  }
}

export const userApi = new UserApi();
