import { useFormik } from 'formik';
import {
  ChangeEvent, useCallback, useEffect, useState,
} from 'react';
import { useApiService } from '../../../../../contexts/ApiServiceContext/ApiServiceContext';
import { useMemoryBankServiceContext } from '../../../../../contexts/MemoryBankContext/MemoryBankServiceContext';
import { useAsyncOperationState } from '../../../../../dorian-shared/hooks/useAsyncOperationState';
import { Character } from '../../../../../dorian-shared/types/character/Character';
import { bugTracker } from '../../../../../services/bugTracker/BugTrackerService';
import { MemoryBankPostRequest, MemoryIconPostRequest } from '../../../../../services/memoryBankService/types';
import { showToast } from '../../../../ui/utils';
import { memoryBankFormValidationSchema } from '../memoryBankFormValidationSchema';
import {
  MemoryDTO, MemoryFormDTO, MemoryIcon, MemoryShowIn, MemoryType,
} from '../memoryBankTypes';
import { convertMemoryDTOToFormDTO, convertMemoryFormDTOToMemoryDTO, isMemoryDTOEqual } from '../memoryBankUtils';
import { toggleMemoryShowIn } from '../utils';

export function useMemoryBankForm(bookId: number, userId: number, onHide: () => void) {
  const [memoryBank, setMemoryBank] = useState<MemoryDTO[]>([]);
  const [characters, setCharacters] = useState<Character[] | null>(null);
  const [memoryIcons, setMemoryIcons] = useState<MemoryIcon[]>([]);

  const [
    ,
    {
      isLoading,
      setToLoading,
      setToSuccess,
      isError,
      setToError,
    },
  ] = useAsyncOperationState();

  const memoryBankService = useMemoryBankServiceContext();
  const apiService = useApiService();

  const updateMemory = async (memory: MemoryFormDTO) => {
    const originalMemory = memoryBank.find((s) => s.id === memory.id);
    if (!originalMemory) {
      showToast({ textMessage: 'Can\'t find original memory', variant: 'danger' });
      return;
    }
    const newMemory = convertMemoryFormDTOToMemoryDTO(memory);

    if (!isMemoryDTOEqual(newMemory, originalMemory)) {
      try {
        await memoryBankService.updateMemory(newMemory);
        // TODO: Just add item to memoryBank
        // await loadMemoryBank();
      } catch (error) {
        if (error instanceof Error) {
          bugTracker().reportError({ name: 'MemoryBankForm', message: error.message });
        } else {
          bugTracker().reportError({ name: 'MemoryBankForm', message: `Can\'t update memory: ${newMemory.name}` });
        }
        showToast({ textMessage: `Can\'t update memory: ${newMemory.name}`, variant: 'danger' });
      }
    }
  };

  const submitHandle = (sendValues: { memoryBankSlots: MemoryFormDTO[] }) => {
    const memoryValues = sendValues.memoryBankSlots;
    memoryValues.forEach(async (memory) => {
      try {
        await updateMemory(memory);
      } catch (error) {
        if (error instanceof Error) {
          bugTracker().reportError({ name: 'MemoryBankForm', message: error.message });
        } else {
          bugTracker().reportError({ name: 'MemoryBankForm', message: 'Can\'t update memory' });
        }
        showToast({ textMessage: 'Can\'t update memory', variant: 'danger' });
      }
    });
    onHide();
  };

  const formik = useFormik({
    validationSchema: memoryBankFormValidationSchema,
    initialValues: {
      memoryBankSlots: memoryBank.map((memory) => convertMemoryDTOToFormDTO(memory)),
    },
    onSubmit: submitHandle,
  });

  const {
    handleSubmit,
    values,
    errors,
    setFieldValue,
    handleChange,
  } = formik;

  const loadMemoryBank = useCallback(async () => {
    setToLoading();
    try {
      const newMemoryBank = await memoryBankService.fetchMemoryBank(bookId);
      setMemoryBank(newMemoryBank);
      setFieldValue('memoryBankSlots', newMemoryBank.map((slot) => convertMemoryDTOToFormDTO(slot)));
      setToSuccess();
    } catch (e) {
      if (e instanceof Error) {
        setToError();
        bugTracker().reportError({ name: 'MemoryBankModal', message: e.message });
      } else {
        bugTracker().reportError({ name: 'MemoryBankModal', message: 'An unknown error occurred' });
      }
      showToast({ textMessage: 'Can\'t load memory bank', variant: 'danger' });
    }
  }, [bookId, memoryBankService, setFieldValue, setToError, setToLoading, setToSuccess]);

  useEffect(() => {
    loadMemoryBank();
  }, [bookId, loadMemoryBank]);

  const loadData = useCallback(async () => {
    setToLoading();
    try {
      const charactersData = await apiService.fetchCharactersByBookId(bookId);
      setCharacters(charactersData);
    } catch (e) {
      setToError();
      showToast({ textMessage: 'Can\'t load characters', variant: 'danger' });
    }
    try {
      const memoryIconsData = await apiService.fetchMemoryIconsByBookId(bookId);
      setMemoryIcons(memoryIconsData);
    } catch (e) {
      setToError();
      showToast({ textMessage: 'Can\'t load memory icons', variant: 'danger' });
    }
    if (!isError) {
      setToSuccess();
    }
  }, [apiService, bookId, isError, setToError, setToLoading, setToSuccess]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleMemoryChange = async (event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>, memoryIndex: number) => {
    const targetId = event.target.id;
    const isTargetChecked = 'checked' in event.target;
    const isChecked = 'checked' in event.target ? event.target.checked : false;

    const memoryBankValues = [...values.memoryBankSlots];
    const newMemory = memoryBankValues[memoryIndex];

    // Clear value if type is changed
    // 'id' is defined in <MemoryTypeField>
    if (targetId === `memoryBankSlots[${memoryIndex}].type`) {
      newMemory.value = '';
    }

    // Resolve showIn
    if (targetId === `memoryBankSlots[${memoryIndex}].showInAlert` && isTargetChecked) {
      memoryBankValues[memoryIndex].showIn = toggleMemoryShowIn(isChecked, MemoryShowIn.Alert, newMemory.showIn);
      memoryBankValues[memoryIndex].showIn = toggleMemoryShowIn(isChecked, MemoryShowIn.Panel, newMemory.showIn);
    }

    memoryBankValues[memoryIndex] = newMemory;
    setFieldValue('memoryBankSlots', memoryBankValues);
    handleChange(event);
  };

  const handleMemoryDelete = async (memoryIndex: number) => {
    setToLoading();
    try {
      const memoryBankValues = values.memoryBankSlots[memoryIndex];
      const memoryToDelete = convertMemoryFormDTOToMemoryDTO(memoryBankValues);
      await memoryBankService.deleteMemory(memoryToDelete);
      const newMemoryBank = memoryBank.filter((memory) => memory.id !== memoryToDelete.id);
      setMemoryBank(newMemoryBank);
      setFieldValue('memoryBankSlots', values.memoryBankSlots.filter((_, index) => index !== memoryIndex));
      setToSuccess();
    } catch (error) {
      setToError();
      if (error instanceof Error) {
        bugTracker().reportError({ name: 'MemoryBankForm', message: error.message });
      } else {
        bugTracker().reportError({ name: 'MemoryBankForm', message: 'An unknown error occurred in handleMemoryDelete' });
      }
      showToast({ textMessage: 'Can\'t delete memory', variant: 'danger' });
    }
  };

  const handleMemoryCreate = async () => {
    const memoryName = `var_${Math.floor(Math.random() * 1000)}`;
    const requestData: MemoryBankPostRequest = {
      name: memoryName,
      type: MemoryType.String,
      defaultValue: memoryName,
      showIn: [],
      displayName: '',
      icon: -1,
      defaultChangeDescription: '',
    };

    setToLoading();
    try {
      const newMemory: MemoryDTO = await memoryBankService.createMemory(bookId, requestData);
      setMemoryBank([...memoryBank, newMemory]);
      setFieldValue('memoryBankSlots', [...values.memoryBankSlots, convertMemoryDTOToFormDTO(newMemory)]);
      setToSuccess();
    } catch (error) {
      setToError();
      if (error instanceof Error) {
        bugTracker().reportError({ name: 'MemoryBankForm', message: error.message });
      } else {
        bugTracker().reportError({
          name: 'MemoryBankForm',
          message: 'An unknown error occurred in handleMemoryCreate',
        });
      }
      showToast({ textMessage: 'Can\'t add memory', variant: 'danger' });
    }
  };

  const handleMemoryIconAdd = async (image: File, label: string): Promise<MemoryIcon> => {
    const memoryIconPostRequest: MemoryIconPostRequest = {
      label,
      bookId,
      image,
    };
    const memoryIconResponse = await apiService.createMemoryIconByUserId(userId, memoryIconPostRequest);
    setMemoryIcons([...memoryIcons, memoryIconResponse]);
    return memoryIconResponse;
  };

  return {
    characters,
    isLoading,
    handleSubmit,
    values,
    errors,
    handleMemoryChange,
    handleMemoryDelete,
    handleMemoryCreate,
    handleMemoryIconAdd,
    memoryIcons,
  };
}
