import React, { Component } from 'react';
import {
  Button, Col, Form, Row, Spinner,
} from 'react-bootstrap';
import { api } from '../../api';
import { getCharacterLockingVisible } from '../../pages/Book/utils';
import { AvatarDropdownGroups } from './AvatarDropdownGroups';
import { BadgeWithTooltip } from './BadgeWithTooltip/BadgeWithTooltip';

const playerAvatarNameTooltip = {
  title: 'Using the player\'s name',
  content: 'Using the player\'s name means having the player\'s own name appear on this characters name plate, '
      + 'and in the text anywhere <playername> is entered. This is great for a reader\'s self insert immersion!',
};

export class CharacterForm extends Component {
  constructor(props, context) {
    super(props, context);
    const { obj: character, saveCopy } = this.props;

    // eslint-disable-next-line no-nested-ternary
    const alias = !character || !character.alias ? '' : saveCopy ? `${character.alias}_copy` : character.alias;

    const isNewCharacter = !character;
    const shouldUsePlayerAvatarName = isNewCharacter ? false : !character.hasForceAuthorsChoiceName;

    this.state = {
      loading: false,
      validated: false,
      isPlayer: isNewCharacter ? false : character.isPlayer && shouldUsePlayerAvatarName,
      playerOption: isNewCharacter ? '' : character.playerOption,
      name: isNewCharacter ? '' : character.name,
      alias,
      group: null,
      avatarNameLimit: null,
      shouldUsePlayerAvatarName,
      isAuthorLockAvatar: isNewCharacter ? false : character.lockName || character.lockArt,
      side: isNewCharacter ? 'default' : character.side,
    };
  }

  async handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    let validated = form.checkValidity();

    const {
      limits, obj, customCharacterId, avatarForced, saveCopy, extraData, avatar, file,
    } = this.props;
    const {
      alias,
      isPlayer,
      name,
      playerOption,
      shouldUsePlayerAvatarName,
      isAuthorLockAvatar,
      side,
    } = this.state;

    if (name && name.length > Number(limits.character_name_max.value)) {
      validated = false;
      this.setState({ validated: false, avatarNameLimit: true });
      event.stopPropagation();
    }

    if (validated !== false) {
      this.setState({ loading: true });
      const characterData = this.configToData(true, avatar);
      const configData = new Blob([JSON.stringify(characterData, null, 2)], { type: 'application/json' });
      const imageData = file || await this.imageToBlob();

      const formData = new FormData();
      formData.set('name', shouldUsePlayerAvatarName ? '<PlayerName>' : name);
      formData.set('config', configData);
      formData.set('image', imageData);
      formData.set('isPlayer', isPlayer ? 1 : 0);
      formData.set('playerOption', playerOption);
      formData.set('group', this.CheckGroup());
      /* DOR-5317, false - because we removed that functionality in client */
      formData.set('isDefaultProps', false);
      formData.set('hasForceAuthorsChoiceName', !shouldUsePlayerAvatarName);
      formData.set('lockArt', isAuthorLockAvatar);
      formData.set('lockName', isAuthorLockAvatar);
      formData.set('side', side);

      if (customCharacterId) {
        formData.set('customCharacterId', customCharacterId);
      }

      if (isPlayer) {
        const forcedData = this.configToData(true, avatarForced);
        const forcedConfigData = new Blob([JSON.stringify(forcedData, null, 2)], { type: 'application/json' });
        formData.set('forcedConfig', forcedConfigData);
      }

      if (obj && obj.id && !saveCopy) {
        formData.set('id', obj.id);
      }
      if (obj || saveCopy) {
        formData.set('alias', alias);
      }
      if (extraData) {
        const blobExtraData = new Blob([JSON.stringify(extraData, null, 2)], { type: 'application/json' });
        formData.set('data', blobExtraData);
      }
      this.saveData(formData, validated, '');
    }
    this.setState({ validated: true });
  }

  CheckGroup = () => {
    const { group } = this.state;
    const { obj } = this.props;

    const g = !group && obj && obj.group ? obj.group : group;
    return g === 'null' || g === 'Uncategorized' ? 'Uncategorized' : g;
  };

  saveData(params, validated) {
    this.setState({ loading: true });
    if (validated === true) {
      const { bookid, updateAvatar } = this.props;
      const url = `/v1/books/${bookid}/aws/characters`;
      api.post(url, params)
        .then((res) => {
          updateAvatar(res.data.character);
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    }
  }

  configToData(minimize, prop) {
    const dataCopy = JSON.parse(JSON.stringify(prop));
    const EXTRANEOUS_PROPERTIES = ['skincolorapprox', 'headstate', 'eyebrowstate', 'eyestate', 'mouthstate'];
    if (minimize) {
      EXTRANEOUS_PROPERTIES.forEach((k) => {
        if (dataCopy[k]) {
          delete dataCopy[k];
        }
      });
      // TODO: This block will be removed after update backend.
      // Should we send to backend all items?
      const itemsCanBeInCharacter = ['hairstyle'];
      Object.keys(dataCopy).forEach((k) => {
        if (dataCopy[k] == null && !itemsCanBeInCharacter.includes(k)) {
          delete dataCopy[k];
        }
      });
    }
    return dataCopy;
  }

  imageToBlob() {
    const { params } = this.props;

    return fetch(params).then((res) => res.blob());
  }

  handleIsPlayerChange() {
    const { isPlayer } = this.state;
    const newIsPlayer = !isPlayer;

    this.setState({
      isPlayer: newIsPlayer,
      shouldUsePlayerAvatarName: newIsPlayer,
    });
  }

  handleShouldUsePlayerAvatarNameChange() {
    const { shouldUsePlayerAvatarName } = this.state;
    const newShouldUsePlayerAvatarName = !shouldUsePlayerAvatarName;
    const isPlayer = newShouldUsePlayerAvatarName;

    this.setState({
      shouldUsePlayerAvatarName: newShouldUsePlayerAvatarName,
      isPlayer,
    });
  }

  render() {
    const {
      limits, obj: character, onHide, view, avatar, userRole, bookType,
    } = this.props;
    const nameMaxLength = Number(limits.character_name_max.value);
    const {
      alias,
      loading,
      avatarNameLimit,
      name,
      playerOption,
      isPlayer,
      validated,
      shouldUsePlayerAvatarName,
      isAuthorLockAvatar,
      side,
    } = this.state;

    if (!view) {
      return null;
    }

    const isFemale = avatar.type === 'female';

    const {
      isCharacterLockingForNonAdminVisible,
    } = getCharacterLockingVisible(userRole, bookType);

    const isCharacterNameDisabled = isPlayer && shouldUsePlayerAvatarName;

    return (
      <div className="border p-3">
        <Form
          noValidate
          validated={validated}
          onSubmit={(event) => this.handleSubmit(event)}
        >

          <h3 className="mb-3">
            Parameters
          </h3>

          <Row>
            <Col lg={2} className="text-left">
              <Form.Group className="mr-3">
                <Form.Check
                  custom
                  disabled={loading}
                  id="isPlayerCheck"
                  name="isPlayer"
                  checked={isPlayer}
                  onChange={() => this.handleIsPlayerChange()}
                  label="Player"
                />
              </Form.Group>
            </Col>

            <Col lg={6}>
              {isCharacterLockingForNonAdminVisible && (
                <Form.Group className="mr-2">
                  <Form.Check
                    custom
                    disabled={loading}
                    id="isAuthorLockAvatar"
                    name="isAuthorLockAvatar"
                    checked={isAuthorLockAvatar}
                    onChange={() => {
                      this.setState({
                        isAuthorLockAvatar: !isAuthorLockAvatar,
                      });
                    }}
                    label="Locked In Spin-Offs"
                  />
                </Form.Group>
              )}
            </Col>

            <Col lg={4}>
              <Form.Group>
                <Form.Control
                  as="select"
                  size="sm"
                  disabled={(loading || !(isPlayer && isFemale))}
                  id="playerOptions"
                  name="playerOption"
                  value={playerOption || ''}
                  onChange={(e) => {
                    this.setState({
                      playerOption: e.target.value,
                    });
                  }}
                  label="Player Option"
                >
                  <option>Default</option>
                  <option value="underwear">Underwear</option>
                  <option value="swimwear">Swimwear</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col lg={2} className="text-left">
              <Form.Label>Options:</Form.Label>
            </Col>

            <Col lg={3}>
              <Form.Group className="mr-2 d-flex">
                <Form.Check
                  custom
                  disabled={loading}
                  id="shouldUsePlayerAvatarName"
                  name="shouldUsePlayerAvatarName"
                  checked={shouldUsePlayerAvatarName}
                  onChange={() => this.handleShouldUsePlayerAvatarNameChange()}
                  label="Use player's name"
                />
                <BadgeWithTooltip
                  id="playerAvatarNameTooltip"
                  title={playerAvatarNameTooltip.title}
                  content={playerAvatarNameTooltip.content}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col lg={2}>
              <Form.Label>Name:</Form.Label>
            </Col>

            <Col lg={10}>
              <Form.Group controlId="Title" style={{ position: 'relative' }}>
                <Form.Control
                  required
                  disabled={
                    loading
                    || isCharacterNameDisabled
                  }
                  size="sm"
                  type="text"
                  placeholder="Name"
                  name="Name"
                  value={isCharacterNameDisabled ? '<PlayerName>' : name}
                  className={name && name.length > nameMaxLength ? 'text-limit' : null}
                  onChange={(e) => {
                    this.setState({
                      avatarNameLimit: e.target.value.length > nameMaxLength,
                      name: e.target.value,
                    });
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  Please choose a Name.
                </Form.Control.Feedback>
                <Form.Control.Feedback
                  type="invalid"
                  className={avatarNameLimit ? 'd-block' : 'd-none'}
                >
                  Name is too long.
                </Form.Control.Feedback>
                <Form.Text className="char-limit-info-box">
                  {nameMaxLength - (name ? name.length : 0)}
                </Form.Text>
              </Form.Group>
            </Col>

          </Row>

          {character && (
            <Row>
              <Col md={2}>
                <Form.Label>Alias:</Form.Label>
              </Col>

              <Col md={10}>
                <Form.Group controlId="Alias">
                  <Form.Control
                    required
                    disabled={(loading)}
                    size="sm"
                    type="text"
                    placeholder="Alias"
                    name="alias"
                    value={alias}
                    onChange={(e) => {
                      this.setState({
                        alias: e.target.value,
                      });
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please choose a Alias.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

            </Row>
          )}

          <Row>
            <Col lg={2}>
              <Form.Label>Group:</Form.Label>
            </Col>

            <Col lg={10} className="mb-3">
              <AvatarDropdownGroups
                {...this.props}
                disabled={(loading)}
                update={(group) => {
                  this.setState({
                    group,
                  });
                }}
              />
            </Col>
          </Row>

          <Row>
            <Col lg={2}>
              <Form.Label>Default side:</Form.Label>
            </Col>
            <Col lg="auto">
              <Form.Group>
                <Form.Control
                  as="select"
                  value={side}
                  onChange={(event) => {
                    this.setState({ side: event.target.value });
                  }}
                >
                  <option value="default">{`Default (${isPlayer ? 'Right' : 'Left'})`}</option>
                  <option value="left">Left</option>
                  <option value="right">Right</option>
                </Form.Control>
              </Form.Group>

            </Col>
          </Row>

          <div className="pr-0">
            <div className="menu-right my-3">
              <Button
                disabled={loading}
                className="mx-2"
                size="md"
                type="reset"
                variant="secondary"
                onClick={onHide}
              >
                BACK
              </Button>

              <Button
                disabled={
                  loading
                  || avatarNameLimit
                  || (avatar.type === 'custom' && !avatar.image)
                }
                size="md"
                type="submit"
                variant="primary"
              >
                {loading && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                )}
                SAVE
              </Button>
            </div>
          </div>
        </Form>
      </div>
    );
  }
}
