import arrayMove from 'array-move';
import classNames from 'classnames/bind';
import React, { Component } from 'react';
import {
  Col, Dropdown, Form, ListGroup, Row,
} from 'react-bootstrap';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { isMemoryBankEnabled } from 'components/utils/isMemoryBankEnabled';
import { StepTypeMode } from '../../../../../dorian-shared/types/branch/Branch';
import { StepTypes } from '../../../../../dorian-shared/types/branch/BranchStep';
import {
  defaultExpressionName,
  defaultExpressionValue,
} from '../../../../ui/AvatarTool/CharacterExpressions/constants';
import { ConfirmModal } from '../../../../ui/ConfirmModal/ConfirmModal';
import { isEliminationCorrectOptionCanBeDisplayed } from '../../../../utils/branchUtils';
import { CheckOperator } from '../../../Book/MemoryBank/memoryBankTypes';
import { NodeEditContext } from '../context/NodeEditContext';
import { AddAnswerHeader } from './AddAnswerHeader/AddAnswerHeader';
import { AnswerForm } from './AnswerForm';
import { getStepData, prepareForCopy, setStepData } from './CopyPastStep';
import { DeleteStep } from './DeleteStep';
import styles from './Steps.scss';
import { StepsFormData } from './StepsFormData';
import { StepsTypeFieldsCharacters } from './stepsTypeFieldsCharacters';
import { StepsTypeFieldsExpressions } from './stepsTypeFieldsExpressions';
import { StepsTypeFieldsRelationships } from './stepsTypeFieldsRelationships';
import { StepsTypeFieldsSide } from './StepsTypeFieldsSide';
import { StepsTypeFieldsSubType } from './stepsTypeFieldsSubType';
import { StepsTypeFieldsText } from './stepsTypeFieldsText';
import { StepTypeCheck } from './StepTypeCheck/StepTypeCheck';
import { StepTypeRemember } from './StepTypeRemember';
import { count } from './utils';

const cs = classNames.bind(styles);

/* Answer */
const SortableItemAnswer = SortableElement(({ answer, answerIndex, ...props }) => (
  <ListGroup.Item key={answerIndex} as="li" className={cs('py-0 pl-3 pr-1 branchesList-li')}>
    <AnswerForm
      answerIndex={answerIndex}
      object={answer}
      {...props}
    />
  </ListGroup.Item>
));

const SortableListAnswer = SortableContainer(({ answers, ...props }) => (
  <ListGroup as="ol" start="0" variant="flush" className="my-1 ml-4 mr-1 branchesList">
    {answers.map((answer, index) => {
      const isCorrectOptionDisabled = isEliminationCorrectOptionCanBeDisplayed(answers, answer.id);
      return (
        <SortableItemAnswer
        // eslint-disable-next-line react/no-array-index-key
          key={index}
          disabled={props.disabledSortable}
          index={index}
          answer={answer}
          answerIndex={index}
          isEliminationCorrectOptionDisabled={isCorrectOptionDisabled}
          {...props}
        />
      );
    })}
  </ListGroup>
));

export class StepsForm extends Component {
  constructor(props, ...args) {
    super(props, ...args);
    const { step } = this.props;
    const operator = CheckOperator[step?.check?.operator];
    const isMoreFunctions = operator?.isMultiSelect || false;
    this.state = {
      stepsTypeFields: [],
      disabledSortable: false,
      copyStepChecked: false,
      deleteStepChecked: false,
      isMoreFunctions,
      isShowMoreFunctionsWarning: false,
    };
  }

  onSortAnswerEnd = ({ oldIndex, newIndex }) => {
    const {
      step, updateStep, activeStepsFunc, index,
    } = this.props;

    const stepClass = `steps-${index}-answers-${newIndex}`;
    step.answers = arrayMove(step.answers, oldIndex, newIndex);
    updateStep(index, step);
    activeStepsFunc(stepClass);
  };

  handleAddAnswer = (index) => {
    const {
      step, activeStepsFunc, index: indexFromProps, updateStep,
    } = this.props;
    const { answerType } = this.context;

    const newStep = { ...step };
    const newAnswers = {
      text: '',
      gotoBranchId: '',
      data: [],
      traits: [],
      requires: [],
      tags: [],
    };

    const defAnswerType = answerType.find((el) => el.isDefault === true);
    if (defAnswerType) {
      newAnswers.answerTypeId = defAnswerType.id;
    }
    if (index !== undefined) {
      newStep.answers.splice(index + 1, 0, newAnswers);
      activeStepsFunc(`steps-${indexFromProps}-answers-${index}`);
    } else {
      newStep.answers.push(newAnswers);
      activeStepsFunc(`steps-${indexFromProps}-answers-${newStep.answers.length - 1}`);
    }
    updateStep(indexFromProps, newStep);
  };

  deleteAnswer = (i) => {
    const { step, index, updateStep } = this.props;

    const newStep = { ...step };
    const answers = newStep.answers.slice();
    answers.splice(i, 1);
    newStep.answers = answers;
    updateStep(index, newStep);
  };

  handleChangeAnswer = (answerIndex, data) => {
    const { step, index, updateStep } = this.props;

    const newStep = { ...step };
    const answersCopy = newStep.answers.slice();
    answersCopy[answerIndex] = data;
    newStep.answers = answersCopy;
    updateStep(index, newStep);
  };

  disabledSortableAction = (val) => {
    this.setState({
      disabledSortable: val,
    });
  };

  handleChangeStepsType() {
    const { stepsType } = this.context;
    const {
      step, defStepType,
    } = this.props;

    const fields = this.checkItem(stepsType, 'id', step && step.stepTypeId ? Number(step.stepTypeId) : defStepType, 'fields');

    const newFields = [];
    if (fields) {
      fields.forEach((field) => {
        newFields[field] = true;
      });
    }

    this.setState({
      stepsTypeFields: newFields,
    });
  }

  componentDidMount() {
    this.handleChangeStepsType();
    this.copyStepChecked();
    this.deleteStepChecked();
  }

  copyStepChecked = () => {
    const { step } = this.props;
    const copyStep = JSON.parse(localStorage.getItem('copyStep')) || [];
    this.setState({
      copyStepChecked: !!copyStep.find((e) => {
        let a = e.ind && step.ind && Number(e.ind) === Number(step.ind);
        if (e.ind === 0 && step.ind === 0) {
          a = true;
        }
        return Boolean(a);
      }),
    });
  };

  deleteStepChecked = () => {
    const { deleteStepVal, index } = this.props;

    this.setState({
      deleteStepChecked: deleteStepVal.includes(index),
    });
  };

  componentDidUpdate(prevProps) {
    const {
      SelectStepsDelete,
      step,
      copyStep,
      deleteStepVal,
      allCopyStepChecked,
      SelectStepsCopy,
      allDeleteStepChecked,
    } = this.props;

    if (prevProps.allCopyStepChecked !== allCopyStepChecked) {
      this.setState({
        copyStepChecked: allCopyStepChecked,
      }, () => {
        this.copyStepCheckedFunc();
      });
    }
    if (prevProps.step.stepTypeId !== step.stepTypeId || prevProps.step.mode !== step.mode) {
      this.handleChangeStepsType();
    }
    if (
      prevProps.copyStep !== copyStep
      || prevProps.SelectStepsCopy !== SelectStepsCopy
    ) {
      this.copyStepChecked();
    }
    if (
      prevProps.deleteStepVal !== deleteStepVal
      || prevProps.SelectStepsDelete !== SelectStepsDelete
      || prevProps.allDeleteStepChecked !== allDeleteStepChecked
    ) {
      this.deleteStepChecked();
    }
  }

  stepType() {
    const {
      step, result, isIntro, totalSteps, ending, user,
    } = this.props;
    const { stepsType } = this.context;

    const isMemoryBank = isMemoryBankEnabled(user);
    const stepTypeId = Number(step.stepTypeId);
    // const isMoreThanOneStep = totalSteps > 1;
    const stepTypes = stepsType.filter(({ id }) => {
      if (id === StepTypes.Result) return false;
      if (id === StepTypes.PlayerSelect) return false;
      if (id === StepTypes.Check && !isMemoryBank) return false;
      if (id === StepTypes.Remember && !isMemoryBank) return false;
      if (stepTypeId === id) return true;
      if (isIntro) {
        if (id === StepTypes.Choice) return false;
        if (id === StepTypes.Remember) return false;
        if (id === StepTypes.Check) return false;
      }
      // TODO: Ask about excluding disabled options
      // if (isMoreThanOneStep) {
      //   if (id === StepTypes.Choice || id === StepTypes.Check) return false;
      //   if (result && id !== StepTypes.Ending) return false;
      //   if (ending && id !== StepTypes.Ending && id !== StepTypes.Result) return false;
      //   if (ending && id !== StepTypes.Ending && id !== StepTypes.Result) return false;
      //   if (!ending && !result && (id === StepTypes.Ending || id === StepTypes.Result)) return false;
      // }

      return true;
    });

    return stepTypes.map(({ id, title }) => {
      const isMoreThanOneStep = totalSteps > 1;
      const isCheckOrRemember = id === StepTypes.Choice || id === StepTypes.Check;
      const isResultNotEnding = result && id !== StepTypes.Ending;
      const isEndingNotEndingOrResult = ending && id !== StepTypes.Ending && id !== StepTypes.Result;
      const isNoEndingOrResult = !ending && !result && (id === StepTypes.Ending || id === StepTypes.Result);

      const isDisabled = isMoreThanOneStep && (
        isCheckOrRemember
        || isResultNotEnding
        || isEndingNotEndingOrResult
        || isNoEndingOrResult
      );
      return <option value={id} key={id} disabled={isDisabled}>{title}</option>;
    });
  }

  checkItem(arr, field, arr1, field1) {
    let res = null;
    arr.forEach((object) => {
      if (object[field] === arr1) {
        res = object[field1];
      }
    });
    return res;
  }

  /* Begin Data */

  handleDataChange = (value) => {
    const { step, index, updateStep } = this.props;

    const objCopy = { ...step };
    objCopy.data = value;
    updateStep(index, objCopy);
  };

  showData() {
    const { step, updateStep, index } = this.props;

    step.showData = !step.showData;
    updateStep(index, step);
  }

  /* End Data */
  copyStep = () => {
    const { step } = this.props;

    setStepData(step);
  };

  pasteStep = () => {
    const {
      ending, index, errorAlert, addStep, result, totalSteps,
    } = this.props;

    const data = getStepData();
    if (data.length < 1) {
      return;
    }
    data.forEach((a) => {
      const obj = prepareForCopy(a);

      if (totalSteps > 1) {
        const stepTypeID = Number(obj.stepTypeId);
        if (stepTypeID === 3) {
          errorAlert({ response: { data: { error: 'Choice step should be the only one in the node' } } });
          return;
        }
        if ((stepTypeID === 5 || stepTypeID === 6) && (!ending && !result)) {
          errorAlert({ response: { data: { error: 'Outro/ending node must only have Ending steps' } } });
          return;
        }
        if ((stepTypeID !== 5 && stepTypeID !== 6) && (ending || result)) {
          errorAlert({ response: { data: { error: 'Outro/ending node must only have Ending steps' } } });
          return;
        }
      }
      addStep(index, obj, true);
    });
  };

  renderAnswers = () => {
    const {
      user, activeSteps, limits, step, restrictedEdit, updateStep, activeStepsFunc, index,
    } = this.props;
    const { disabledSortable } = this.state;
    const {
      memoryBankSlots, characters, handleChangeStepTypeMode, stepTypeMode, story,
    } = this.context;

    if (Number(step.stepTypeId) !== StepTypes.Choice) {
      return null;
    }

    const answersCount = step.answers ? count(step.answers) : 0;

    return (
      <div className={cs('tableSubItems pt-2', 'd-block')}>
        {!restrictedEdit && (
          <AddAnswerHeader
            disabled
            onAddAnswerClick={this.handleAddAnswer}
            answersCount={answersCount}
            stepTypeMode={stepTypeMode ?? StepTypeMode.Regular}
            onStepTypeModeChange={handleChangeStepTypeMode}
            competitiveMode={story?.book?.competitiveMode ?? false}
          />
        )}

        {/* <AnswerForm> wrapped in SortableContainer to allow drag and drop */}
        <SortableListAnswer
          distance={2}
          user={user}
          restrictedEdit={restrictedEdit}
          answers={step.answers}
          onSortEnd={this.onSortAnswerEnd}
          helperClass="sort-item"
          disabledSortable={disabledSortable || restrictedEdit}
          stepIndex={index}
          limits={limits}
          disabledSortableAction={this.disabledSortableAction}
          addAnswer={this.handleAddAnswer}
          activeSteps={activeSteps}
          activeStepsFunc={activeStepsFunc}
          updateStep={updateStep}
          handleChangeAnswer={this.handleChangeAnswer}
          deleteAnswer={this.deleteAnswer}
          memoryBankSlots={memoryBankSlots}
          characters={characters}
        />
      </div>
    );
  };

  copyStepCheckedFunc() {
    const { step, updateCopyStep } = this.props;
    const { copyStepChecked } = this.state;

    const copyStep = JSON.parse(localStorage.getItem('copyStep')) || [];
    const newObj = [];
    copyStep.forEach((a) => {
      if (a.ind !== step.ind) {
        newObj.push(a);
      }
    });
    if (copyStepChecked) {
      newObj.push(step);
    }
    localStorage.setItem('copyStep', JSON.stringify(newObj));
    updateCopyStep(newObj);
  }

  deleteStepCheckedFunc() {
    const { index, updateDeleteStep } = this.props;
    const { deleteStepChecked } = this.state;

    updateDeleteStep(index, deleteStepChecked);
  }

  handleStepTypeChange = (e) => {
    const { handleChangeSteps, index } = this.props;
    handleChangeSteps(e.target.value, index, 'stepTypeId');
    this.handleChangeStepsType();
  };

  handleMoreFunctionsChange = (event) => {
    const { removeStepCheck, step } = this.props;
    removeStepCheck(step);
    this.setState({ isMoreFunctions: event.target.checked });
  };

  render() {
    const {
      step,
      activeSteps,
      SelectStepsCopy,
      index,
      deleteStep,
      story,
      SelectStepsDelete,
      limits,
      handleChangeSteps,
      addStep,
      restrictedEdit,
      defStepType,
      activeStepsFunc,
      disabledSortableAction,
      updateCharacters,
      handleChangeStepAction,
      addStepAction,
      branches,
      addStepCheck,
      handleChangeStepCheck,
      handleChangeStepSwitch,
      user,
    } = this.props;
    const {
      copyStepChecked, deleteStepChecked, isMoreFunctions, isShowMoreFunctionsWarning,
    } = this.state;
    const {
      characters, characterExpression: possibleExpressions, memoryBankSlots,
    } = this.context;

    const stepIndex = index;
    const stepText = step ? step.text : null;
    const stepCharacterId = step ? step.characterId : null;

    const character = characters.find(
      (item) => item.id === Number(stepCharacterId),
    );
    const legacyCharacterSide = character && (character.isPlayer ? 'right' : 'left');

    const defaultCharacterExpression = possibleExpressions.find(
      (expression) => expression.def,
    );
    const defaultExpressionForOldCustomCharacters = {
      [defaultCharacterExpression.value]: defaultExpressionValue,
    };
    const defaultExpressionForCustomCharacters = possibleExpressions.find(
      (expression) => expression.value === defaultExpressionName,
    ) ?? defaultExpressionForOldCustomCharacters;
    const characterPropertiesExpressions = character?.properties?.expressions
      ?? defaultExpressionForOldCustomCharacters;
    const availableExpressionsKeys = Object.keys(characterPropertiesExpressions);
    const availableExpressions = character?.type === 'custom'
      ? possibleExpressions.filter(
        (expression) => availableExpressionsKeys.includes(expression.value),
      )
      : possibleExpressions;

    const characterExpression = step?.characterExpressionId
      ? availableExpressions
        .find((expression) => expression.id === step?.characterExpressionId)
      : availableExpressions
        .find((expression) => expression.id === defaultCharacterExpression.id);

    const selectedExpression = characterExpression ?? defaultExpressionForCustomCharacters;
    const characterExpressionId = selectedExpression?.id ?? defaultExpressionForCustomCharacters.id;
    const characterRelationship = step ? step.characterRelationship : null;
    const stepType = step ? Number(step.stepTypeId) : Number(defStepType);
    const subType = step ? step.subType : null;
    const dataIndex = step ? count(step.data) : 0;
    const stepClass = `step-${stepIndex}`;
    const stepClassActive = activeSteps;
    const valStepData = getStepData();
    const { disabledSortable, stepsTypeFields } = this.state;

    const isMemoryBankAvailable = isMemoryBankEnabled(user);

    return (
      <ListGroup.Item
        as="li"
        className={cs('my-1 py-0 pl-3 pr-0 branchesList-li')}
      >
        <Row
          className={cs('py-1 row justify-content-between', stepClassActive === stepClass ? 'active-item' : null)}
        >
          {restrictedEdit && (
            <Form.Control
              type="hidden"
              name={`steps[${stepIndex}][id]`}
              value={step.id}
            />
          )}
          {SelectStepsCopy && (
            <Form.Group
              className="mb-0 boxSelectStepsCopy"
              controlId={`copyStep${stepIndex}`}
              onMouseOver={(e) => {
                disabledSortableAction(e);
              }}
              onClick={() => {
                this.setState({
                  copyStepChecked: !copyStepChecked,
                }, () => {
                  this.copyStepCheckedFunc();
                });
              }}
            >
              <Form.Check
                custom
                autoFocus
                as="input"
                checked={copyStepChecked}
                onChange={
                  (e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }
                }
                type="checkbox"
                id={`copyStep[${stepIndex}]`}
                name={`copyStep[${stepIndex}]`}
                label=""
                style={{ margin: '.25em' }}
              />
            </Form.Group>
          )}
          {SelectStepsDelete && (
            <Form.Group
              className="mb-0 boxSelectStepsCopy"
              controlId={`deleteStep${stepIndex}`}
              onMouseOver={(e) => {
                disabledSortableAction(e);
              }}
              onClick={() => {
                this.setState({
                  deleteStepChecked: !deleteStepChecked,
                }, () => {
                  this.deleteStepCheckedFunc();
                });
              }}
            >
              <Form.Check
                custom
                autoFocus
                as="input"
                checked={deleteStepChecked}
                onChange={
                  (e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }
                }
                type="checkbox"
                id={`deleteStep[${stepIndex}]`}
                name={`deleteStep[${stepIndex}]`}
                label=""
                style={{ margin: '.25em' }}
              />
            </Form.Group>
          )}
          <div>
            <Form.Group
              md={1}
              controlId={`stepType${stepIndex}`}
              className={cs('mb-0 px-1')}
            >

              <Form.Control
                disabled={restrictedEdit}
                size="sm"
                as="select"
                name={`steps[${stepIndex}][stepTypeId]`}
                value={stepType || ''}
                onChange={this.handleStepTypeChange}
                onMouseDown={(e) => e.stopPropagation()}
                onFocus={() => activeStepsFunc(stepClass)}
              >
                {this.stepType()}
              </Form.Control>
            </Form.Group>
            {stepType === StepTypes.Check && isMemoryBankAvailable && (
              <>
                <Form.Group
                  controlId={`moreFunctions-${stepIndex}`}
                  className={cs('mb-0 px-1')}
                >
                  <Form.Check
                    custom
                    id={`moreFunctions-${stepIndex}`}
                    disabled={false}
                    checked={isMoreFunctions}
                    onChange={() => this.setState({ isShowMoreFunctionsWarning: true })}
                    type="checkbox"
                    label="More Functions"
                    onMouseDown={(e) => e.stopPropagation()}
                  />
                </Form.Group>
                <ConfirmModal
                  show={isShowMoreFunctionsWarning}
                  onHide={() => this.setState({ isShowMoreFunctionsWarning: false })}
                  onCancel={() => this.setState({ isShowMoreFunctionsWarning: false })}
                  onConfirm={() => {
                    this.setState({ isMoreFunctions: !isMoreFunctions, isShowMoreFunctionsWarning: false });
                    this.handleMoreFunctionsChange({ target: { checked: !isMoreFunctions } });
                  }}
                  title="Warning"
                >
                  <Col className="text-center">
                    The values will be cleared in this step.
                    <br />
                    Are you sure you want to proceed?
                  </Col>
                </ConfirmModal>
              </>
            )}
          </div>

          <StepsTypeFieldsText
            stepIndex={stepIndex}
            stepClassActive={stepClassActive}
            stepClass={stepClass}
            stepText={stepText}
            stepType={stepType}
            stepTextMax={limits[`step_${stepType}_text_max`] ? Number(limits[`step_${stepType}_text_max`].value) : 0}
            displayControl={stepsTypeFields.text === true}
            activeStepsFunc={activeStepsFunc}
            addStep={addStep}
            handleChangeSteps={handleChangeSteps}
            SelectStepsCopy={SelectStepsCopy}
          />

          <StepsTypeFieldsCharacters
            disabled={restrictedEdit}
            update={(val) => updateCharacters(val, stepIndex)}
            story={story}
            stepIndex={stepIndex}
            stepClass={stepClass}
            stepType={stepType}
            characters={characters}
            characterId={stepCharacterId}
            displayControl={stepsTypeFields.character === true}
            activeStepsFunc={activeStepsFunc}
            handleChangeSteps={handleChangeSteps}
            disabledSortableAction={disabledSortableAction}
          />

          <StepsTypeFieldsRelationships
            disabled={restrictedEdit}
            stepIndex={stepIndex}
            stepClass={stepClass}
            characterRelationship={characterRelationship}
            displayControl={stepsTypeFields.characterRelationship === true}
            activeStepsFunc={activeStepsFunc}
            handleChangeSteps={handleChangeSteps}
          />

          <StepsTypeFieldsExpressions
            disabled={restrictedEdit}
            stepIndex={stepIndex}
            stepClass={stepClass}
            characters={characters}
            characterId={stepCharacterId}
            characterExpressionId={characterExpressionId}
            availableExpressions={availableExpressions}
            displayControl={stepsTypeFields.characterExpression === true}
            activeStepsFunc={activeStepsFunc}
            handleChangeSteps={handleChangeSteps}
            disabledSortableAction={disabledSortableAction}
          />

          {stepsTypeFields.character && (
            <StepsTypeFieldsSide
              disabled={restrictedEdit}
              characterPlacementSide={step.characterPlacementSide}
              defaultPlacementSide={!character || character.side === 'default' ? legacyCharacterSide : character.side}
              stepIndex={stepIndex}
              handleChangeSteps={handleChangeSteps}
            />
          )}

          <StepsTypeFieldsSubType
            disabled={restrictedEdit}
            stepIndex={stepIndex}
            stepClass={stepClass}
            subType={subType}
            displayControl={stepsTypeFields.subType === true}
            activeStepsFunc={activeStepsFunc}
            handleChangeSteps={handleChangeSteps}
          />

          {stepType === StepTypes.Remember && isMemoryBankAvailable && (
            <StepTypeRemember
              stepIndex={stepIndex}
              memoryBankSlots={memoryBankSlots}
              handleChangeStepAction={handleChangeStepAction}
              addStepAction={addStepAction}
              action={step.action}
              characters={characters}
              isDisabled={restrictedEdit}
            />
          )}

          {stepType === StepTypes.Check && isMemoryBankAvailable && (
            <StepTypeCheck
              stepIndex={stepIndex}
              memoryBankSlots={memoryBankSlots}
              branches={branches}
              checkData={step.check}
              switchData={step.switch}
              currentBranchId={step.branchId}
              addStepCheck={addStepCheck}
              handleChangeStepCheck={handleChangeStepCheck}
              handleChangeStepSwitch={handleChangeStepSwitch}
              characters={characters}
              isDisabled={restrictedEdit}
              isMoreFunctions={isMoreFunctions}
            />
          )}

          <Form.Group
            md="auto"
            as={Col}
            className={cs('mb-auto px-1 pr-4 text-right align-self-end')}
          >

            <Dropdown
              className="mx-1 d-inline-block"
              onToggle={(e) => {
                disabledSortableAction(e);
                activeStepsFunc(stepClass);
              }}
            >
              <Dropdown.Toggle
                disabled={restrictedEdit}
                variant="secondary"
                className="mx-1"
                size="sm"
              >
                ...
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  active={step.showData}
                  onClick={() => {
                    this.showData();
                  }}
                >
                  Data (
                  {dataIndex}
                  )
                </Dropdown.Item>
                <Dropdown.Divider />

                <Dropdown.Item
                  onClick={() => this.copyStep()}
                >
                  Copy
                </Dropdown.Item>

                <Dropdown.Item
                  disabled={!valStepData || valStepData.length > 1 || !!SelectStepsDelete}
                  onClick={() => this.pasteStep()}
                >
                  Paste
                </Dropdown.Item>

                <Dropdown.Divider />

                <DeleteStep
                  disabled={(!!SelectStepsDelete)}
                  deleteFunc={deleteStep}
                  index={stepIndex}
                  title="Delete Step"
                  content="Do you really want to delete this Step?"
                />

              </Dropdown.Menu>
            </Dropdown>
          </Form.Group>

        </Row>

        <StepsFormData
          stepIndex={stepIndex}
          data={step.data}
          disabledSortable={disabledSortable}
          limits={limits}
          showData={step.showData}
          handleDataChange={this.handleDataChange}
          activeSteps={activeSteps}
          activeStepsFunc={activeStepsFunc}
        />

        {this.renderAnswers()}

      </ListGroup.Item>
    );
  }
}

StepsForm.contextType = NodeEditContext;
