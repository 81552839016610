import async from 'async';
import classNames from 'classnames/bind';
import serialize from 'form-serialize';
import React, { Component } from 'react';
import {
  Button, Col, Form, Row,
} from 'react-bootstrap';
import { CirclePicker } from 'react-color';
import { api } from '../../api';
import { AddLocationModal } from './AddLocationModal/AddLocationModal';
import { colors } from './ChangeColor';
import { Panel } from './Panel';
import styles from './ValidatePanel.scss';

const cs = classNames.bind(styles);

export class SharedSettingsPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locations: [],
      loading: true,
      locationId: '',
      addLocation: false,
      color: undefined,
    };
  }

  componentDidUpdate(prevProps) {
    const { show } = this.props;

    if (show !== prevProps.show && show === true) {
      this.loadData();
      this.setState({
        locationId: '',
        color: undefined,
      });
    }
  }

  loadData() {
    const { story } = this.props;
    this.setState({
      loading: true,
    });
    async.parallel({
      locations: (callback) => {
        api.get(`/v1/books/${story.book.id}/locations`)
          .then((res) => {
            callback(null, res.data.locations);
          }).catch((error) => {
            callback(error, null);
          });
      },
    }, (err, res) => {
      try {
        if (err) {
          this.setState({
            loading: false,
          });
        } else {
          this.setState({
            locations: res.locations,
            loading: false,
          });
        }
      } catch (e) {
        this.setState({
          loading: false,
        });
      }
    });
  }

  saveChanges(data) {
    const { update, storyId, onHide } = this.props;

    api.patch(`/v1/stories/${storyId}/branches`, data)
      .then(() => {
        update();
        onHide();
      });
  }

  handleSubmit(event) {
    const { selectedItems } = this.props;
    const { color } = this.state;

    event.preventDefault();
    const form = event.currentTarget;
    const validated = form.checkValidity();
    const value = serialize(form, { hash: true, disabled: false });
    if (validated === false) {
      event.stopPropagation();
    } else {
      const data = {};

      if (color !== undefined) {
        data.bgColor = color === '' ? null : color;
      }
      if (value.locationId) {
        data.locationId = value.locationId;
      }

      if (color !== undefined || value.locationId) {
        const branches = [];

        for (let i = 0; i < selectedItems.length; i++) {
          branches.push({
            id: selectedItems[i],
            data,
          });
        }
        this.saveChanges({ branches });
      }
    }
    event.stopPropagation();
  }

  locationOptions() {
    const { locations } = this.state;

    const res = [
      <option
        value=""
        disabled
        className="def_location_option"
        key="def"
      >
        Select
      </option>,
    ];

    if (locations.length > 0) {
      // eslint-disable-next-line react/no-array-index-key,max-len
      res.push(...locations.map((object, i) => <option value={object.id} key={i}>{object.title}</option>));
    }
    res.push(<option value="add" key="add">+ ADD LOCATION</option>);
    return res;
  }

  checkChanges() {
    const { locationId, color } = this.state;

    return !!locationId || (color !== undefined);
  }

  handleChange = (color) => {
    this.setState({ color: color.hex });
  };

  render() {
    const { show, user, onHide } = this.props;
    const {
      color,
      loading,
      locationId,
      addLocation,
      story,
      limits,
    } = this.state;

    return (
      <>
        <Panel
          pageTitle="Shared Node Settings"
          show={show}
          onHide={onHide}
          loading={loading}
        >
          <Form
            noValidate
            className={cs('my-2 pt-3')}
            onSubmit={(e) => this.handleSubmit(e)}
          >
            <Row>
              <Form.Label column md={3}>Location</Form.Label>
              <Col>
                <Form.Control
                  size="sm"
                  as="select"
                  style={{
                    opacity: locationId ? 1 : 0.5,
                    backgroundColor: locationId ? '#fff' : '#ccc',
                  }}
                  placeholder="Location"
                  disabled={loading}
                  value={locationId}
                  onChange={(e) => {
                    if (e.target.value === 'add') {
                      this.setState({
                        addLocation: true,
                      });
                      return;
                    }
                    this.setState({
                      locationId: e.target.value,
                    });
                  }}
                  name="locationId"
                >
                  {this.locationOptions()}
                </Form.Control>
              </Col>
            </Row>
            <hr />
            <Row>
              <Form.Label column md={3}>Color</Form.Label>
              <Col>
                <CirclePicker
                  width="100%"
                  colors={colors}
                  color={color}
                  onChange={this.handleChange}
                />
              </Col>
            </Row>
            <Row>
              <Button
                variant="secondary"
                size="sm"
                className="ml-auto my-2"
                onClick={() => {
                  this.setState({ color: '' });
                }}
              >
                Clear Color
              </Button>
            </Row>
            <hr />
            <Row>
              <Col className={cs('text-right')}>
                <Button
                  disabled={loading}
                  type="reset"
                  className={cs('ml-1')}
                  variant="secondary"
                  size="sm"
                  onClick={() => {
                    onHide();
                  }}
                >
                  Cancel
                </Button>

                <Button
                  disabled={loading || !this.checkChanges()}
                  type="submit"
                  className={cs('ml-1')}
                  variant="primary"
                  size="sm"
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        </Panel>

        {addLocation && (
          <AddLocationModal
            story={story}
            limits={limits}
            user={user}
            show={addLocation}
            onHide={() => {
              this.setState({
                addLocation: false,
              });
            }}
            onSuccess={(data) => {
              this.loadData();
              this.setState({
                locationId: data.id,
                addLocation: false,
              });
            }}
          />
        )}
      </>
    );
  }
}
