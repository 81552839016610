import React, { useContext, useEffect } from 'react';
import { Col, ListGroup, Row } from 'react-bootstrap';
import {
  MemoryDTO,
  MemoryValue,
  StepCheck,
  StepCheckOperatorType,
  StepCheckSwitch,
} from 'components/pages/Book/MemoryBank/memoryBankTypes';
import { Branch } from 'dorian-shared/types/branch/Branch';
import { Character } from 'dorian-shared/types/character/Character';
import { ChoiceMemoryContext } from '../../../../../../contexts/ChoiceMemory/ChoiceMemoryContext';
import { MemoryBankCheckValue, MemorySlotCompareForm } from '../MemorySlotCompareForm';
import { MultiMemoryCheckForm } from '../MultiMemoryCheckForm/MultiMemoryCheckForm';
import { MultiMemoryCheckSwitchForm } from '../MultiMemoryCheckForm/MultiMemoryCheckSwitchForm';
import { SWITCH_INDEX_TYPE, StepTypeCheckSwitchForm } from './StepTypeCheckSwitchForm';

export function isCheckStep(branch: Branch) {
  return branch.switch && branch.switch.length > 0;
}

type StepTypeCheckProps = {
  stepIndex: number,
  memoryBankSlots: MemoryDTO[],
  branches: Branch[],
  currentBranchId: number,
  checkData: StepCheck,
  switchData: StepCheckSwitch[],
  addStepCheck: (
    stepIndex: number,
    variableId: number,
    operatorName: string,
    value: MemoryValue,
  ) => void,
  handleChangeStepCheck: (
    values: MemoryBankCheckValue[],
    stepIndex: number,
  ) => void,
  handleChangeStepSwitch: (
    gotoBranchId: number,
    stepIndex: number,
    switchIndex: number,
  ) => void,
  characters: Character[],
  isDisabled?: boolean,
  isMoreFunctions: boolean,
}

export function StepTypeCheck(props: StepTypeCheckProps) {
  const {
    stepIndex, memoryBankSlots, handleChangeStepCheck, handleChangeStepSwitch,
    checkData, switchData, branches, currentBranchId, addStepCheck, characters,
    isDisabled, isMoreFunctions,
  } = props;

  const currentSlot = memoryBankSlots.find((slot) => slot.id === Number(checkData?.variableId));
  const switchTrueGotoBranchId = switchData
    && switchData[SWITCH_INDEX_TYPE.TRUE] ? switchData[SWITCH_INDEX_TYPE.TRUE].gotoBranchId : 0;
  const switchFalseGotoBranchId = switchData
    && switchData[SWITCH_INDEX_TYPE.FALSE] ? switchData[SWITCH_INDEX_TYPE.FALSE].gotoBranchId : 0;

  const isCheckStepPossible = memoryBankSlots && memoryBankSlots.length > 0;

  useEffect(() => {
    if (!isCheckStepPossible) {
      return;
    }

    if (!checkData) {
      const variable = memoryBankSlots[0];
      const operator = isMoreFunctions ? StepCheckOperatorType.Min : StepCheckOperatorType.Equal;
      const value = '';
      addStepCheck(stepIndex, variable.id, operator, value);
    }
  }, [checkData, addStepCheck, isCheckStepPossible, memoryBankSlots, stepIndex, isMoreFunctions]);

  if (!checkData) {
    return null;
  }

  return (
    <>
      {!isMoreFunctions && (
      <Col>
        <MemorySlotCompareForm
          variableInputValue={checkData.variableId}
          operatorInputValue={checkData.operator}
          checkInputValue={checkData.value}
          currentSlot={currentSlot}
          inputNamePrefix={`steps[${stepIndex}][check]`}
          memoryBankSlots={memoryBankSlots}
          stepIndex={stepIndex}
          characters={characters}
          onChangeValue={handleChangeStepCheck}
          isDisabled={isDisabled}
        />
        <Row>
          <StepTypeCheckSwitchForm
            stepIndex={stepIndex}
            switchIndex={SWITCH_INDEX_TYPE.TRUE}
            gotoBranchId={switchTrueGotoBranchId}
            currentBranchId={currentBranchId}
            handleChangeStepSwitch={handleChangeStepSwitch}
            branches={branches}
            isDisabled={isDisabled}
          />
          <StepTypeCheckSwitchForm
            stepIndex={stepIndex}
            switchIndex={SWITCH_INDEX_TYPE.FALSE}
            gotoBranchId={switchFalseGotoBranchId}
            currentBranchId={currentBranchId}
            handleChangeStepSwitch={handleChangeStepSwitch}
            branches={branches}
            isDisabled={isDisabled}
          />
        </Row>
      </Col>
      )}
      {isMoreFunctions && (
        <Col>
          <MultiMemoryCheckForm
            operatorInputValue={checkData.operator}
            checkInputValue={checkData.value}
            inputNamePrefix={`steps[${stepIndex}][check]`}
            memoryBankSlots={memoryBankSlots}
            stepIndex={stepIndex}
            onChangeValue={handleChangeStepCheck}
            isDisabled={isDisabled}
          />
          <Col>
            <ListGroup variant="flush">
              {switchData?.map((data, index) => {
                const memoryName = memoryBankSlots.find((memory) => Number(memory.id) === Number(data.value))?.name ?? 'Unknown';
                return (
                  <ListGroup.Item key={data.value as string}>
                    <MultiMemoryCheckSwitchForm
                      stepIndex={stepIndex}
                      switchIndex={index}
                      gotoBranchId={data.gotoBranchId}
                      currentBranchId={currentBranchId}
                      handleChangeStepSwitch={handleChangeStepSwitch}
                      branches={branches}
                      isDisabled={isDisabled}
                      value={data.value as string}
                      label={memoryName}
                    />
                  </ListGroup.Item>
                );
              })}
            </ListGroup>
          </Col>
        </Col>
      )}
    </>
  );
}
