import axios from 'axios';
import classNames from 'classnames/bind';
import moment from 'moment';
import React, { Component } from 'react';
import {
  Alert, Button, Nav, Navbar,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import userIcon from '../../../assets/images/user.svg';
import { userApi } from '../../../services/userApi/UserApi';
import { api } from '../../api';
import { auth } from '../../Auth/Auth';
import { BoxNotifications } from './boxNotificationsItems';
import { BtnMenuCollapsible } from './BtnMenuCollapsible';
import { MenuSettings } from './menuSettings';
import { MobileAlert } from './MobileAlert';
import styles from './PageHeader.scss';

const cs = classNames.bind(styles);
const bgHeader = process.env.REACT_APP_ENVIRONMENTS_HEADER_BG_COLOR_HEX || null;

export class PageHeaderLoged extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      user: JSON.parse(localStorage.getItem('user')),
      maintenance: [],
      coAuthoringRequests: [],
    };

    this.el = document.createElement('div');
    this.el.style.display = 'contents';
    this.el.id = 'errorsBox';
    this.maintenanceIntervalID = undefined;
    this.loadUserIntervalID = undefined;
    this.cancelTokenSource = null;
    this.updateUser = this.updateUser.bind(this);
    this.loadMaintenance = this.loadMaintenance.bind(this);
    this.maintenanceMode = this.maintenanceMode.bind(this);
  }

  componentDidMount() {
    document.body.appendChild(this.el);
    window.addEventListener('focus', this.loadMaintenance);
    this.loadMaintenance();
  }

  componentDidUpdate() {
    const localStorageUser = JSON.parse(localStorage.getItem('user'));
    const { user } = this.state;
    if (
      localStorageUser !== user && (
        localStorageUser.firstName !== user.firstName
      || localStorageUser.lastName !== user.lastName
      || JSON.stringify(localStorageUser.image) !== JSON.stringify(user.image)
      )
    ) {
      this.setState({
        user: localStorageUser,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('focus', this.loadMaintenance);
    document.body.removeChild(this.el);
    clearTimeout(this.maintenanceIntervalID);
    clearTimeout(this.loadUserIntervalID);
    this.cancelTokenSource?.cancel('Operation canceled');
  }

  updateUser = (res) => {
    const userData = res.data.user;
    const user = auth.getUser();
    if (user) {
      user.latestActivity = userData.latestActivity;
    }

    if (res.data.documents) {
      const documents = JSON.parse(localStorage.getItem('documents'));
      if (
        JSON.stringify(documents) !== JSON.stringify(res.data.documents)
        || res.data.documents.length > 0
      ) {
        localStorage.setItem('documents', JSON.stringify(res.data.documents));
        this.loadUserIntervalID = setTimeout(() => {
          const d1 = JSON.parse(localStorage.getItem('documents'));
          if (d1 && d1.length > 0) {
            // window.location.reload();
          }
        }, 5000);
      }
    }

    auth.setUser(userData);
  };

  loadMaintenance() {
    if (this.cancelTokenSource) {
      this.cancelTokenSource.cancel('Operation canceled');
    }
    this.cancelTokenSource = axios.CancelToken.source();
    api.get('/v1/maintenance', { cancelToken: this.cancelTokenSource.token })
      .then((res) => {
        if (this.cancelTokenSource.token.reason) {
          return;
        }
        this.updateUser(res);
        this.setState({
          coAuthoringRequests: res.data.coAuthoringRequests,
          maintenance: res.data.config,
        }, () => {
          localStorage.setItem('maintenance_active', res.data.config.maintenance_active);
          localStorage.setItem('maintenance_timer', res.data.config.maintenance_timer);
          clearTimeout(this.maintenanceIntervalID);
          this.maintenanceIntervalID = setTimeout(() => this.loadMaintenance(), 15000);
        });
      })
      .catch((error) => {
        clearTimeout(this.maintenanceIntervalID);
        if (error) {
          this.maintenanceIntervalID = setTimeout(() => this.loadMaintenance(), 30000);
        }
      });
  }

  maintenanceMode() {
    const { maintenance } = this.state;
    const {
      maintenance_timer: maintenanceTimer,
      maintenance_active: maintenanceActive,
    } = maintenance;

    if (maintenanceActive && maintenanceTimer) {
      const then = maintenanceTimer;
      const now = moment().valueOf();
      const countdown = then - now;
      const duration = moment.duration(countdown, 'milliseconds');
      if (then > now) {
        return (
          <Nav className={cs('maintenanceMassage')}>
            Maintenance mode starts
            in
            <br />
            {' '}
            {`${duration.hours()} hr ${duration.minutes()} min`}
          </Nav>
        );
      }
      return (<Nav className={cs('maintenanceMassage')}>Maintenance mode is about to start...</Nav>);
    }
    return null;
  }

  render() {
    const { coAuthoringRequests, maintenance, user } = this.state;
    const { emailVerifie, pageTitle, showSettings } = this.props;

    const imgUrl = user.image && user.image.imageUrl ? user.image.imageUrl : userIcon;

    const userName = () => {
      let stateUserName = `${user.firstName} ${user.lastName}`;
      if (!user.firstName && !user.lastName) {
        stateUserName = user.fullname;
      }
      if (!user.firstName && !user.lastName && !user.fullname) {
        stateUserName = user.username;
      }
      return stateUserName;
    };

    return (
      <header className={cs('pageHeaderLoged')}>
        <Navbar
          collapseOnSelect
          expand="lg"
          bg="secondary"
          variant="dark"
          className={cs(maintenance.maintenance_active ? 'darkRed' : '')}
          ref={(el) => {
            if (el && bgHeader) {
              const imp = !maintenance.maintenance_active ? 'important' : null;
              el.style.setProperty('background-color', bgHeader, imp);
            }
          }}
        >
          <BtnMenuCollapsible />
          <Navbar.Brand>{pageTitle}</Navbar.Brand>

          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            {this.maintenanceMode()}
            <Nav>
              {String(showSettings) === String(user.role) && (
                <MenuSettings {...this.props} />
              )}
            </Nav>
            <Nav>
              <Nav.Item className={cs('userNavItem')}>
                <Link to="/profile" className="headerUserInfoText">
                  <span>
                    Hi,
                    {' '}
                    {userName()}
                  </span>
                  <i
                    style={{ backgroundImage: `url(${imgUrl})` }}
                    className="userNavItemIcon bg-secondary"
                  />
                </Link>
              </Nav.Item>
              <Button
                size="sm"
                variant="primary"
                onClick={() => userApi.logout()}
              >
                Log Out
              </Button>
            </Nav>
          </Navbar.Collapse>
          <Alert
            show={(!!emailVerifie && user.email && !user.email_verified)}
            variant="success"
            className="my-5 emailVerifiedAlert"
          >
            Please make sure you have verified your account.
          </Alert>
        </Navbar>
        <MobileAlert />
        <div className="boxNotifications">
          {coAuthoringRequests && coAuthoringRequests.length > 0
            && coAuthoringRequests.map((obj, index) => (
              <BoxNotifications
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                obj={obj}
              />
            ))}
        </div>
      </header>
    );
  }
}
