import { useEffect, useState } from 'react';
import { api } from '../../api';

export function useBackgrounds(debouncedSearchTerm, pageSize) {
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [backgrounds, setBackgrounds] = useState([]);

  useEffect(() => {
    const offset = pageSize * (currentPage - 1);
    const params = {
      limit: pageSize,
      offset,
      search: debouncedSearchTerm || undefined,
      order: 'createdAt:desc',
    };

    setIsLoading(true);
    api.get('/v1/backgrounds', { params })
      .then((res) => {
        const { images } = res.data;
        setTotalCount(res.data.totalCount);
        setBackgrounds(images);
      })
      .finally(() => setIsLoading(false));
  }, [currentPage, debouncedSearchTerm, pageSize]);

  return {
    currentPage,
    setCurrentPage,
    totalCount,
    backgrounds,
    isLoading,
  };
}
