import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { AvatarTool } from './index';

export class AvatarToolModal extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  render() {
    const {
      update,
      charactersGroups,
      obj,
      show,
      title,
      bookid,
      onHide,
      ...otherProps
    } = this.props;

    if (!show) {
      return null;
    }

    return (
      <Modal
        {...otherProps}
        show={show}
        title={title}
        bookid={bookid}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className="modalBig"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {obj ? `Edit Character - ${obj.name}` : 'Add Character'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {show ? (
            <AvatarTool
              charactersGroups={charactersGroups}
              updateAvatarModal={(val) => {
                update(val);
              }}
              bookid={bookid}
              onHide={onHide}
              title={title}
              obj={obj}
            />
          ) : null}
        </Modal.Body>
      </Modal>
    );
  }
}
