import {StoryBranchesWithContext} from "../../contexts/StoryBranchesWithContext/StoryBranchesWithContext";
import {Article} from '../pages/Article';
import {Articles} from '../pages/Articles';
import {Book} from '../pages/Book';
import {BookCreateLink} from '../pages/BookCreateLink';
import {BookPerformance} from '../pages/BookPerformance';
import {Books} from '../pages/Books';
import {Documents} from '../pages/Documents';
import {FanSignups} from '../pages/FanSignups';
import {PrivacyPolicyPage} from '../pages/Home/PrivacyPolicy';
import {CopyrightPolicyPage} from '../pages/Home/PrivacyPolicy/CopyrightPolicyPage';
import {TermsOfUsePage} from '../pages/Home/PrivacyPolicy/TermsOfUsePage';
import {ConfirmEmail} from '../pages/Logins/ConfirmEmail/ConfirmEmail';
import {ProvideName} from '../pages/Logins/ProvideName/ProvideName';
import {ResetPassword} from '../pages/Logins/ResetPassword/ResetPassword';
import {SetPassword} from '../pages/Logins/SetPassword/SetPassword';
import {SignUp} from '../pages/Logins/SignUp/SignUp';
import {VerifiedEmail} from '../pages/Logins/VerifiedEmail/VerifiedEmail';
import {Logout} from '../pages/Logout';
import {News} from '../pages/News';
import {Payout} from '../pages/Payout';
import {PlayPreview} from '../pages/PlayPreview';
import {Profile} from '../pages/Profile';
import {Search} from '../pages/Search';
import {StoryBranches} from '../pages/StoryBranches';
import {AvatarTool} from '../ui/AvatarTool';
import {StoryRedirect} from './StoryRedirect';

export const routesLogged = [
  {
    path: '/',
    exact: true,
    component: News,
  },
  {
    path: '/search',
    component: Search,
  },
  {
    path: '/home',
    component: News,
  },
  {
    path: '/mystories',
    component: News,
  },
  {
    path: '/create/template/:id',
    component: BookCreateLink,
  },
  {
    path: '/books',
    component: Books,
  },
  {
    path: '/verify',
    search: '?token=token',
    component: Books,
  },
  {
    path: '/articles/page/:id',
    component: Articles,
  },
  {
    path: '/articles',
    component: Articles,
  },
  {
    path: '/article/:id',
    component: Article,
  },
  {
    path: '/book/:id',
    component: Book,
  },
  {
    path: '/stories/:id/branches',
    component: StoryBranchesWithContext(StoryBranches),
  },
  {
    path: '/stories/:id',
    component: StoryRedirect,
  },
  {
    path: '/avatar-tool',
    component: AvatarTool,
  },
  {
    path: '/profile',
    component: Profile,
  },
  {
    path: '/play/:uuid',
    component: PlayPreview,
  },
  {
    path: '/fan',
    component: FanSignups,
  },
  {
    path: '/performance',
    component: BookPerformance,
  },
  {
    path: '/documents/:id',
    component: Documents,
  },
  {
    path: '/documents/',
    component: Documents,
  },
  {
    path: '/payout',
    component: Payout,
  },
  {
    path: '/privacy-policy',
    component: PrivacyPolicyPage,
  },
  {
    path: '/terms-of-use',
    component: TermsOfUsePage,
  },
  {
    path: '/copyright-policy',
    component: CopyrightPolicyPage,
  },
  {
    path: '/logout',
    component: Logout,
  },
  {
    path: '/reset-password',
    component: ResetPassword,
  },
  {
    path: '/set-password',
    component: SetPassword,
  },
  {
    path: '/provide-name',
    component: ProvideName,
  },
  {
    path: '/sign-up',
    component: SignUp,
  },
  {
    path: '/confirm-email',
    component: ConfirmEmail,
  },
  {
    path: '/verified-email',
    component: VerifiedEmail,
  },
];
