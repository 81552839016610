import React, { useState } from 'react';
import {
  Button, Col, Modal, Row,
} from 'react-bootstrap';
import { convertURLToAssetsImage } from '../../../utils/urlUtils';
import { CustomCharacterSelect } from '../CustomCharacterSelect/CustomCharacterSelect';
import classNames from './ImagePicker.module.scss';

/**
 * Allow select custom character image from library
 * @param title - String. Title for CustomCharacterSelect
 * @param value - HashMap. { imageURL, customCharacterId }
 * @param disabled - Bool.
 * @param onChange(imageUrl) - callback on save selected image
 * @returns {JSX.Element}
 */
export function ImagePicker({
  title,
  value,
  disabled,
  onChange,
}) {
  const [selectedCharacter, setSelectedCharacter] = useState(value);
  const [isModalShown, setIsModalShown] = useState(false);

  const handleSaveClick = () => {
    onChange(selectedCharacter);
    setIsModalShown(false);
  };

  const handleSelectCharacterClick = (newCharacter) => {
    const newSelectedCharacter = {
      imageURL: convertURLToAssetsImage(newCharacter.imageUrl),
      customCharacterId: newCharacter.id,
    };
    setSelectedCharacter(newSelectedCharacter);
  };

  return (
    <>
      <Button
        className={classNames.buttonExternal}
        disabled={disabled}
        variant={disabled ? 'outline-secondary' : 'secondary'}
        onClick={() => setIsModalShown(true)}
      >
        Image
      </Button>
      <Modal
        className={classNames.modal}
        size="lg"
        show={isModalShown}
        onHide={() => setIsModalShown(false)}
        centered
        fullscreen="sm-down"
      >
        <Modal.Body className="show-grid">
          <Row>
            <Col xs={12}>
              <CustomCharacterSelect
                title={title}
                activeCharacterId={selectedCharacter?.customCharacterId}
                onClick={handleSelectCharacterClick}
              />
            </Col>
          </Row>
          <Row className={classNames.modalButtons}>
            <Button
              className={classNames.button}
              variant="secondary"
              onClick={() => setIsModalShown(false)}
            >
              Cancel
            </Button>
            <Button
              className={classNames.button}
              variant="primary"
              onClick={handleSaveClick}
            >
              Choose
            </Button>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}
