import async from 'async';
import { isEmpty, omit } from 'lodash-es';
import React, { Component } from 'react';
import {
  Button, Card, Col, Row, Spinner,
} from 'react-bootstrap';
import { api } from '../../api';
import { Auth } from '../../Auth/Auth';
import { getBookType } from '../../pages/Book/utils';
import { convertAssetsImageToURL, convertURLToAssetsImage } from '../../utils/urlUtils';
import './avatar.scss';
import { AvatarCanvas } from './AvatarCanvas';
import { AvatarJSONEditor } from './AvatarJSONEditor';
import { AvatarPicker } from './AvatarPicker';
import { AvatarTab } from './AvatarTab';
import { AvatarToolTable } from './AvatarToolTable';
import { CharacterExpressions } from './CharacterExpressions/CharacterExpressions';
import { defaultExpressionName, defaultExpressionValue, emptyKey } from './CharacterExpressions/constants';
import { CharacterForm } from './CharacterForm';
import './style.scss';

const auth = new Auth();

export class AvatarTool extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      avatar: {},
      avatarForced: {},
      defaultsAvatarForced: {},
      defaults: {},
      properties: {},
      params: {},
      loading: true,
      loadingAvatar: true,
      itemPickerView: null,
      saveView: false,
      saveCopy: false,
      tab: 'face',
      file: null,
      fileUrl: null,
      editorView: false,
      editorType: null,
      characterExtraData: null,
      forced: {},
      defaultsForced: {},
      limits: [],
      customCharacterId: null,
      disabled: false,
      bookType: null,
    };

    this.imgBase64 = null;
    this.PATH_TO_ASSETS = null;// `${baseURL}avatar/`;
    this.AVATAR_TYPES = ['female', 'male', 'custom'];
    this.properties = {};
    this.tabs = [
      {
        id: 'face',
        title: 'Face',
        type: ['female', 'male', 'girl'],
        items: [
          {
            id: 'skincolor',
            forced: [],
          },
          {
            id: 'hairstyle',
            forced: ['female', 'male'],
          },
          {
            id: 'headshape',
            forced: [],
          },
          {
            id: 'eyebrowcolor',
            forced: [], // forced: ["female", "male"],
          },
          {
            id: 'eyebrowshape',
            forced: [], // forced: ["female", "male"],
          },
          {
            id: 'eyestyle',
            forced: [], // forced: ["female", "male"],
          },
          {
            id: 'stubblestyle',
            forced: [], // forced: ["female", "male"], /****/
          },
          {
            id: 'facialcomplexion',
            forced: [], // forced: ["female", "male"],
          },
          {
            id: 'noseshape',
            forced: [], // forced: ["female", "male"],
          },
          {
            id: 'lipcolor',
            forced: [], // forced: ["female", "male"],
          },
          {
            id: 'facialhairupperstyle',
            forced: [], // forced: ["female", "male"],
          },
          {
            id: 'facialhairlowerstyle',
            forced: [], // forced: ["female", "male"],
          },
          {
            id: 'facialhaircolor',
            forced: [], // forced: ["female", "male"],
          },
          {
            id: 'bodyhairstyle',
            forced: ['male'],
          },
        ],
      },
      {
        id: 'clothes',
        title: 'Clothes',
        type: ['female', 'male', 'girl'],
        items: [
          {
            id: 'headwearstyle',
            forced: ['female', 'male'],
          },
          {
            id: 'glassesstyle',
            forced: ['female', 'male'],
          },
          {
            id: 'scarfstyle',
            forced: ['female', 'male'],
          },
          {
            id: 'necklacestyle',
            forced: ['female', 'male'],
          },
          {
            id: 'topinnerstyle',
            forced: ['female', 'male'],
          },
          {
            id: 'bottomstyle',
            forced: ['female', 'male'],
          },
          {
            id: 'topouterstyle',
            forced: ['female', 'male'],
          },
        ],
      },
      {
        id: 'outfit',
        title: 'Outfit',
        type: ['female', 'male'],
        items: [
          {
            id: 'outfitstyle',
            forced: ['female', 'male'],
          },
        ],
      },
      {
        id: 'underwear',
        title: 'Underwear',
        type: ['female', 'male'],
        items: [
          {
            id: 'underwearstyle',
            forced: ['female'],
          },
        ],
      },
      {
        id: 'swimwear',
        title: 'Swimwear',
        type: ['female'],
        items: [
          {
            id: 'swimwearstyle',
            forced: ['female'],
          },
        ],
      },
    ];
    const { obj } = this.props;
    this.isPlayer = !obj || !obj.isPlayer ? false : obj.isPlayer;
  }

  componentDidMount() {
    this.loadData();
    const { obj } = this.props;
    if (obj && obj.forcedProperties) {
      this.loadAvatarForced(obj.forcedProperties);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { obj } = this.props;
    const {
      editorView, avatar, saveView, customCharacterId,
    } = this.state;

    if (
      obj !== prevProps.obj
      && obj.forcedProperties
      && obj.forcedProperties !== prevProps.obj.forcedProperties
    ) {
      this.loadAvatarForced(!obj.forcedProperties ? {} : obj.forcedProperties);
    }
    if (
      saveView !== prevState.saveView
      || editorView !== prevState.editorView
      || customCharacterId !== prevState.customCharacterId
      || avatar.type !== prevState.avatar.type
    ) {
      this.setState({
        disabled:
          saveView
          || editorView
          || (avatar.type === 'custom' && !avatar.image)
        ,
      });
    }
  }

  componentWillUnmount() {
    this.setState({
      loading: false,
    });
  }

  loadData = () => {
    const { bookid } = this.props;

    this.setState({
      loading: true,
    });
    async.parallel({
      config: (callback) => {
        api.get('/v1/avatar/config')
          .then((res) => {
            callback(null, res.data);
          }).catch((error) => {
            callback(error, null);
          });
      },
      limits: (callback) => {
        api.get('/v1/settings')
          .then((res) => {
            callback(null, res.data.settings.limits);
          }).catch((error) => {
            callback(error, null);
          });
      },
      book: (callback) => {
        api.get(`/v1/books/${bookid}`)
          .then((res) => {
            callback(null, res.data.book);
          }).catch((error) => {
            callback(error, null);
          });
      },
    }, (err, res) => {
      try {
        if (err) {
          this.setState({
            loading: false,
          });
        } else {
          const bookType = getBookType(res.book);

          this.PATH_TO_ASSETS = res.config.imageUrl;
          this.properties = res.config.config.properties;
          this.setState({
            params: res.config.params,
            layers: res.config.config.layers,
            loading: false,
            limits: res.limits,
            bookType,
          });
          this.initProperties();
        }
      } catch (e) {
        this.setState({
          loading: false,
        });
      }
    });
  };

  initProperties = () => {
    const { avatar } = this.state;
    const { obj } = this.props;

    const type = avatar.type ? avatar.type : this.properties.type.default;
    const defaults = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const avatarType of this.AVATAR_TYPES) {
      defaults[avatarType] = { type: avatarType };
    }
    const properties = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const [key, property] of Object.entries(this.properties)) {
      if (property.isConfigurable) {
        property.id = key;
        property.type = 'property';
        if (key !== 'type') {
          if (!property.values) property.values = {};
          if (!property.default) property.default = null;
          if (!(property.default && typeof property.default === 'object')) {
            const def = {};
            // eslint-disable-next-line no-restricted-syntax
            for (const avatarType of this.AVATAR_TYPES) {
              if (avatarType !== 'custom') {
                def[avatarType] = property.default;
              }
            }
            property.default = def;
          }
          // eslint-disable-next-line no-restricted-syntax
          for (const avatarType of this.AVATAR_TYPES) {
            if (avatarType !== 'custom') {
              if (property.default[avatarType]) {
                if (Array.isArray(property.default[avatarType])) {
                  const index = Math.floor(Math.random() * property.default[avatarType].length);
                  defaults[avatarType][key] = property.default[avatarType][index];
                } else {
                  defaults[avatarType][key] = property.default[avatarType];
                }
              }
              defaults[avatarType].type = avatarType;
            }
          }
        }
        properties.push(property);
      }
    }

    const parProps = obj && obj.avatar && obj.avatar.properties ? obj.avatar.properties : null;
    if (parProps && !parProps.type) {
      parProps.type = this.properties.type.default;
    }

    // Init custom characters for edit
    if (obj && parProps?.type === 'custom') {
      if (isEmpty(parProps.expressions)) {
        const expressionValue = {
          ...defaultExpressionValue,
          imageURL: convertURLToAssetsImage(obj.imageUrl),
        };
        parProps.expressions = {
          [defaultExpressionName]: expressionValue,
        };
      }
      this.setState({
        customCharacterId: parProps.expressions[defaultExpressionName]?.customCharacterId,
      });
    }

    this.setState({
      avatar: parProps || defaults[type],
      fileUrl: obj?.imageUrl,
      defaults,
      properties,
      characterExtraData: obj && obj.data ? obj.data : null,
    });
  };

  randomize = () => {
    const newProps = {};
    const { properties, avatar } = this.state;
    // eslint-disable-next-line no-restricted-syntax
    for (const property of properties) {
      if (!(property.id === 'type' || property.type === 'override')) {
        if (property.values[avatar.type]) {
          const choices = property.values[avatar.type]
            .filter((el) => el.disabled === false)
            .map((o) => o.id);

          if (!property.isRequired) {
            choices.push(null);
          }
          const i = Math.floor(Math.random() * choices.length);
          newProps[property.id] = choices[i];
        }
      }
    }
    newProps.type = avatar.type;
    this.setState({
      avatar: newProps,
    });
  };

  updateTab = (val) => {
    const { avatarForced, properties, avatar } = this.state;
    const parsedAvatar = JSON.parse(JSON.stringify(avatar));
    const parsedAvatarForced = JSON.parse(JSON.stringify(avatarForced));
    parsedAvatar.clothing = val === 'outfitstyle' || val === 'swimwear' || val === 'underwear' ? val : 'normal';
    parsedAvatarForced.clothing = val === 'outfitstyle' || val === 'swimwear' || val === 'underwear' ? val : 'normal';
    const itemsTab = this.tabs.find((e) => e.id === val);
    let itemPickerView = null;
    if (itemsTab.items.length === 1) {
      const obj = properties.find((e) => e.id === itemsTab.items[0].id);
      const valNew = [];
      valNew.id = avatar[obj.id];
      valNew.obj = obj;
      itemPickerView = valNew;
    }
    this.setState({
      avatarForced: parsedAvatarForced,
      avatar: parsedAvatar,
      tab: val,
      itemPickerView,
    });
  };

  resetButton = () => {
    const { obj } = this.props;
    const {
      defaults,
      avatar,
    } = this.state;

    const parsedDefaults = JSON.parse(JSON.stringify(defaults));

    const parProps = obj && obj.avatar && obj.avatar.properties ? obj.avatar.properties : null;
    if (parProps && !parProps.type) {
      parProps.type = this.properties.type.default;
    }
    this.setState({
      avatar: parProps || parsedDefaults[avatar.type],
    });
  };

  typesButton = () => {
    const {
      defaults,
      avatar,
      saveView,
      editorView,
      defaultsAvatarForced,
      defaultsForced,
    } = this.state;
    const parsedDefaults = JSON.parse(JSON.stringify(defaults));

    return this.AVATAR_TYPES.map((type, i) => {
      if (type === 'custom') {
        const user = auth.getUser();
        if (user && user.role !== 'admin' && !(user.actions && user.actions.includes('characters'))) {
          return null;
        }
      }

      return (
        <Button
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          size="sm"
          className="mr-2"
          disabled={(saveView || editorView)}
          variant={avatar.type === type ? 'primary' : 'secondary'}
          onClick={() => {
            let avatarForced = {};
            let forced = {};
            if (
              defaultsAvatarForced
              && defaultsAvatarForced.type
              && defaultsAvatarForced.type === type
            ) {
              avatarForced = JSON.parse(JSON.stringify(defaultsAvatarForced));
              forced = JSON.parse(JSON.stringify(defaultsForced));
            }
            this.setState({
              avatar: parsedDefaults[type],
              tab: 'face',
              file: null,
              fileUrl: null,
              itemPickerView: null,
              avatarForced,
              forced,
            });
          }}
        >
          {type}
        </Button>
      );
    });
  };

  handleForceChecked = (e, status) => {
    if (!this.isPlayer) {
      return;
    }
    const { avatarForced, forced, avatar } = this.state;

    const forcedCopy = JSON.parse(JSON.stringify(forced));
    const avatarCopy = JSON.parse(JSON.stringify(avatar));
    const avatarForcedCopy = avatarForced ? JSON.parse(JSON.stringify(avatarForced)) : {};

    if (!status && forcedCopy[e.id]) {
      delete avatarForcedCopy[e.id];
      delete forcedCopy[e.id];
    } else {
      forcedCopy[e.id] = status;
      // avatarForced[e.id] = (!this.state.avatar[e.id]) ? null : this.state.avatar[e.id];
      avatarForcedCopy.type = avatar.type;
      if (!avatar[e.id]) {
        avatarCopy[e.id] = 'removed';
        avatarForcedCopy[e.id] = 'removed';
      } else {
        avatarForcedCopy[e.id] = avatar[e.id];
      }
    }
    if (status && ['topinnerstyle', 'bottomstyle'].includes(e.id)) {
      delete avatarCopy.outfitstyle;
      delete forcedCopy.outfitstyle;
      delete avatarForcedCopy.outfitstyle;
    }
    this.setState({
      avatar: avatarCopy,
      forced: forcedCopy,
      avatarForced: avatarForcedCopy,
    });
  };

  loadAvatarForced(e) {
    this.setState({
      avatarForced: e,
      defaultsAvatarForced: JSON.parse(JSON.stringify(e)),
    }, () => {
      const { avatarForced } = this.state;
      if (this.isPlayer && avatarForced && Object.keys(avatarForced).length > 0) {
        const forced = {};
        Object.entries(avatarForced).map((obj) => {
          if (obj[0] !== 'type') {
            this.tabs.map((a) => a.items.map((b) => {
              if (b.id === obj[0]) {
                forced[b.id] = true;
              }
              return null;
            }));
          }
          return null;
        });
        this.setState({
          forced,
          defaultsForced: JSON.parse(JSON.stringify(forced)),
        });
      }
    });
  }

  handleCharacterExpressionsChange = (newValue) => {
    const { avatar } = this.state;

    const newAvatar = {
      ...avatar,
      expressions: newValue,
      image: newValue[defaultExpressionName]?.imageURL,
    };
    this.setState(
      {
        avatar: newAvatar,
        customCharacterId: newValue[defaultExpressionName]?.customCharacterId,
        fileUrl: convertAssetsImageToURL(newValue[defaultExpressionName]?.imageURL),
      },
    );
  };

  handleNextClick = () => {
    const { avatar } = this.state;
    if (avatar.type === 'custom') {
      const newExpressions = omit(avatar.expressions, emptyKey);
      this.setState({
        avatar: { ...avatar, expressions: newExpressions },
        customCharacterId: newExpressions[defaultExpressionName]?.customCharacterId,
      });
    }
    this.setState({
      saveView: true,
      saveCopy: false,
    });
  };

  render() {
    const {
      customCharacterId,
      characterExtraData,
      editorType,
      file,
      avatar,
      tab,
      saveView,
      loading,
      itemPickerView,
      limits,
      defaults,
      avatarForced,
      saveCopy,
      loadingAvatar,
      saveLoading,
      editorView,
      disabled,
      properties,
      params,
      fileUrl,
      layers,
      forced,
      bookType,
    } = this.state;

    const {
      charactersGroups,
      obj: object,
      onHide,
      title,
      id,
      bookid,
      updateAvatarModal,
    } = this.props;

    if (loading) {
      return (
        <div className="containerAvatarTool">
          <div className="box">
            <div className="avatarContainer">
              <div className="text-center">
                <Spinner
                  variant="primary"
                  animation="border"
                  className="loadingSpinner justify-content-center"
                />
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="containerAvatarTool">

        <div className="box">
          <div className="avatarContainer">
            <div className={`${(this.isPlayer && Object.keys(forced).length > 0) && 'forced'}`}>
              <div className="avatar-box">
                {
                  // eslint-disable-next-line no-nested-ternary
                  avatar.type
                    ? avatar.type === 'custom'
                      ? (
                        <img
                          src={fileUrl}
                          alt=""
                          className="avatarImgCustom"
                        />
                      )
                      : (
                        <AvatarCanvas
                          properties={avatar}
                          params={params}
                          update={(data) => {
                            this.imgBase64 = data;
                          }}
                          updateLoading={(val) => {
                            this.setState({
                              loadingAvatar: val,
                            });
                          }}
                        />
                      )
                    : null
                }
              </div>

              {
                (
                  this.isPlayer
                  && Object.keys(forced).length > 0
                  && (avatar.type || avatar.type !== 'custom')
                )
                && (
                <div className="avatar-box forced-avatar-box">
                  <h6>Forced</h6>
                  {
                    Object.values(avatarForced).find((_avatar) => _avatar === 'removed')
                    && (
                    <div className="forced-removed-box">
                      <h5>REMOVED</h5>
                      {
                        // eslint-disable-next-line react/no-array-index-key
                        Object.entries(avatarForced).map((_avatar, i) => (_avatar[1] === 'removed') && <p key={i}>{_avatar[0]}</p>)
                      }
                    </div>
                    )
                  }
                  <AvatarCanvas
                    layers={layers}
                    properties={avatarForced}
                    forced
                    params={params}
                    tabs={this.tabs}
                    update={() => {
                      //  this.imgBase64 = data;
                    }}
                  />
                </div>
                )
              }

              <section style={{ width: '100%' }}>
                <div className="canvas-menu">
                  <Button
                    size="sm"
                    id="randomize"
                    variant="primary"
                    onClick={this.randomize}
                    disabled={(
                      saveView
                      || editorView
                      || avatar.type === 'custom'
                      || loadingAvatar
                    )}
                  >
                    {loading || loadingAvatar ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : null}
                    Randomize!
                  </Button>

                  <Button
                    size="sm"
                    id="reset"
                    variant="primary"
                    onClick={this.resetButton}
                    disabled={(
                      saveView
                      || editorView
                      || avatar.type === 'custom'
                      || loadingAvatar
                    )}
                  >
                    Reset
                  </Button>
                </div>
              </section>
            </div>
          </div>

          <div className="itemContainer">
            <div id="config-box">

              <div className="alert alert-secondary mt-1" role="alert" hidden>
                <div id="alert-message" />
                <div className="small text-faded">Click or press Esc to dismiss</div>
              </div>

              <section className="type-section">
                <div className="button-section type-buttons">
                  {this.typesButton()}
                </div>
                <div>
                  {
                    !!onHide
                    && (
                    <Button
                      className="mx-1"
                      size="md"
                      type="reset"
                      variant="secondary"
                      onClick={() => onHide()}
                    >
                      CLOSE
                    </Button>
                    )
                  }

                  <Button
                    size="md"
                    type="submit"
                    variant="primary"
                    disabled={disabled}
                    onClick={this.handleNextClick}
                  >
                    {
                      saveLoading
                      && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      )
                    }
                    NEXT
                  </Button>
                </div>
              </section>

              <div className={`${saveView || editorView ? 'd-none' : ''}`}>
                <section className="tab-section">
                  <div className="nav">
                    <div className="tabs clothing-buttons">
                      {
                        this.tabs.map((obj, i) => (
                          <AvatarTab
                            // eslint-disable-next-line react/no-array-index-key
                            key={i}
                            obj={obj}
                            tab={tab}
                            avatar={avatar}
                            update={this.updateTab}
                          />
                        ))
                      }
                    </div>
                  </div>
                </section>

                {
                  avatar.type === 'custom'
                    ? (
                      <Card className="custom-character-card" id="main-view">
                        <Col className="custom-character-card-title">
                          <h4>Expressions:</h4>
                        </Col>
                        <CharacterExpressions
                          value={avatar?.expressions}
                          onChange={this.handleCharacterExpressionsChange}
                        />
                      </Card>
                    )
                    : (
                      <AvatarToolTable
                        tab={tab}
                        tabs={this.tabs}
                        view={(itemPickerView)}
                        itemPickerShow={(obj) => {
                          this.setState({
                            itemPickerView: obj,
                          });
                        }}
                        properties={properties}
                        avatar={avatar}
                        forced={forced}
                      />
                    )
                }

                <AvatarPicker
                  tab={tab}
                  tabs={this.tabs}
                  path={this.PATH_TO_ASSETS}
                  avatar={avatar}
                  avatarTypes={this.AVATAR_TYPES}
                  avatarForced={avatarForced}
                  forceChecked={this.handleForceChecked}
                  forced={forced}
                  isPlayer={this.isPlayer}
                  obj={itemPickerView}
                  onHide={() => {
                    this.setState({
                      itemPickerView: null,
                    });
                  }}
                  update={(type, value) => {
                    let copyAvatar = JSON.parse(JSON.stringify(avatar));
                    const copyAvatarForced = JSON.parse(JSON.stringify(avatarForced));
                    if (type === 'type') {
                      const copyDefaults = JSON.parse(JSON.stringify(defaults));
                      copyAvatar = copyDefaults[value];
                    } else {
                      copyAvatar[type] = value;
                    }
                    if (
                      this.isPlayer
                      && Object.keys(forced).length > 0
                      && (avatar.type || avatar.type !== 'custom')
                      && forced[type]
                    ) {
                      copyAvatarForced[type] = value;
                    }
                    this.setState({
                      avatar: copyAvatar,
                      avatarForced: copyAvatarForced,
                    });
                  }}
                />
              </div>

              {
                (editorView && editorType === 'props')
                && (
                <AvatarJSONEditor
                  title="Edit Properties"
                  obj={avatar}
                  onHide={() => {
                    this.setState({
                      editorView: false,
                      editorType: null,
                    });
                  }}
                  update={(value) => {
                    this.setState({
                      avatar: value,
                      editorView: false,
                      editorType: null,
                    });
                  }}
                />
                )
              }

              {
                (editorView && editorType === 'data')
                && (
                <AvatarJSONEditor
                  title="Edit Data"
                  obj={characterExtraData}
                  onHide={() => {
                    this.setState({
                      editorView: false,
                      editorType: null,
                    });
                  }}
                  update={(value) => {
                    this.setState({
                      characterExtraData: value,
                      editorView: false,
                      editorType: null,
                    });
                  }}
                />
                )
              }

              {
                saveView
                && (
                <CharacterForm
                  charactersGroups={charactersGroups}
                  obj={object}
                  id={id || null}
                  bookid={bookid}
                  customCharacterId={customCharacterId}
                  file={file}
                  view={saveView}
                  saveCopy={saveCopy}
                  avatar={avatar}
                  extraData={characterExtraData}
                  title={title}
                  params={this.imgBase64}
                  avatarForced={avatarForced}
                  limits={limits}
                  onHide={() => {
                    this.setState({
                      saveView: false,
                      saveCopy: false,
                    });
                  }}
                  updateAvatar={(val) => {
                    this.setState({
                      saveView: false,
                      saveCopy: false,
                    }, () => {
                      if (updateAvatarModal) {
                        updateAvatarModal(val);
                      }
                    });
                  }}
                  userRole={auth.getUser().role}
                  bookType={bookType}
                />
                )
              }

              <Row
                className={`saveBtnContainer mx-0 ${saveView || editorView ? 'd-none' : ''}`}
              >
                <Col className="px-0">
                  {
                    object
                      ? (
                        <Button
                          className="mx-1"
                          size="sm"
                          type="reset"
                          variant="primary"
                          disabled={disabled}
                          onClick={() => {
                            this.setState({
                              saveView: true,
                              saveCopy: true,
                            });
                          }}
                        >
                          Save Copy
                        </Button>
                      )
                      : null
                  }
                  {
                    (auth.getUser() && auth.getUser().role === 'admin')
                    && (
                    <>
                      <Button
                        className="mx-1"
                        size="sm"
                        variant="primary"
                        disabled={disabled}
                        onClick={() => {
                          this.setState({
                            editorView: true,
                            editorType: 'props',
                          });
                        }}
                      >
                        Edit properties
                      </Button>
                      <Button
                        className="mx-1"
                        size="sm"
                        variant="primary"
                        disabled={disabled}
                        onClick={() => {
                          this.setState({
                            editorView: true,
                            editorType: 'data',
                          });
                        }}
                      >
                        Edit data
                      </Button>
                    </>
                    )
                  }

                </Col>
                <Col className="text-right px-0">
                  {
                    !!onHide
                    && (
                    <Button
                      className="mx-1"
                      size="md"
                      type="reset"
                      variant="secondary"
                      onClick={() => onHide()}
                    >
                      CLOSE
                    </Button>
                    )
                  }

                  <Button
                    size="md"
                    type="submit"
                    variant="primary"
                    disabled={disabled}
                    onClick={this.handleNextClick}
                  >
                    {
                      saveLoading && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )
                    }
                    NEXT
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
