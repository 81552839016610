import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import {
  CheckOperator,
  MemoryDTO,
  MemoryLinkValue, MemoryType,
  MemoryValue, StepCheckOperatorType,
} from 'components/pages/Book/MemoryBank/memoryBankTypes';
import { Character } from 'dorian-shared/types/character/Character';
import {
  getMemorySlotValue,
  getMemorySlotVariableLink,
  isMemorySlotVariableLink,
} from '../../../../utils/choiceMemoryUtils';
import { MemoryMultiSelectForm } from './MemoryMultiSelectForm/MemoryMultiSelectForm';
import { MemorySlotCompareValueForm } from './MemorySlotCompareValueForm';
import { MemorySlotCompareVariableForm } from './MemorySlotCompareVariableForm';

export type MemoryBankCheckValue = {
  name: string,
  value: MemoryValue | MemoryLinkValue | null,
}

interface MemorySlotCompareFormProp {
  inputNamePrefix: string,
  variableInputValue: number,
  operatorInputValue: string,
  checkInputValue: MemoryValue | MemoryLinkValue,
  currentSlot?: MemoryDTO,
  memoryBankSlots?: MemoryDTO[],
  stepIndex: number,
  characters: Character[],
  onChangeValue: (
    values: MemoryBankCheckValue[],
    stepIndex: number,
  ) => void,
  isDisabled?: boolean,
}

export function MemorySlotCompareForm(props: MemorySlotCompareFormProp) {
  const {
    inputNamePrefix,
    variableInputValue,
    operatorInputValue,
    checkInputValue,
    currentSlot,
    memoryBankSlots,
    stepIndex,
    characters,
    onChangeValue,
    isDisabled,
  } = props;

  const initialIsVariableCompare = isMemorySlotVariableLink(checkInputValue);
  const [isVariableCompare, setIsVariableCompare] = useState<boolean>(initialIsVariableCompare);

  const variables = memoryBankSlots?.filter(
    (slot) => slot.type === currentSlot?.type && slot.id !== currentSlot?.id,
  );

  if (!variables) {
    return null;
  }

  return (
    <Row xs={1} sm={2} lg={3}>
      <Form.Group as={Row}>
        <Col lg={4}>
          <Form.Label column>
            Whether
          </Form.Label>
        </Col>
        <Col>
          <Form.Control
            disabled={isDisabled}
            size="sm"
            as="select"
            name={`${inputNamePrefix}[variableId]`}
            value={variableInputValue}
            onChange={(event) => {
              const values: MemoryBankCheckValue[] = [
                {
                  name: 'variableId',
                  value: Number(event.target.value),
                },
                {
                  name: 'value',
                  value: 0,
                },
              ];

              const isOperatorToChange = operatorInputValue !== StepCheckOperatorType.Equal
                && operatorInputValue !== StepCheckOperatorType.NotEqual;
              if (isOperatorToChange) {
                values.push({
                  name: 'operator',
                  value: 0,
                });
              }

              onChangeValue(values, stepIndex);
            }}
          >
            <option />
            {memoryBankSlots?.map((slot) => (
              <option key={slot.id} value={slot.id}>
                {slot.name}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Col lg="auto">
          <Form.Label column>
            is
          </Form.Label>
        </Col>
        <Col className="mr-3">
          <Form.Control
            disabled={isDisabled}
            size="sm"
            as="select"
            name={`${inputNamePrefix}[operator]`}
            value={operatorInputValue}
            onChange={(event) => onChangeValue([{
              name: 'operator',
              value: event.target.value,
            }], stepIndex)}
          >
            {Object.entries(CheckOperator).map(([operatorKey, operatorValue]) => {
              if (operatorValue.isMultiSelect) {
                return null;
              }
              const isSlotANumber = currentSlot?.type === MemoryType.Number;
              const isOnlyNumberOperator = operatorKey !== StepCheckOperatorType.Equal
                && operatorKey !== StepCheckOperatorType.NotEqual;

              if (!isSlotANumber && isOnlyNumberOperator) {
                return null;
              }
              return (
                <option key={`option${operatorKey}`} value={operatorKey}>
                  {operatorValue.title}
                </option>
              );
            })}
          </Form.Control>
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Col lg="auto" className="mt-1">
          <Form.Check
            custom
            id="useMemoryToCampare"
            disabled={isDisabled}
            checked={isVariableCompare}
            onChange={(event) => {
              setIsVariableCompare(event.target.checked);
            }}
            type="checkbox"
            label="use memory"
          />
        </Col>
        {isVariableCompare && (
        <Col>
          <MemorySlotCompareVariableForm
            valueInputValue={getMemorySlotVariableLink(checkInputValue)}
            stepIndex={stepIndex}
            onChangeValue={onChangeValue}
            inputName={`${inputNamePrefix}[value]`}
            isDisabled={isDisabled}
            variables={variables}
          />
        </Col>
        )}
        {!isVariableCompare && (
        <Col>
          <MemorySlotCompareValueForm
            onChangeValue={onChangeValue}
            stepIndex={stepIndex}
            valueInputValue={getMemorySlotValue(checkInputValue)}
            characters={characters}
            memoryBankSlot={currentSlot}
            inputName={`${inputNamePrefix}[value]`}
            isDisabled={isDisabled}
          />
        </Col>
        )}
      </Form.Group>
    </Row>
  );
}
