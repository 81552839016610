import React from 'react';
import { Form } from 'react-bootstrap';
import classes from './MemoryField.module.scss';

export function MemoryDefaultChangeDescriptionField(props: React.ComponentProps<typeof Form.Control>) {
  const {
    id,
    value = '',
    disabled,
    placeholder = disabled ? '-' : '',
    errorMessage,
    ...rest
  } = props;

  return (
    <Form.Group className="position-relative">
      <Form.Label
        htmlFor={id}
        className={classes.memoryLabel}
      >
        Description
      </Form.Label>
      <Form.Control
        id={id}
        type="text"
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        {...rest}
      />
      <Form.Control.Feedback type="invalid" tooltip>
        {errorMessage}
      </Form.Control.Feedback>
    </Form.Group>
  );
}
