import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import rateLimit from 'axios-rate-limit';
import { MemoryIcon } from '../../components/pages/Book/MemoryBank/memoryBankTypes';
import { Branch } from '../../dorian-shared/types/branch/Branch';
import { Character } from '../../dorian-shared/types/character/Character';
import { ExpressionConfig } from '../../dorian-shared/types/character/ExpressionConfig';
import { MemoryIconGetResponse, MemoryIconPostRequest, MemoryIconPostResponse } from '../memoryBankService/types';

export class ApiService {
  private _rateLimitInstance: AxiosInstance;
  private _instance: AxiosInstance;

  public readonly MAX_REQUESTS_COUNT = 1;

  constructor(service: AxiosInstance) {
    this._rateLimitInstance = rateLimit(service, { maxRequests: this.MAX_REQUESTS_COUNT });
    this._instance = service;
  }

  public async fetchExpressionNames(): Promise<ExpressionConfig[]> {
    const response = await this._rateLimitInstance.get('/v1/characters/expressions');
    return response.data.expressions as ExpressionConfig[];
  }

  public async uploadCharacterFile(
    formData: FormData,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse> {
    const response = await this._rateLimitInstance.post('/v1/customcharacters', formData, config);
    return response;
  }

  public async uploadBackgroundFile(
    formData: FormData,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse> {
    return await this._rateLimitInstance.post('/v1/backgrounds', formData, config);
  }

  public async fetchCharactersByBookId(bookId: number): Promise<Character[] | null> {
    const characters = await this._instance.get(`/v1/books/${bookId}/characters`);
    return characters?.data?.characters;
  }

  public async fetchMemoryIconsByBookId(bookId: number): Promise<MemoryIcon[]> {
    const response: AxiosResponse<MemoryIconGetResponse> = await this._instance.get(`/v1/books/${bookId}/memoryIcons/`);
    const memoryIcons: MemoryIcon[] = response.data.icons ?? [];
    return memoryIcons.map((memoryIcon: MemoryIcon) => ({
      id: memoryIcon.id,
      label: memoryIcon.label,
      bookId: memoryIcon.bookId,
      key: memoryIcon.key,
      userId: memoryIcon.userId,
      type: memoryIcon.type,
      url: memoryIcon.url,
    }));
  }

  public async createMemoryIconByUserId(
    userId: number,
    memoryIconPostRequest: MemoryIconPostRequest,
  ): Promise<MemoryIcon> {
    const memoryIconFormData = new FormData();
    memoryIconFormData.append('image', memoryIconPostRequest.image);
    memoryIconFormData.append('label', memoryIconPostRequest.label);
    memoryIconFormData.append('bookId', memoryIconPostRequest.bookId.toString());

    const response: AxiosResponse<MemoryIconPostResponse> = await this._instance.post(`/v1/users/${userId}/memoryIcons`, memoryIconFormData);
    return {
      id: response.data.icon.id,
      label: response.data.icon.label,
      bookId: response.data.icon.bookId,
      key: response.data.icon.key,
      userId: response.data.icon.userId,
      type: response.data.icon.type,
      url: response.data.icon.url,
    };
  }

  public async fetchMemoryIconsByUser(userId: number) : Promise<MemoryIcon[]> {
    const response: AxiosResponse<MemoryIconGetResponse> = await this._instance.get(`/v1/users/${userId}/memoryIcons`);
    return response.data.icons.map((memoryIcon: MemoryIcon) => ({
      id: memoryIcon.id,
      label: memoryIcon.label,
      bookId: memoryIcon.bookId,
      key: memoryIcon.key,
      userId: memoryIcon.userId,
      type: memoryIcon.type,
      url: memoryIcon.url,
    }));
  }

  public async fetchStoryBranchesByEpisodeId(episodeId: number): Promise<Branch[]> {
    const response = await this._instance.get(`/v1/stories/${episodeId}/branches`);
    return response.data.branches;
  }
}
