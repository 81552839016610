import classNames from 'classnames/bind';
import React, { Component } from 'react';
import {
  Alert, Button, Modal, Spinner,
} from 'react-bootstrap';
import { api } from '../../api';
import styles from './Characters.scss';

const cs = classNames.bind(styles);

export class CharactersDelete extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showLoading: false,
      formError: null,
    };
  }

  errorAlert = (error) => {
    this.setState({
      formError: error,
    });
    setTimeout(() => {
      this.setState({
        formError: null,
      });
    }, 5000);
  };

  deleteCharactersAction() {
    this.setState({ showLoading: true });
    const {
      obj,
      update,
      args,
    } = this.props;

    api.delete(`/v1/books/${args.id}/characters/${obj.id}`)
      .then(() => {
        update();
      })
      .catch((error) => {
        if (error.response) {
          this.errorAlert(error.response.data.error);
        }
        this.setState({ showLoading: false });
      });
  }

  render() {
    const {
      showLoading,
      formError,
    } = this.state;

    const { onHide } = this.props;

    return (
      <Modal
        className={cs('deleteModal')}
        {...this.props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Character</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className={formError === null ? 'd-none' : 'd-block'}>
            <Alert variant="danger">
              {formError}
            </Alert>
          </p>

          <p className="text-center">
            Do you really want to delete this Character?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="reset"
            variant="secondary"
            onClick={onHide}
          >
            Cancel
          </Button>

          <Button
            type="submit"
            variant="primary"
            onClick={() => this.deleteCharactersAction()}
          >
            {showLoading ? (
              <Spinner
                variant="primary"
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : null}
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
