import classNames from 'classnames/bind';
import React from 'react';
import styles from '../../../Characters/Characters.scss';
import { PreviewImg } from '../../../Characters/PreviewImg';

const cs = classNames.bind(styles);

const previewImageStyle = {
  height: '60px',
  opacity: 1,
  cursor: 'pointer',
};

export function LocationsList({
  backgrounds,
  selectedImageId,
  onImageClick,
}) {
  const noSearchedBackgrounds = backgrounds.length === 0;
  const noBackgrounds = backgrounds.length === 0;

  return (
    <ul className={cs('thumbnails', 'image_selector', 'mt-2')}>
      {noBackgrounds && (
      <li>
        No backgrounds exist, please create one first
      </li>
      )}
      {!noBackgrounds && noSearchedBackgrounds && (
      <li>
        Nothing found, please try another search
      </li>
      )}
      {backgrounds.map((background) => {
        const {
          label, id, alias,
        } = background;

        const selected = selectedImageId && selectedImageId === id;

        return (
        // eslint-disable-next-line max-len
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
          <li
            key={id}
            onClick={() => onImageClick(background)}
          >
            <PreviewImg
              style={previewImageStyle}
              character={{ image: background }}
              trigger="hover"
              className={cs('thumbnail', selected ? 'selected' : '')}
              alias={alias}
              label={label || alias}
            />
          </li>
        );
      })}
    </ul>
  );
}
