import { AnswerStep } from 'dorian-shared/types/branch/AnswerStep';
import {Branch, StepTypeMode} from "../../dorian-shared/types/branch/Branch";
import { BranchStep, StepTypes } from '../../dorian-shared/types/branch/BranchStep';
import { EliminationAnswerResultType } from '../pages/StoryBranches/BranchEdit/Steps/AnswerEliminationType';

export const isStepTypeIdExist = (
  steps: BranchStep[],
  stepTypeId: StepTypes,
): boolean => steps.find((step) => step && (Number(step.stepTypeId) === stepTypeId)) !== undefined;

export const getIndexByStepTypeId = (
  steps: BranchStep[],
  stepTypeId: StepTypes,
) : number => steps.findIndex(
  (step) => Number(step.stepTypeId) === stepTypeId,
);

// Disable elimination correct answer if there is another correct answer
export const isEliminationCorrectOptionCanBeDisplayed = (
  answers: AnswerStep[],
  currentAnswerId: number,
) => {
  const eliminationCorrectAnswer = answers
    .find((answer) => answer.eliminationResultType === EliminationAnswerResultType.Correct);
  return eliminationCorrectAnswer
      && eliminationCorrectAnswer.id !== currentAnswerId;
};


export const isDecisionStep = (branch: Branch) => {
  const isCompetitiveMode = branch.stepTypeMode === StepTypeMode.Elimination;
  const isChoiceStep = isStepTypeIdExist(branch.steps ?? [], StepTypes.Choice);
  const hasBranchLinks = branch.links && branch.links.length > 0;
  return  isCompetitiveMode || hasBranchLinks || isChoiceStep;
}
