import serialize from 'form-serialize';
import React, { Component } from 'react';
import {
  Alert, Button, Col, Form, Modal, Spinner,
} from 'react-bootstrap';
import imgEdit from '../../../assets/images/edit-story.png';
import { api } from '../../api';
import { Tags } from './Tags';

export class EditAlias extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      showLoading: false,
      validated: false,
      formError: null,
    };
    this.isAdmin = props.user.role === 'admin';
  }

  handleSubmit(event) {
    event.preventDefault();
    const form = event.currentTarget;
    const validated = form.checkValidity();
    const obj = serialize(form, { hash: true, empty: true });

    // TODO: What type is used? bool or string?
    obj.disabled = !(!obj.disabled || obj.disabled !== 'on');

    if (validated === false) {
      event.stopPropagation();
    } else {
      this.action(obj);
    }
    this.setState({ validated: true });
    event.stopPropagation();
  }

  editModal = () => {
    const { obj, type } = this.props;

    const {
      showLoading, showModal, formError, validated,
    } = this.state;

    if (showModal !== true) {
      return null;
    }

    return (
      <Modal
        className="deleteModal"
        show
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={this.onHide}
      >
        <Form
          noValidate
          validated={validated}
          onSubmit={(e) => this.handleSubmit(e)}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {type === 'character' ? 'Edit Character Art' : 'Edit Image'}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="overflow-visible">
            <Col md={12} className={formError === null ? 'd-none' : 'd-block'}>
              <Alert variant="danger">
                {formError}
              </Alert>
            </Col>
            <Form.Row>
              {/* ALIAS FIELD */}
              {
                this.isAdmin && (
                  <Form.Group as={Col}>
                    <Form.Label>
                      {type === 'character' ? 'Character' : 'Image'}
                      {' '}
                      Alias
                    </Form.Label>
                    <Form.Control
                      required
                      size="sm"
                      type="text"
                      placeholder="Alias"
                      defaultValue={obj.alias || ''}
                      name="alias"
                      pattern="^([A-Za-z]|[0-9]|_|-|)+$"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please choose
                      {' '}
                      {type === 'character' ? 'Character' : 'Image'}
                      {' '}
                      Alias.
                    </Form.Control.Feedback>
                  </Form.Group>
                )
              }
              {/* LABEL FIELD */}
              <Form.Group as={Col}>
                <Form.Label>{type === 'character' ? 'Character Name' : 'Image label'}</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  placeholder={type === 'character' ? 'Name' : 'Label'}
                  defaultValue={type === 'character' ? obj.title : obj.label || ''}
                  name={type === 'character' ? 'title' : 'label'}
                />
                <Form.Control.Feedback type="invalid">
                  Please choose
                  {' '}
                  {type === 'character' ? 'Character Name' : 'Image label'}
                  .
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>

            {
                (!type || type !== 'character')
                && (
                <>
                  <Form.Row>
                    <Form.Group as={Col} controlId="disabled">
                      <Form.Check
                        custom
                        type="checkbox"
                        label="Disabled"
                        name="disabled"
                        defaultChecked={obj.disabled || false}
                      />
                    </Form.Group>
                  </Form.Row>

                  <Tags tags={obj.tags} />
                </>
                )
              }
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="reset"
              variant="secondary"
              onClick={this.onHide}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="primary"
            >
              {
                  showLoading && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )
                }
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  };

  onHide = () => {
    this.setState({
      showModal: false,
    });
  };

  errorAlert = (error) => {
    this.setState({
      formError: error,
    });
    setTimeout(() => {
      this.setState({
        formError: null,
      });
    }, 5000);
  };

  action(dataRequest) {
    const { obj, update, type } = this.props;

    this.setState({
      showLoading: true,
    });

    const url = type === 'character'
      ? `/v1/customcharacters/${obj.id}`
      : `/v1/backgrounds/${obj.id}`;

    api.put(url, dataRequest)
      .then(() => {
        this.setState({ showLoading: false });
        if (update) {
          update();
        }
        this.onHide();
      })
      .catch((error) => {
        this.errorAlert(error.response.data.error);
        this.setState({
          showLoading: false,
        });
      });
  }

  render() {
    return (
      <>
        <Button
          onClick={() => {
            this.setState({ showModal: true });
          }}
          variant="secondary"
          size="sm"
          className="mx-1"
        >
          <img
            src={imgEdit}
            className="btnImg"
            alt="Edit"
          />
        </Button>
        {this.editModal()}
      </>
    );
  }
}
