import { StepCheck, StepCheckSwitch } from '../../../components/pages/Book/MemoryBank/memoryBankTypes';
import { ChoiceMemoryAction } from '../../../services/choiceMemory/types/ChoiceMemoryAction';
import { Character } from '../character/Character';
import { ExpressionValue } from '../character/ExpressionValue';
import { AnswerStep } from './AnswerStep';

export enum StepTypes {
    Dialogue = 1,
    Narrator = 2,
    Choice = 3,
    Ending = 5,
    Result = 6,
    PlayerSelect = 7,
    Texting = 8,
    Thinking = 9,
    Reaction = 10,
    Check = 11,
    Remember = 12,
}

export type BranchStep = {
    id: number;
    index: null;
    stepTypeId: StepTypes;
    subType: null;
    text: string;
    characterId: number | null;
    characterRelationship: null;
    characterExpressionId: number;
    character?: Character;
    characterPlacementSide: 'default' | 'left' | 'right';
    branchId: number;
    data: Record<string, unknown>; // {} | [];
    expression?: {
        id: number;
        title: string;
        value: ExpressionValue;
        def: boolean;
        createdAt: string; // UTC format date
        updatedAt: string; // UTC format date
    };
    answers: AnswerStep[];
    wordCount: number;
    switch?: StepCheckSwitch[];
    action?: ChoiceMemoryAction;
    check?: StepCheck;
}
