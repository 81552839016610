import { FormikErrors } from 'formik';
import React, { ChangeEvent } from 'react';
import {
  Button, Col, Form, Row,
} from 'react-bootstrap';
import { Character } from 'dorian-shared/types/character/Character';
import classes from './MemoryBank.module.scss';
import {
  MemoryFormDTO, MemoryIcon, MemoryShowIn, MemoryType,
} from './memoryBankTypes';
import { MemoryDefaultChangeDescriptionField } from './MemoryFields/MemoryDefaultChangeDescriptionField';
import { MemoryDisplayNameField } from './MemoryFields/MemoryDisplayNameField';
import { MemoryIconField } from './MemoryFields/MemoryIconField';
import { MemoryNameField } from './MemoryFields/MemoryNameField';
import { MemoryShowInAlertField } from './MemoryFields/MemoryShowInAlertField';
import { MemoryTypeField } from './MemoryFields/MemoryTypeField';
import { MemoryValueField } from './MemoryFields/MemoryValueField';

type MemoryBankSlotsFormProps = {
  memoryIndex: number,
  memoryFormDTO: MemoryFormDTO,
  errors: FormikErrors<MemoryFormDTO>,
  onChange: (event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>, slotIndex: number) => void,
  onDelete: (slotIndex: number) => void,
  onIconAdd: (image: File, label: string) => Promise<MemoryIcon>,
  characters: Character[],
  memoryIcons: MemoryIcon[],
  disabled?: boolean,
}

export function MemoryForm(props: MemoryBankSlotsFormProps) {
  const {
    onChange, onDelete, memoryFormDTO, memoryIndex, errors,
    characters, memoryIcons, onIconAdd, disabled,
  } = props;

  const {
    type, value, name, displayName, defaultChangeDescription, icon, showIn, showInAlert,
  } = memoryFormDTO;

  const handleChange = (event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) => {
    onChange(event, memoryIndex);
  };

  const handleDeleteClick = () => {
    onDelete(memoryIndex);
  };

  const isShowIn = showIn && (showIn.includes(MemoryShowIn.Alert) || showIn.includes(MemoryShowIn.Panel));
  const isShowInDisabled = type !== MemoryType.Number;
  const isMemoryVisionDisabled = !isShowIn || isShowInDisabled;

  return (
    <Row>
      <Col xl lg={2} md={3}>
        <MemoryNameField
          required
          id={`memoryBankSlots[${memoryIndex}].name`}
          value={name}
          onChange={handleChange}
          errorMessage={errors?.name}
          isInvalid={!!errors?.name}
          disabled={disabled}
          /* Autofocus when a new memory is created */
          autoFocus={name === value}
        />
      </Col>
      <Col md="auto">
        <MemoryTypeField
          id={`memoryBankSlots[${memoryIndex}].type`}
          value={type as MemoryType}
          onChange={handleChange}
          disabled={disabled}
        />
      </Col>
      <Col md={2}>
        <MemoryValueField
          id={`memoryBankSlots[${memoryIndex}].value`}
          type={type}
          value={value}
          onChange={handleChange}
          characters={characters}
          errorMessage={errors?.value}
          isInvalid={!!errors?.value}
          disabled={disabled}
        />
      </Col>
      <Col md="auto">
        <MemoryShowInAlertField
          custom
          id={`memoryBankSlots[${memoryIndex}].showInAlert`}
          checked={showInAlert}
          onChange={handleChange}
          disabled={isShowInDisabled || disabled}
        />
      </Col>
      <Col md={2}>
        <MemoryDisplayNameField
          id={`memoryBankSlots[${memoryIndex}].displayName`}
          value={displayName}
          onChange={handleChange}
          disabled={isMemoryVisionDisabled || disabled}
          isInvalid={!!errors?.displayName && !isMemoryVisionDisabled}
          errorMessage={errors?.displayName}
        />
      </Col>
      <Col md={2}>
        <MemoryIconField
          id={`memoryBankSlots[${memoryIndex}].icon`}
          value={icon}
          onChange={handleChange}
          memoryIcons={memoryIcons}
          onSave={onIconAdd}
          disabled={isMemoryVisionDisabled || disabled}
          isInvalid={!!errors?.icon && !isMemoryVisionDisabled}
          errorMessage={errors?.icon}
        />
      </Col>
      <Col xl lg={2} md={11} sm={11}>
        <MemoryDefaultChangeDescriptionField
          id={`memoryBankSlots[${memoryIndex}].defaultChangeDescription`}
          value={defaultChangeDescription}
          onChange={handleChange}
          disabled={isMemoryVisionDisabled || disabled}
          isInvalid={!!errors?.defaultChangeDescription && !isMemoryVisionDisabled}
          errorMessage={errors?.defaultChangeDescription}
        />
      </Col>
      <Form.Group className="mt-auto">
        <Button
          onClick={handleDeleteClick}
          variant="secondary"
          className={classes.memoryBankRemoveButton}
          disabled={disabled}
        >
          X
        </Button>
      </Form.Group>
    </Row>
  );
}
