import React, { useEffect, useState } from 'react';
import { Offline } from 'react-detect-offline';
import { BrowserRouter } from 'react-router-dom';
// import of general styles should come before any
// app related code to keep CSS priority of general styles low
// eslint-disable-next-line import/order
import './style.scss';
import { baseURL } from './components/api';
import { auth } from './components/Auth/Auth';
// eslint-disable-next-line import/order
import { Routes } from './components/router';
import { ApiServiceProvider } from './contexts/ApiServiceContext/ApiServiceContext';
import { MemoryBankProvider } from './contexts/MemoryBankContext/MemoryBankServiceContext';
import './index.scss';
import { bugTracker, bugTrackerConfig } from './services/bugTracker/BugTrackerService';
import { VersionCheck } from './Version';

bugTracker().init(process.env.REACT_APP_RELEASE ?? '', bugTrackerConfig);

export function App() {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    auth.initialise().then(() => {
      setLoaded(true);
    }).catch(() => {
      setLoaded(true);
    });
  }, []);
  const polling = {
    enabled: false,
    interval: 5000,
    timeout: 7000,
    url: `${baseURL}v1/`,
  };

  return loaded ? (
    <BrowserRouter>
      <ApiServiceProvider>
        <VersionCheck />
        <MemoryBankProvider>
          <Routes auth={auth} />
        </MemoryBankProvider>
      </ApiServiceProvider>
      <Offline polling={polling}>
        <div className="appOfflineBox">
          <div className="appOfflineBoxContent">
            App Offline
          </div>
        </div>
      </Offline>
    </BrowserRouter>
  ) : <div>Loading</div>;
}
