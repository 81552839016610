import React, { Component } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import imgDelete from '../../../assets/images/delete.svg';
import { api } from '../../api';

export class Delete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      showLoading: false,
    };
  }

  deleteModal = () => {
    // eslint-disable-next-line react/destructuring-assignment
    if (this.state.showModal !== true) {
      return null;
    }
    return (
      <Modal
        className="deleteModal"
        show
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={this.onHide}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-center">
            Do you really want to delete this Image?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="reset"
            variant="secondary"
            onClick={this.onHide}
          >
            Cancel
          </Button>

          <Button
            type="submit"
            variant="primary"
            onClick={() => this.deleteAction()}
          >
            {/* eslint-disable-next-line react/destructuring-assignment */}
            {this.state.showLoading && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            )}
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  onHide = () => {
    this.setState({ showModal: false });
  };

  deleteAction() {
    this.setState({ showLoading: true });

    // eslint-disable-next-line react/destructuring-assignment
    api.delete(this.props?.type === 'character'
      // eslint-disable-next-line react/destructuring-assignment
      ? `/v1/customcharacters/${this.props.obj.id}`
      // eslint-disable-next-line react/destructuring-assignment
      : `/v1/backgrounds/${this.props.obj.id}`)
      .then(() => {
        this.setState({ showLoading: false });
        // eslint-disable-next-line react/destructuring-assignment
        if (this.props.update) {
          // eslint-disable-next-line react/destructuring-assignment
          this.props.update();
        }
        this.onHide();
      })
      .catch(() => {
        this.setState({ showLoading: false });
      });
  }

  render() {
    return (
      <>
        <Button
          onClick={() => {
            this.setState({
              showModal: true,
            });
          }}
          variant="secondary"
          size="sm"
          className="mx-1"
        >
          <img src={imgDelete} className="btnImg" alt="Delete" />
        </Button>
        {this.deleteModal()}
      </>
    );
  }
}
