import classNames from 'classnames/bind';
import React, { Component } from 'react';
import {
  Alert, Button, Col, Container, Modal, Row, Spinner,
} from 'react-bootstrap';
import ImgArr from '../../../assets/images/arr-002.svg';
import { api } from '../../api';
import { LocationsForm } from '../_shared/LocationsForm/LocationsForm';
import styles from '../Characters/Characters.scss';
import { LocationsTableRow } from './LocationsTableRow';

const cs = classNames.bind(styles);

export class Locations extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      locations: [],
      addLocation: false,
      loading: false,
      edit: false,
      editDisabled: false,
      deleteDisabled: false,
      wizardStep: null,
    };
  }

  componentDidMount() {
    const { user, book } = this.props;

    const wizardStep = JSON.parse(localStorage.getItem('wizardStep'));
    if (wizardStep === 9) {
      this.setState({
        wizardStep,
      }, () => {
        localStorage.setItem('wizardStep', wizardStep);
      });
    }

    if (book.book_role === 'brancheditor' && user.role !== 'admin') {
      this.setState({
        editDisabled: true,
        deleteDisabled: true,
      });
    }
    this.loadLocations();
  }

  loadLocations = () => {
    const { onHide, bookId } = this.props;
    const { wizardStep, locations } = this.state;

    this.setState({
      loading: true,
      edit: false,
    });
    api.get(`/v1/books/${bookId}/locations`)
      .then((res) => {
        this.setState({
          locations: res.data.locations,
          addLocation: false,
          loading: false,
        }, () => {
          if (wizardStep === 9 && locations.length >= 1) {
            localStorage.setItem('wizardStep', wizardStep + 1);
            onHide();
          }
        });
      });
  };

  locationsAlert() {
    const { loading, locations } = this.state;

    if (!(locations.length < 1 && loading === true)) {
      return null;
    }

    return (
      <Alert variant="info">
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        Location still don't exist. Please Add One.
      </Alert>
    );
  }

  onStopEdit = () => {
    this.setState({
      addLocation: false,
      edit: false,
    });
  };

  onStartEdit() {
    this.setState({
      edit: true,
    });
  }

  addLocationsForm() {
    const {
      loading, addLocation, locations,
    } = this.state;
    const {
      bookId, limits, disabled,
    } = this.props;

    if (addLocation === true) {
      return (
        <LocationsForm
          onSuccess={this.loadLocations}
          onStopEdit={this.onStopEdit}
          bookId={bookId}
          loading={loading}
          disabled={disabled}
          limits={limits}
          isFirstLocationInStory={locations.length === 0}
        />
      );
    }
    return null;
  }

  locationsTableRow() {
    const {
      disabledEdit, bookId, limits, disabled,
    } = this.props;
    const {
      loading, edit, locations, deleteDisabled, editDisabled,
    } = this.state;

    if (locations.length < 1) {
      // eslint-disable-next-line react/no-unescaped-entities
      return <Alert variant="info" className="my-3">Locations still don't exist. Please add location.</Alert>;
    }

    const args = {
      loading,
      edit,
      onStartEdit: this.onStartEdit.bind(this),
      onStopEdit: this.onStopEdit.bind(this),
      update: this.loadLocations.bind(this),
      bookId,
      editDisabled,
      deleteDisabled,
    };
    return locations.map((object, i) => (
      <LocationsTableRow
        // eslint-disable-next-line react/no-array-index-key
        key={i}
        obj={object}
        args={args}
        disabled={disabled || disabledEdit}
        limits={limits}
      />
    ));
  }

  render() {
    const {
      update, bookId, bookTitle, disabledEdit, disabled, ...other
    } = this.props;
    const {
      loading, edit, wizardStep, locations, slocations, addLocation,
    } = this.state;

    const modalTitle = bookTitle ? `: ${bookTitle}` : `# ${bookId}`;

    return (
      <Modal
        {...other}
        disabled={disabled}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Locations
            {modalTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.locationsAlert()}
          <div className={cs('wrapperTable')}>
            <Container>
              <Row className="py-2 border-bottom align-items-center">
                <Col md={1} className="h6 mb-0 text-nowrap text-center">Default</Col>
                <Col md={4} className="h6 mb-0 text-nowrap">Title</Col>
                <Col md={3} className="h6 mb-0 text-nowrap">Alias</Col>
                <Col md={2} className="h6 mb-0 text-nowrap">Image</Col>
                <Col md={2}>
                  <Button
                    disabled={
                        addLocation
                        || edit
                        || disabled
                        || disabledEdit
                      }
                    size="sm"
                    variant="secondary"
                    onClick={() => {
                      this.setState({
                        addLocation: true,
                        edit: true,
                      });
                    }}
                  >
                    + Add Location
                  </Button>
                </Col>
              </Row>
              {this.locationsTableRow()}
              {this.addLocationsForm()}
            </Container>
            <div
              className={cs(loading === false ? 'd-none' : 'd-block', 'wrapperTableLoader')}
            >
              <div
                className={cs('wrapperTableSpinner')}
              >
                <Spinner animation="border" variant="primary" />
              </div>
            </div>
            <div
              className={cs(edit === false ? 'd-none' : 'd-block', 'wrapperTableLoader')}
            />
            <span
              className={`wizardBtnBox ${wizardStep === 9 && locations.length < 1 ? 'wizardActive' : ''}`}
            >
              {(wizardStep === 9 && slocations.length < 1) && (
                <div className="sidebarArr">
                  <img src={ImgArr} alt="" />
                  <span>please create one Location</span>
                </div>
              )}
            </span>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
