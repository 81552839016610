// @ts-ignore: no def file
import { isEmpty } from 'lodash-es';
import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { CustomCharacterSelectProvider } from '../../../../contexts/CustomCharacterSelect/CustomCharacterSelectProvider';
import { api } from '../../../api';
import { ExpressionList } from '../ExpressionList/ExpressionList';
import classNames from './CharacterExpressions.module.scss';
import { defaultExpressionName, defaultExpressionValue, emptyKey } from './constants';

/**
 * Allows to change character expressions
 * @param value - HashMap. Character avatar expressions
 * @param onChange(newValue) - Callback with new value
 * @returns {JSX.Element}
 */
export function CharacterExpressions({
  value,
  onChange,
}) {
  const [possibleExpressions, setPossibleExpressions] = useState([]);

  const expressionValuesByName = isEmpty(value)
    ? { [defaultExpressionName]: defaultExpressionValue }
    : value;

  useEffect(() => {
    api.get('v1/characters/expressions').then(
      (response) => {
        const loadedExpressions = response.data.expressions.map((expression) => (
          {
            id: expression.id,
            value: expression.value,
            title: expression.title,
          }
        ));
        // For new expression
        const emptyExpression = {
          id: 0,
          value: emptyKey,
          title: ' ',
        };
        setPossibleExpressions([emptyExpression, ...loadedExpressions]);
      },
    );
  }, []);

  const handleAddExpression = () => {
    const newExpression = {
      ...expressionValuesByName,
      [emptyKey]: defaultExpressionValue,
    };
    onChange(newExpression);
  };

  const handleExpressionListChange = (newValue) => {
    onChange(newValue);
  };

  return (
    <CustomCharacterSelectProvider>
      <div className={classNames.container}>
        <Row>
          <Col>
            <ExpressionList
              value={expressionValuesByName}
              possibleExpressions={possibleExpressions}
              onChange={handleExpressionListChange}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} className={classNames.addExpressionButton}>
            <Button
              className={classNames.button}
              variant="primary"
              onClick={handleAddExpression}
            >
              Add expression
            </Button>
          </Col>
        </Row>
      </div>
    </CustomCharacterSelectProvider>
  );
}
