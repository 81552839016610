import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import {
  CheckOperator,
  MemoryArrayLink,
  MemoryDTO,
  MemoryLinkValue,
  MemoryType,
  MemoryValue,
} from 'components/pages/Book/MemoryBank/memoryBankTypes';
import { getMemorySlotVariableLink } from '../../../../../utils/choiceMemoryUtils';
import { MemoryMultiSelectForm } from '../MemoryMultiSelectForm/MemoryMultiSelectForm';

export type MemoryBankCheckValue = {
  name: string,
  value: MemoryValue | MemoryLinkValue | null,
}

interface MemorySlotMultiCompareFormProps {
  inputNamePrefix: string,

  operatorInputValue: string,
  checkInputValue: MemoryValue | MemoryLinkValue,
  memoryBankSlots?: MemoryDTO[],
  stepIndex: number,
  onChangeValue: (
    values: MemoryBankCheckValue[],
    stepIndex: number,
  ) => void,
  isDisabled?: boolean,
}

export function MultiMemoryCheckForm(props: MemorySlotMultiCompareFormProps) {
  const {
    inputNamePrefix,
    operatorInputValue,
    checkInputValue,
    memoryBankSlots,
    stepIndex,
    onChangeValue,
    isDisabled,
  } = props;

  const memories = memoryBankSlots?.filter(
    (memory) => memory.type === MemoryType.Number,
  );

  if (!memories) {
    return null;
  }

  return (
    <Row xs={1} sm={2} lg={3}>
      <Form.Group as={Row}>
        <Col className="mx-2">
          <Form.Control
            disabled={isDisabled}
            size="sm"
            as="select"
            name={`${inputNamePrefix}[operator]`}
            value={operatorInputValue}
            onChange={(event) => onChangeValue([{
              name: 'operator',
              value: event.target.value,
            }], stepIndex)}
          >
            {Object.entries(CheckOperator).map(([operatorKey, operatorValue]) => {
              if (!operatorValue.isMultiSelect) {
                return null;
              }
              return (
                <option key={`option${operatorKey}`} value={operatorKey}>
                  {operatorValue.title}
                </option>
              );
            })}
          </Form.Control>
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Col md="auto" className="mx-1">
          <Form.Label column>
            of
          </Form.Label>
        </Col>
        <Col xl={6} lg={8} md={7}>
          <MemoryMultiSelectForm
            memories={memories}
            stepIndex={stepIndex}
            value={getMemorySlotVariableLink(checkInputValue) as MemoryArrayLink}
            onChangeValue={onChangeValue}
            name={`${inputNamePrefix}[value]`}
          />
        </Col>
      </Form.Group>
    </Row>
  );
}
