import React, { PureComponent } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { AddLocationModal } from '../AddLocationModal/AddLocationModal';
import { ScenePreviewImg } from '../ScenePreviewImg';

export class AddBranchHead extends PureComponent {
  constructor(...args) {
    super(...args);
    const { locationId, description, title } = this.props;
    this.state = {
      locationId,
      defaultValueTitle: title || '',
      defaultValueDescription: description || '',
      oldTitleText: '',
      oldDescriptionText: '',
      titleTextPos: null,
      descriptionTextPos: null,
      isAddLocationModalShown: false,
    };
    this.titleRef = React.createRef();
    this.descriptionRef = React.createRef();
  }

  locationOptions() {
    const { locations } = this.props;

    if (locations.length > 0) {
      const def = locations.find((obj) => obj.isDefault === true);
      const res = [
        <option
          value=""
          className="def_location_option"
          key="def"
        >
          {def ? `Default (${def.title})` : 'Select Location'}
        </option>,
      ];
      // eslint-disable-next-line max-len,react/no-array-index-key
      res.push(...locations.map((object, i) => <option value={object.id} key={i}>{object.title}</option>));
      res.push(<option value="add" key="add">+ ADD LOCATION</option>);
      return res;
    }
    const res = [
      <option
        value=""
        disabled
        className="def_location_option"
        key="def"
      >
        Select
      </option>,
    ];

    res.push(<option value="add" key="add">+ ADD LOCATION</option>);
    return res;
  }

  stringDiffIndex = (oldText, newText) => {
    let s = 0;
    while (s < oldText.length && s < newText.length && oldText[s] === newText[s]) {
      s += 1;
    }
    return s;
  };

  componentDidUpdate(prevProps, prevState) {
    const {
      editHeader, update, locationId: locationIdFromProps, description, title,
    } = this.props;
    const {
      descriptionTextPos, titleTextPos, locationId, defaultValueDescription, defaultValueTitle,
    } = this.state;

    if (titleTextPos) {
      this.titleRef.current.setSelectionRange(titleTextPos, titleTextPos);
      this.setState({
        titleTextPos: null,
      });
    }
    if (descriptionTextPos) {
      this.descriptionRef.current.setSelectionRange(descriptionTextPos, descriptionTextPos);
      this.setState({
        descriptionTextPos: null,
      });
    }

    if (prevProps.locationId !== locationIdFromProps) {
      this.setState({ locationId: locationIdFromProps });
    }
    if (prevProps.title !== title) {
      this.setState({ defaultValueTitle: title });
    }
    if (prevProps.description !== description) {
      this.setState({ defaultValueDescription: description });
    }

    if (prevState.locationId !== locationId
      || prevState.defaultValueTitle !== defaultValueTitle
      || prevState.defaultValueDescription !== defaultValueDescription) {
      const edit = locationIdFromProps !== locationId
        || title !== defaultValueTitle
        || description !== defaultValueDescription;
      if (edit !== editHeader) {
        update(edit);
      }
    }
  }

  render() {
    const {
      updateLocation,
      disabled,
      limits,
      activeStepsFunc,
      restrictedEdit,
      locations,
      title,
      story,
    } = this.props;
    const {
      oldTitleText,
      locationId,
      defaultValueTitle,
      oldDescriptionText,
      defaultValueDescription: defaultDescription,
      isAddLocationModalShown,
    } = this.state;

    const branchTitleMax = Number(limits.branch_title_max.value);
    const branchDescriptionMax = Number(limits.branch_description_max.value);
    return (
      <>
        <div className="sf-header">
          <Form.Group controlId="BranchTitle" className="mb-1">
            <Row>
              <Form.Label column md={2} style={{ fontSize: '14px' }}>Node Title</Form.Label>
              <Col md={9}>
                <Form.Control
                  size="sm"
                  required
                  type="text"
                  placeholder="Node Title"
                  value={defaultValueTitle}
                  pattern="^([A-Za-z]|[0-9]|_|-)+$"
                  name="title"
                  disabled={title === 'intro' || restrictedEdit}
                  ref={this.titleRef}
                  onKeyDown={(ev) => {
                    if (ev.key === '-') {
                      const text = ev.target.value;
                      this.setState({
                        oldTitleText: text,
                      });
                    }
                  }}
                  onKeyUp={(ev) => {
                    if (ev.key === '-') {
                      let text = ev.target.value;
                      const idx = this.stringDiffIndex(oldTitleText || '', text || '');
                      if (text[idx - 1] === '-') {
                        text = `${text.substring(0, idx - 1)}—${text.substring(idx + 1, text.length)}`;
                        this.setState({
                          defaultValueTitle: text,
                          titleTextPos: idx,
                        });
                      }
                    }
                  }}
                  onFocus={() => activeStepsFunc('Node-Title')}
                  onChange={(e) => this.setState({
                    defaultValueTitle: e.target.value,
                  })}
                  className={defaultValueTitle && defaultValueTitle.length > branchTitleMax ? 'text-limit' : null}
                />
                <Form.Text className="char-limit-info-box">
                  {branchTitleMax - (defaultValueTitle ? defaultValueTitle.length : 0)}
                </Form.Text>
              </Col>

              <Col md={1}>
                <ScenePreviewImg
                  scenes={locations}
                  id={locationId}
                />
              </Col>
            </Row>
          </Form.Group>

          <Form.Row className="mb-0">
            <Form.Group as={Col} md="12" className="mb-0">
              <Row>
                <Form.Label column md={2} style={{ fontSize: '14px' }}>Node Description</Form.Label>
                <Col md={6}>
                  <Form.Control
                    disabled={restrictedEdit}
                    size="sm"
                    as="textarea"
                    rows="1"
                    placeholder="Node Description"
                    value={defaultDescription}
                    ref={this.descriptionRef}
                    onKeyDown={(ev) => {
                      if (ev.key === '-') {
                        const text = ev.target.value;
                        this.setState({
                          oldDescriptionText: text,
                        });
                      }
                    }}
                    onKeyUp={(ev) => {
                      if (ev.key === '-') {
                        let text = ev.target.value;
                        const idx = this.stringDiffIndex(oldDescriptionText || '', text || '');
                        if (text[idx - 1] === '-') {
                          text = `${text.substring(0, idx - 1)}—${text.substring(idx + 1, text.length)}`;
                          this.setState({
                            defaultValueDescription: text,
                            descriptionTextPos: idx,
                          });
                        }
                      }
                    }}
                    onFocus={() => {
                      activeStepsFunc('Node-Description');
                    }}
                    onChange={(e) => {
                      this.setState({
                        defaultValueDescription: e.target.value,
                      });
                    }}
                    className={defaultDescription && defaultDescription.length > branchDescriptionMax ? 'text-limit' : null}
                    name="description"
                  />
                  <Form.Text className="char-limit-info-box">
                    {branchDescriptionMax - (defaultDescription ? defaultDescription.length : 0)}
                  </Form.Text>
                </Col>

                <Form.Label
                  column
                  md={1}
                  style={{ fontSize: '14px' }}
                >
                  Location
                </Form.Label>
                <Col md={2}>
                  <Form.Control
                    size="sm"
                    as="select"
                    placeholder="Location"
                    disabled={disabled || restrictedEdit}
                    value={locationId}
                    onChange={(e) => {
                      if (e.target.value === 'add') {
                        this.setState({
                          isAddLocationModalShown: true,
                        });
                      } else {
                        this.setState({
                          locationId: e.target.value,
                        });
                      }
                    }}
                    name="locationId"
                  >
                    {this.locationOptions()}
                  </Form.Control>
                </Col>
              </Row>
            </Form.Group>
          </Form.Row>
        </div>
        {isAddLocationModalShown && (
          <AddLocationModal
            story={story}
            show={isAddLocationModalShown}
            limits={limits}
            onHide={() => {
              this.setState({
                isAddLocationModalShown: false,
              });
            }}
            onSuccess={(newLocation) => {
              updateLocation();
              this.setState({
                locationId: newLocation.id,
                isAddLocationModalShown: false,
              });
            }}
          />
        )}
      </>
    );
  }
}
