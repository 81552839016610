import classNames from 'classnames/bind';
import React, { useEffect, useState } from 'react';
import {
  Alert, Button, Col, Container, Form, Row, Spinner,
} from 'react-bootstrap';
import RangeSlider from 'react-bootstrap-range-slider';
import { useDebounce } from 'use-debounce';
import { api } from '../../../api';
import { Auth } from '../../../Auth/Auth';
import { Pagination } from '../../../ui/Pagination/Pagination';
import { useAudioPlayer } from '../../AudioLibraryPage/useAudioPlayer';
import { useBackgrounds } from '../../Backgrounds/useBackgrounds';
import styles from '../../Characters/Characters.scss';
import { AudioPicker } from '../../Locations/AudioPicker/AudioPicker';
import classes from './LocationsForm.module.scss';
import { LocationsList } from './LocationsList/LocationsList';
import { Search } from './LocationsList/Search';

const cs = classNames.bind(styles);

const pageSize = 15;

export function LocationsForm({
  onSuccess,
  bookId,
  onStopEdit,
  loading,
  storyLocationToEdit,
  limits,
  disabled,
  isFirstLocationInStory,
}) {
  const storyLocationToEditId = storyLocationToEdit?.id ?? null;
  const isNew = !storyLocationToEditId;

  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm] = useDebounce(searchTerm, 1000);

  const auth = new Auth();
  const user = auth.getUser();
  const userRole = user ? user.role : 'unknown';

  const {
    currentPage,
    setCurrentPage,
    totalCount,
    backgrounds,
    isLoading,
  } = useBackgrounds(debouncedSearchTerm, pageSize);

  const handleSearchChange = (newSearchTerm) => {
    setCurrentPage(1);
    setSearchTerm(newSearchTerm);
  };

  const [isDefaultLocation, setIsDefaultLocation] = useState(isFirstLocationInStory || false);
  const [title, setTitle] = useState('');
  const [formError, setFormError] = useState(null);
  const [selectedImageId, setSelectedImageId] = useState(null);
  const [selectedAudio, setSelectedAudio] = useState(null);
  const [validated, setValidated] = useState(false);

  const {
    audioVolume,
    setAudioVolume,
    isLoopAudio,
    setIsLoopAudio,
    isPlayingAudio,
    togglePlayAudio,
  } = useAudioPlayer(selectedAudio?.audioUrl, storyLocationToEdit?.audioSettings);

  useEffect(() => {
    if (storyLocationToEdit) {
      setSelectedImageId(storyLocationToEdit.backgroundId);
      setTitle(storyLocationToEdit.title);
      setIsDefaultLocation(storyLocationToEdit.isDefault);
      if (storyLocationToEdit.audioId) {
        api.get(`/v1/audio/${storyLocationToEdit.audioId}`).then((response) => {
          setSelectedAudio(response.data.audio);
        });
      }
    }
  }, [setAudioVolume, setIsLoopAudio, storyLocationToEdit]);

  function errorAlert(error) {
    setFormError(error);
    setTimeout(() => {
      setFormError(null);
    }, 3000);
  }

  const handleImageClick = (image) => {
    setSelectedImageId(image.id);
    if (isNew) {
      setTitle(image?.label ?? '');
    }
  };

  function handleSubmit(event) {
    event.preventDefault();
    // we need this in order to prevent parent from submitting and closing modal
    // TODO: remove nesting of Form components
    event.stopPropagation();

    const form = event.currentTarget;

    if (!selectedImageId) {
      errorAlert('Please select location from the list');
      return;
    }

    if (form.checkValidity() !== false) {
      const locationUpdate = {
        title,
        backgroundId: selectedImageId,
        isDefault: isDefaultLocation,
        audioId: selectedAudio?.id ?? null,
        audioSettings: {
          volume: audioVolume,
          loop: isLoopAudio,
        },
      };

      if (storyLocationToEditId || storyLocationToEditId === 0) {
        api.put(`/v1/books/${bookId}/locations/${storyLocationToEditId}`, locationUpdate)
          .then((response) => onSuccess(response.data.location))
          .catch((error) => {
            if (error) {
              errorAlert(error.response.data.error);
            }
          });
      } else {
        api.post(`/v1/books/${bookId}/locations`, locationUpdate)
          .then((response) => onSuccess(response.data.location))
          .catch((error) => {
            if (error) {
              errorAlert(error.response.data.error);
            }
          });
      }
    }

    setValidated(true);
  }

  const handleAudioPickerConfirm = (value) => setSelectedAudio(value);

  const createRangeTooltip = (value) => `${value * 100}%`;

  const handleRangeChanged = (event) => setAudioVolume(event.target.value);

  const isTitleTooLong = title && title.length > Number(limits.scene_title_max.value);

  return (
    <Container className={cs(loading === false ? 'formEdit' : null, 'py-2 border-bottom')}>
      <Row>
        <Col md={12} className="text-center mb-3">
          Story location:
        </Col>
      </Row>
      <Form
        noValidate
        validated={validated}
        onSubmit={(e) => handleSubmit(e)}
      >
        <Row>
          <Col md={12} className={cs(formError === null ? 'd-none' : 'd-block')}>
            <Alert variant="danger">
              {formError}
            </Alert>
          </Col>
        </Row>
        <Row className="mb-1">
          <Col md={12}>
            <Form.Check
              custom
              disabled={isFirstLocationInStory}
              id="isDefault"
              name="isDefault"
              checked={isDefaultLocation}
              onChange={(event) => setIsDefaultLocation(event.target.checked)}
              label="Is this location default?"
            />
          </Col>
        </Row>
        <Form.Group controlId="Title">
          <Row>
            <Col md={2}>
              <Form.Label>
                Title:
              </Form.Label>
            </Col>
            <Col md={10}>
              <Form.Control
                required
                size="sm"
                type="text"
                placeholder="Title"
                name="Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className={isTitleTooLong ? 'text-limit' : null}
              />
              <Form.Text className="char-limit-info-box" style={{ top: 0, bottom: 'auto' }}>
                {Number(limits.scene_title_max.value) - (title ? title.length : 0)}
              </Form.Text>
              <Form.Control.Feedback
                type="invalid"
                className={isTitleTooLong ? 'd-block' : 'd-none'}
              >
                Title is too long
              </Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Please enter Location title
              </Form.Control.Feedback>
            </Col>
          </Row>
        </Form.Group>
        {
          userRole === 'admin' && (
            <>
              <Row className={classes.audioRow}>
                <Col md={2}>
                  <Form.Label>
                    Audio:
                  </Form.Label>
                </Col>
                <Col lg={5} md={7}>
                  <div className={classes.audioField}>
                    {selectedAudio?.title || '-'}
                  </div>
                </Col>
                <Col md="auto">
                  <AudioPicker
                    className={classes.audioPickerButton}
                    title="Select audio"
                    initialValue={selectedAudio}
                    onConfirm={handleAudioPickerConfirm}
                  />

                  {selectedAudio && (
                  <Button
                    size="sm"
                    variant="outline-danger"
                    className={classes.removeButton}
                    onClick={() => setSelectedAudio(null)}
                  >
                    Remove
                  </Button>
                  )}
                </Col>
              </Row>
              <Row>
                <Col md={2}>
                  <Form.Label>
                    Audio settings:
                  </Form.Label>
                </Col>
                <Col md={10} className={classes.audioSettings}>
                  <div className={classes.loopField}>
                    <Form.Check
                      custom
                      disabled={!selectedAudio}
                      checked={isLoopAudio}
                      id="isLoopAudio"
                      onChange={(event) => setIsLoopAudio(event.target.checked)}
                      label="Loop"
                    />
                  </div>
                  <div className={classes.volumeField}>
                    <RangeSlider
                      disabled={!selectedAudio}
                      value={audioVolume}
                      min={0}
                      max={1}
                      step={0.1}
                      size="sm"
                      tooltipLabel={createRangeTooltip}
                      onChange={handleRangeChanged}
                    />
                    <Form.Label className={classes.volumeLabel}>
                      Volume
                    </Form.Label>
                  </div>
                  <Button
                    disabled={!selectedAudio}
                    className={classes.playButton}
                    variant="outline-primary"
                    size="sm"
                    onClick={togglePlayAudio}
                  >
                    {isPlayingAudio
                      ? 'Stop'
                      : ' Listen'}
                  </Button>
                </Col>
              </Row>
            </>
          )
        }
        <Row>
          <Col md={12} className="text-right mb-3">
            <Button
              className="mr-1"
              size="sm"
              type="reset"
              variant="secondary"
              onClick={() => {
                onStopEdit();
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={disabled || isTitleTooLong}
              className="ml-1"
              size="sm"
              type="submit"
              variant="primary"
            >
              Save
            </Button>
          </Col>
        </Row>
      </Form>
      <Row>
        <Col md={12} className="text-center mb-1">
          Available locations:
        </Col>
      </Row>
      <Row>
        <Search value={searchTerm} onChange={handleSearchChange} />
      </Row>
      <Row className={cs(classes.list, 'mb-2')}>
        {isLoading ? (
          <Spinner
            variant="primary"
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        ) : (
          <LocationsList
            backgrounds={backgrounds}
            selectedImageId={selectedImageId}
            onImageClick={handleImageClick}
          />
        )}
      </Row>
      <Row>
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={totalCount}
          pageSize={pageSize}
          onPageChange={setCurrentPage}
        />
      </Row>
    </Container>
  );
}
