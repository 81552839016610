import React from 'react';
import Form from 'react-bootstrap/esm/Form';
import classes from './MemoryField.module.scss';

export function MemoryShowInAlertField(props: React.ComponentProps<typeof Form.Check>) {
  const {
    id,
    ...rest
  } = props;

  return (
    <Form.Group className="">
      <Form.Label
        htmlFor={id}
        className={classes.memoryLabel}
      >
        Show
      </Form.Label>
      <Form.Check
        id={id}
        {...rest}
      />
    </Form.Group>
  );
}
