import {StoryBranchesWithContext} from "../../contexts/StoryBranchesWithContext/StoryBranchesWithContext";
import {FeaturedStories} from '../FeaturedStories';
import {AdminSupportPanel} from '../pages/AdminSupportPanel/AdminSupportPanel';
import {AnswerModifiersTypes} from '../pages/AnswerModifiersTypes';
import {AnswerTypes} from '../pages/AnswerTypes';
import {Article} from '../pages/Article';
import {Articles} from '../pages/Articles';
import {AudioLibraryPage} from '../pages/AudioLibraryPage/AudioLibraryPage';
import {AvatarAssets} from '../pages/AvatarAssets';
import {BackgroundsPage} from '../pages/Backgrounds';
import {BackgroundTags} from '../pages/BackgroundTags';
import {Book} from '../pages/Book';
import {BookActivity} from '../pages/BookActivity';
import {BookCreateLink} from '../pages/BookCreateLink';
import {BookPerformance} from '../pages/BookPerformance';
import {DailyReads} from '../pages/BookPerformance/DailyReads';
import {EpisodeDailyReads} from '../pages/BookPerformance/EpisodeDailyReads';
import {BookPrompts} from '../pages/BookPrompts';
import {Books} from '../pages/Books';
import {CharacterArtLibrary} from '../pages/CharacterArtLibrary';
import {ClientVariablesPage} from '../pages/ClientVariablesPage/ClientVariablesPage';
import {Documents} from '../pages/Documents';
import {EventsRouter} from '../pages/Events/EventsRouter';
import {Expressions} from '../pages/Expressions';
import {FanSignups} from '../pages/FanSignups';
import {ApprovedFan} from '../pages/FanSignups/ApprovedFan';
import {Genre} from '../pages/Genre';
import {GlobalInvitationStatus} from '../pages/GlobalInvitationStatus';
import {PrivacyPolicyPage} from '../pages/Home/PrivacyPolicy';
import {CopyrightPolicyPage} from '../pages/Home/PrivacyPolicy/CopyrightPolicyPage';
import {TermsOfUsePage} from '../pages/Home/PrivacyPolicy/TermsOfUsePage';
import {Logout} from '../pages/Logout';
import {MailTemplates} from '../pages/MailTemplates';
import {Maintenance} from '../pages/Maintenance';
import {News} from '../pages/News';
import {PageNotFound} from '../pages/PageNotFound';
import {Payout} from '../pages/Payout';
import {PlayPreview} from '../pages/PlayPreview';
import {Profile} from '../pages/Profile';
import {RecentlyUploaded} from '../pages/RecentlyUploaded';
import {Search} from '../pages/Search';
import {SettingsPreferences} from '../pages/SettingsPreferences';
import {SettingsTextLimits} from '../pages/SettingsTextLimits';
import {SignUpCodeRequests} from '../pages/SignUpCodeRequests';
import {SignUpCodes} from '../pages/SignUpCodes';
import {StatTypes} from '../pages/StatTypes';
import {StoryBranches} from '../pages/StoryBranches';
import {ChapterRequirements} from '../pages/StoryBranches/Options/ChapterRequirements';
import {StoryCreation} from '../pages/StoryCreation';
import {StoryCreationDate} from '../pages/StoryCreationDate';
import {StoryTags} from '../pages/StoryTags';
import {TagTypes} from '../pages/TagTypes';
import {Users} from '../pages/Users';
import {UsersNotApproved} from '../pages/UsersNotApproved';
import {AvatarTool} from '../ui/AvatarTool';
import {StoryRedirect} from './StoryRedirect';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.$traits = process.env.REACT_APP_TRAITS && process.env.REACT_APP_TRAITS === 'true';

export const routesLoggedAdmin = [
  {
    path: '/',
    exact: true,
    component: News,
  },
  {
    path: '/search',
    component: Search,
  },
  {
    path: '/home',
    component: News,
  },
  {
    path: '/mystories',
    component: News,
  },
  {
    path: '/create/template/:id',
    component: BookCreateLink,
  },
  {
    path: '/books',
    component: Books,
  },
  {
    path: '/activity',
    component: BookActivity,
  },
  {
    path: '/performance/dailyreads/:book_id',
    component: DailyReads,
  },
  {
    path: '/performance/episodedailyreads/:story_id',
    component: EpisodeDailyReads,
  },
  {
    path: '/performance',
    component: BookPerformance,
  },
  {
    path: '/creation/:date',
    component: StoryCreationDate,
  },
  {
    path: '/creation',
    component: StoryCreation,
  },
  {
    path: '/articles/page/:id',
    component: Articles,
  },
  {
    path: '/articles',
    component: Articles,
  },

  {
    path: '/mail-templates',
    component: MailTemplates,
  },
  {
    path: '/article/:id',
    component: Article,
  },
  {
    path: '/verify',
    search: '?token=token',
    component: Books,
  },
  {
    path: '/book/:id',
    component: Book,
  },
  {
    path: '/stories/:id/branches',
    component: StoryBranchesWithContext(StoryBranches),
  },
  {
    path: '/stories/:id',
    component: StoryRedirect,
  },
  {
    path: '/users',
    component: Users,
  },
  {
    path: '/usersnotapproved',
    component: UsersNotApproved,
  },
  {
    path: '/stats',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    component: !window.$traits ? StatTypes : PageNotFound,
  },
  {
    path: '/tags',
    component: TagTypes,
  },
  {
    path: '/modifiers',
    component: AnswerModifiersTypes,
  },
  {
    path: '/answertypes',
    component: AnswerTypes,
  },
  {
    path: '/prompts',
    component: BookPrompts,
  },
  {
    path: '/genres',
    component: Genre,
  },
  {
    path: '/expressions',
    component: Expressions,
  },
  {
    path: '/textlimits',
    component: SettingsTextLimits,
  },
  {
    path: '/preferences',
    component: SettingsPreferences,
  },
  {
    path: '/chapter-requirements',
    component: ChapterRequirements,
  },
  {
    path: '/storytags',
    component: StoryTags,
  },
  {
    path: '/backgroundtags',
    component: BackgroundTags,
  },
  {
    path: '/signupcodes',
    component: SignUpCodes,
  },
  {
    path: '/signupcodesrequests',
    component: SignUpCodeRequests,
  },
  {
    path: '/backgrounds',
    component: BackgroundsPage,
  },
  {
    path: '/character-art-library',
    component: CharacterArtLibrary,
  },
  {
    path: '/audio-library',
    component: AudioLibraryPage,
  },
  {
    path: '/maintenance',
    component: Maintenance,
  },
  {
    path: '/avatar-tool',
    component: AvatarTool,
  },
  {
    path: '/profile',
    component: Profile,
  },
  {
    path: '/play/:uuid',
    component: PlayPreview,
  },
  {
    path: '/fan',
    component: FanSignups,
  },
  /*
      {
        path: '/beta-signup-limits',
        component: BetaSignupLimits,
      },
       */
  {
    path: '/approvedfan',
    component: ApprovedFan,
  },
  {
    path: '/avatarassets',
    component: AvatarAssets,
  },
  {
    path: '/global-invitation-status',
    component: GlobalInvitationStatus,
  },
  {
    path: '/featured-stories',
    component: FeaturedStories,
  },
  {
    path: '/documents/:id',
    component: Documents,
  },
  {
    path: '/documents/',
    component: Documents,
  },
  {
    path: '/payout',
    component: Payout,
  },
  {
    path: '/privacy-policy',
    component: PrivacyPolicyPage,
  },
  {
    path: '/terms-of-use',
    component: TermsOfUsePage,
  },
  {
    path: '/copyright-policy',
    component: CopyrightPolicyPage,
  },
  {
    path: '/logout',
    component: Logout,
  },
  {
    path: '/recently-uploaded',
    component: RecentlyUploaded,
  },
  {
    path: '/client-variables',
    component: ClientVariablesPage,
  },
  {
    path: '/events',
    component: EventsRouter,
  },
  {
    path: '/admin-support-panel',
    component: AdminSupportPanel,
  },
  {
    path: '**',
    component: PageNotFound,
  },
];
