import React, { Component } from 'react';
import {
  Button, Card, Col, Form, FormControl, ListGroup, Row, Spinner,
} from 'react-bootstrap';
import { api } from '../../../api';
import { Pagination } from '../../../ui/Pagination/Pagination';
import { Delete } from '../Delete';
import { EditAlias } from '../EditAlias';
import { UploadImage } from '../UploadImage';
import './BackgroundsList.scss';

const debounceEvent = (callback, time) => {
  let interval;
  return (...args) => {
    clearTimeout(interval);
    interval = setTimeout(() => {
      interval = null;
      callback(...args);
    }, time);
  };
};

export class BackgroundsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
      selected: null,
      loading: true,
      uploadImage: false,
      currentPage: 1,
      totalCount: 0,
      pageSize: 10,
      searchString: '',
    };

    this.user = props.user;
  }

  componentDidMount() {
    this.loadImages();
  }

  componentDidUpdate(prevProps, prevState) {
    const { user } = this.props;
    const { currentPage, searchString, pageSize } = this.state;

    if ((prevState.currentPage !== currentPage)
        || (prevState.searchString !== searchString)
        || (prevProps.user !== user)
        || (prevState.pageSize !== pageSize)
    ) {
      this.loadImages();
    }
  }

  loadImages = () => {
    const { currentPage, pageSize, searchString } = this.state;

    this.setState({
      loading: true,
    });

    const params = {
      all: true, // show also disabled backgrounds in this list so admin can enable/disable them
      limit: pageSize,
      offset: pageSize * (currentPage - 1),
      search: searchString || undefined,
      order: 'createdAt:desc',
      authorId: this.user.role !== 'admin' ? this.user.id : undefined,
    };

    api.get('/v1/backgrounds', { params })
      .then((res) => {
        this.setState({
          loading: false,
          images: res.data.images,
          totalCount: res.data.totalCount,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  };

  cancelUpload = () => {
    this.setState({ uploadImage: false });
  };

  updateUpload = () => {
    this.setState({ uploadImage: false });
    this.loadImages();
  };

  itemOnClick(selectedItem) {
    this.setState({ selected: selectedItem });
  }

  editUpload() {
    this.setState({ uploadImage: true });
  }

  renderLoadingSpinner() {
    const { loading } = this.state;

    if (!loading) return null;

    return (
      <>
        <div className="text-center">
          <Spinner
            variant="primary"
            animation="border"
            className="loadingSpinner justify-content-center"
          />
        </div>
        <div className="overlay" />
      </>
    );
  }

  renderImageItems() {
    const { images, selected } = this.state;

    return images.map((image) => (
      <ListGroup.Item
        as="div"
        key={image.id}
        action
        active={selected && selected.id === image.id}
        onClick={() => this.itemOnClick(image)}
      >
        <Row className="align-items-center">
          <Col sm={2} className="pr-0">
            <img
              style={{
                height: 50,
                width: 50,
                objectFit: 'cover',
              }}
              src={image.imageUrl}
              alt={image.alias}
            />
          </Col>
          { this.user.role === 'admin' && (
            <Col>
              {image.alias}
            </Col>
          )}
          <Col>
            {image.label}
          </Col>
          <Col>
            {image.tags ? image.tags.map((el) => `${el} `) : ''}
          </Col>
          <Col sm={1}>
            {image.disabled}
            <div className={`${image.disabled === true ? 'checked' : ''} circleBox`} />
          </Col>
          <Col sm={2} className="text-right px-0">
            <EditAlias
              obj={selected}
              update={this.loadImages}
              type="Backgrounds"
              user={this.user}
            />
            <Delete
              obj={selected}
              update={this.loadImages}
            />
          </Col>
        </Row>
      </ListGroup.Item>
    ));
  }

  renderPagination() {
    const { totalCount, currentPage, pageSize } = this.state;

    return (
      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={totalCount}
        pageSize={pageSize}
        onPageChange={(page) => {
          this.setState({ currentPage: page, selected: null });
        }}
      />
    );
  }

  renderImagePreview() {
    const { selected } = this.state;

    if (!selected) return null;
    return (
      <div className="stickyImgBox">
        <h5>
          {selected.label}
        </h5>
        <Row float="center">
          <Col>
            <img
              style={{ maxHeight: 500, maxWidth: '100%' }}
              src={selected.imageUrl}
              alt="Preview"
            />
          </Col>
        </Row>
        <Row float="center">
          <Col style={{ padding: '10px' }}>
            <Button
              onClick={() => this.editUpload()}
              variant="secondary"
              className="mx-1"
              hidden={this.user.role !== 'admin'}
            >
              Replace image
            </Button>
          </Col>
        </Row>
      </div>
    );
  }

  renderSearchBar() {
    const setStateWithDebounce = debounceEvent((searchValue) => this.setState(
      {
        searchString: searchValue,
        currentPage: 1,
      },
    ), 1000);

    const handleSearchBar = (event) => {
      event.preventDefault();
      this.setState({ currentPage: 1 });
    };

    const onChangeSearchString = (event) => {
      const searchValue = event.target.value;
      setStateWithDebounce(searchValue);
    };

    return (
      <Form onSubmit={handleSearchBar} className="search-form">
        <FormControl
          className="search-form_input"
          size="sm"
          type="search"
          placeholder="Type search string"
          aria-label="Search"
          onChange={onChangeSearchString}
        />
        <Button
          type="submit"
          size="sm"
          variant="secondary"
          onClick={handleSearchBar}
        >
          Search
        </Button>
      </Form>
    );
  }

  render() {
    const { uploadImage, selected } = this.state;

    return (
      <Card className="d-block">
        <Card.Body>
          <Card.Title className="title">
            Backgrounds list
          </Card.Title>
          {this.renderLoadingSpinner()}
          <Row>
            <Col sm={6}>
              {this.renderSearchBar()}
              {this.renderPagination()}
              <ListGroup>
                {this.renderImageItems()}
              </ListGroup>
              {this.renderPagination()}
            </Col>
            <Col sm={6} className="text-center">
              {this.renderImagePreview()}
            </Col>
          </Row>
        </Card.Body>
        <UploadImage
          show={uploadImage}
          onHide={this.cancelUpload}
          update={this.updateUpload}
          obj={selected}
          user={this.user}
          type="background"
        />
      </Card>
    );
  }
}
