import React, {
  ReactNode, createContext, useContext, useReducer,
} from 'react';
import { Branch } from '../../dorian-shared/types/branch/Branch';

interface IEpisodeState {
  nodes: Branch[];
  isLoading: boolean;
}

const enum EpisodeActionType {
  SaveNodePosition = 'SAVE_NODE_POSITION',
}

interface SaveNodePositionAction {
  type: EpisodeActionType.SaveNodePosition;
  payload: Branch;
}

type EpisodeAction = SaveNodePositionAction;

const episodeReducer = (state: IEpisodeState, action: EpisodeAction) => {
  switch (action.type) {
    case EpisodeActionType.SaveNodePosition:
      return {
        ...state,
        nodes: state.nodes.map((node) => {
          if (node.id === action.payload.id) {
            return action.payload;
          }
          return node;
        }),
      };
    default:
      return state;
  }
};

export const EpisodeContext = createContext<IEpisodeState | null>(null);
export const EpisodeDispatchContext = createContext<React.Dispatch<EpisodeAction> | null>(null);

const initialState: IEpisodeState = {
  nodes: [],
  isLoading: false,
};

// NOTE: It's still in development
export function EpisodeContextProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(episodeReducer, initialState);

  return (
    <EpisodeContext.Provider value={state}>
      <EpisodeDispatchContext.Provider value={dispatch}>
        {children}
      </EpisodeDispatchContext.Provider>
    </EpisodeContext.Provider>
  );
}

export const useEpisodeContext = () => useContext(EpisodeContext);
export const useEpisodeDispatchContext = () => useContext(EpisodeDispatchContext);
