import React from 'react';
import {
  Button, Col, Dropdown, Row,
} from 'react-bootstrap';
import { defaultExpressionName, emptyKey } from '../CharacterExpressions/constants';
import { ExpressionSelect } from '../ExpressionSelect/ExpressionSelect';
import { ImageArea } from '../ImageArea/ImageArea';
import { ImageAreaPicker } from '../ImageAreaPicker/ImageAreaPicker';
import { ImagePicker } from '../ImagePicker/ImagePicker';
import classNames from './ExpressionCard.module.scss';

/**
 * Expression card with change image, change visible area, change
 * expression select, delete expression
 * @param value - HashMap. Expression
 * @param possibleExpressions - Array. [{id, value, title}]
 * @param onChange(newValue) - Callback. HashMap with new expression
 * @returns {JSX.Element}
 */
export function ExpressionCard({
  value: expressionValueByName,
  possibleExpressions,
  onChange,
  onApplyImageAreaToAll,
}) {
  const expressionName = Object.keys(expressionValueByName)[0];
  const expressionValue = Object.values(expressionValueByName)[0];

  const handleImagePickerChange = (newValue) => {
    const newExpressionValue = {
      ...expressionValue,
      customCharacterId: newValue.customCharacterId,
      imageURL: newValue.imageURL,
    };

    const newExpression = {
      [expressionName]: newExpressionValue,
    };
    onChange(expressionValueByName, newExpression);
  };

  const handleExpressionSelectChange = (newValue) => {
    const newExpression = {
      [newValue]: expressionValue,
    };
    onChange(expressionValueByName, newExpression);
  };

  const handleImageAreaPickerChange = (newValue) => {
    const newExpressionValue = {
      ...expressionValue,
      portraitCoordinates: newValue,
    };

    const newExpression = {
      [expressionName]: newExpressionValue,
    };
    onChange(expressionValueByName, newExpression);
  };

  const handleDeleteExpression = () => {
    onChange(expressionValueByName, null);
  };

  const isExpressionSelectDisabled = expressionName === defaultExpressionName;
  const isImagePickerDisabled = expressionName === emptyKey;
  const isImageAreaPickerDisabled = !expressionValue.imageURL || isImagePickerDisabled;
  const isExpressionDeleteButtonDisabled = expressionName === defaultExpressionName;

  return (
    <div className={classNames.container}>
      <Row className={classNames.body}>
        <Col md="2" className={classNames.button}>
          <ExpressionSelect
            possibleExpressions={possibleExpressions}
            value={expressionName}
            disabled={isExpressionSelectDisabled}
            onChange={handleExpressionSelectChange}
          />
        </Col>

        <Col md="auto" className={classNames.button}>
          <ImagePicker
            title={`Select image for ${expressionName} expression`}
            value={{ customCharacterId: expressionValue?.customCharacterId }}
            disabled={isImagePickerDisabled}
            onChange={handleImagePickerChange}
          />
        </Col>
        <Col md="auto" className={classNames.button}>
          <ImageAreaPicker
            title={`Image area for ${expressionName} expression`}
            imageURL={expressionValue?.imageURL}
            value={expressionValue?.portraitCoordinates}
            disabled={isImageAreaPickerDisabled}
            onChange={handleImageAreaPickerChange}
          />
        </Col>

        <Col lg="auto" className={classNames.avatarContainer}>
          <div className={classNames.avatarCanvas}>
            <ImageArea
              value={expressionValue}
            >
              <Dropdown className="text-right">
                <Dropdown.Toggle
                  variant="secondary"
                  className={classNames.menuButton}
                  size="sm"
                >
                  &#8942;
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => onApplyImageAreaToAll(expressionValue.portraitCoordinates)}
                  >
                    Apply to all
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </ImageArea>
          </div>
        </Col>
        <Col lg="auto">
          <Button
            className={classNames.button}
            variant={isExpressionDeleteButtonDisabled ? 'outline-secondary' : 'outline-danger'}
            disabled={isExpressionDeleteButtonDisabled}
            onClick={handleDeleteExpression}
          >
            Delete
          </Button>
        </Col>
      </Row>
    </div>
  );
}
