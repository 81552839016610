import React from 'react';
import { ApiServiceContext } from 'contexts/ApiServiceContext/ApiServiceContext';
import { EpisodeContext, EpisodeContextProvider, EpisodeDispatchContext } from 'contexts/EpisodeContext/EpisodeContext';
import { MemoryBankService } from '../../services/memoryBankService/MemoryBankService';
import { MemoryBankServiceContext } from '../MemoryBankContext/MemoryBankServiceContext';

type CombinedContextType = {
  MemoryBankService: MemoryBankService;
  aaa: string;
};

interface WithContextProps {
    context: CombinedContextType;
}

// Function that takes a React component and returns a new component with context props
export function StoryBranchesWithContext<T extends WithContextProps = WithContextProps>(
  Component: React.ComponentType<T>,
) {
  // eslint-disable-next-line react/display-name
  return function (props: Omit<T, keyof WithContextProps>) {
    return (
      <EpisodeContextProvider>
        <ApiServiceContext.Consumer>
          {(apiService) => (
            <MemoryBankServiceContext.Consumer>
              {(memoryBankService) => (
                <EpisodeContext.Consumer>
                  {(episodeContext) => (
                    <EpisodeDispatchContext.Consumer>
                      {(episodeDispatchContext) => (
                        <Component
                          {...(props as T)}
                          context={{
                            apiService,
                            memoryBankService,
                            episodeContext,
                            episodeDispatchContext,
                          }}
                        />
                      )}
                    </EpisodeDispatchContext.Consumer>
                  )}
                </EpisodeContext.Consumer>
              )}
            </MemoryBankServiceContext.Consumer>
          )}
        </ApiServiceContext.Consumer>
      </EpisodeContextProvider>
    );
  };
}
