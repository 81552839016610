import React, { PropsWithChildren } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { bugTracker } from '../../../services/bugTracker/BugTrackerService';
import { DefaultFallback } from '../ErrorBoundary/FallBacks/DefaultFallback';
import { DorianLogo } from './DorianLogo';
import styles from './LoginLayout.module.scss';

export function LoginLayout({ children }: PropsWithChildren) {
  const history = useHistory();

  const { ErrorBoundary } = bugTracker();

  return (
    <Container className={styles.container}>
      <div id="errorsBox" />
      <Row>
        <Col className={styles.logoSection}>
          <button
            type="button"
            className={styles.logoSectionButton}
            onClick={() => history.push('/')}
          >
            <DorianLogo fill="#6C74B4" stroke="white" width="5rem" height="5rem" />
            <h1>Dorian</h1>
          </button>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center">
        <Col xl={4} lg={5} md={6} sm={7} xs={12} className="w-100">
          <ErrorBoundary fallback={DefaultFallback}>
            {children}
          </ErrorBoundary>
        </Col>
      </Row>
    </Container>
  );
}
