import classNames from 'classnames/bind';
import { maxBy } from 'lodash-es';
import React, {
  PureComponent, forwardRef, useEffect, useRef, useState,
} from 'react';
import {
  Button, Col, Dropdown, Form, FormControl, InputGroup,
} from 'react-bootstrap';
import { StepTypes } from '../../../../../dorian-shared/types/branch/BranchStep';
import { bugTracker } from '../../../../../services/bugTracker/BugTrackerService';
import { AvatarToolModal } from '../../../../ui/AvatarTool/AvatarToolModal';
import { createGroupsWithCharacters } from '../../../Characters/utils';
import styles from './Steps.scss';
import { SCROLLABLE_STEP_FORM_ELEMENT_ID } from './utils';

const cs = classNames.bind(styles);

function getMinWidthByChildren(children) {
  if (children.length === 0) {
    return '25vw';
  }

  const childWithMaxLength = maxBy(children, (childrenItem) => childrenItem.props.children.length);
  const maxLength = childWithMaxLength.props.children.length / 1.2;

  if (maxLength < 15) {
    return '15vw';
  }
  return `${maxLength < 40 ? maxLength : 40}vw`;
}

const CustomMenu = forwardRef(
  ({
    children, style, className, 'aria-labelledby': labeledBy, onAddNewClick,
  }, ref) => {
    CustomMenu.displayName = 'CustomMenu in character steps';
    const [value, setValue] = useState('');
    const searchRef = useRef();

    useEffect(() => {
      searchRef.current.focus();
    });

    const values = value.toLowerCase().split(' ');
    const minWidth = getMinWidthByChildren(React.Children.toArray(children));

    const filteredChildren = children.filter(
      (child) => !value || values.every((el) => child.props.children.toLowerCase().includes(el)),
    );

    const sortedChildren = filteredChildren.sort(
      (a, b) => a.props.children.localeCompare(b.props.children),
    );

    return (
      <div
        ref={ref}
        style={{
          ...style,
          minWidth,
        }}
        className={className}
        aria-labelledby={labeledBy}
      >
        <div className="mx-3 my-2" style={{ position: 'sticky', top: '2px' }}>
          <InputGroup>
            <FormControl
              ref={searchRef}
              placeholder="Type to filter ..."
              onChange={(e) => setValue(e.target.value)}
              value={value}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                }
              }}
            />
            <InputGroup.Append>
              <Button
                variant="secondary"
                onClick={() => onAddNewClick()}
                className="ml-2 py-2"
                style={{
                  fontSize: '.7em',
                  borderRadius: 0,
                  padding: '0.25em',
                }}
              >
                Add New
              </Button>
            </InputGroup.Append>
          </InputGroup>
        </div>
        <ul className="list-unstyled">
          {sortedChildren}
        </ul>
        {sortedChildren.length === 0 && (
          <div className="text-center">No results found</div>
        )}
      </div>
    );
  },
);

export class StepsTypeFieldsCharacters extends PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      isShowCharacterDropDown: false,
    };
    this.scrollableElement = document.getElementById(SCROLLABLE_STEP_FORM_ELEMENT_ID);
  }

  componentDidUpdate(_, prevState) {
    const { isShowCharacterDropDown } = this.state;
    if (prevState.isShowCharacterDropDown !== isShowCharacterDropDown) {
      this.handleToggleLockBody(isShowCharacterDropDown);
    }
  }

  handleToggleLockBody(isShowCharacterDropDown) {
    const { disabledSortableAction, activeStepsFunc, stepClass } = this.props;
    disabledSortableAction(isShowCharacterDropDown);

    const element = this.scrollableElement;
    if (!element) {
      return;
    }
    if (isShowCharacterDropDown) {
      // Lock body scrolling
      const scrollbarWidth = element.offsetWidth - element.clientWidth;
      element.style.marginRight = `${scrollbarWidth}px`;
      element.style.overflow = 'hidden';

      activeStepsFunc(stepClass);
    } else {
      // Unlock body scrolling
      element.style.overflow = 'auto';
      element.style.marginRight = 0;
    }
  }

  handleToggleDropDown(isOpened, meta) {
    if (isOpened && meta.source === 'click') {
      this.setState({ isShowCharacterDropDown: true });
    }
    if (!isOpened && meta.source === 'rootClose') {
      this.setState({ isShowCharacterDropDown: false });
    }
  }

  handleCharacterSelectKeyDown = (event) => {
    const {
      handleChangeSteps, stepIndex, characters, characterId: currentCharacterId,
    } = this.props;
    const { isShowCharacterDropDown } = this.state;
    if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey || isShowCharacterDropDown) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();
    if (event.key === ' ') {
      this.setState({ isShowCharacterDropDown: true });
      return;
    }

    const sortedCharacters = characters.sort((a, b) => {
      const nameA = `${a.name} (${a.alias})`;
      const nameB = `${b.name} (${b.alias})`;
      return nameA.localeCompare(nameB);
    });

    if (event.key === 'ArrowUp') {
      const locatedCharacter = sortedCharacters.find((character) => character.id === currentCharacterId);
      if (!locatedCharacter) {
        handleChangeSteps(sortedCharacters[0].id, stepIndex, 'characterId');
        return;
      }
      const index = sortedCharacters.indexOf(locatedCharacter);
      if (index !== 0) {
        handleChangeSteps(sortedCharacters[index - 1].id, stepIndex, 'characterId');
        return;
      }
    }

    if (event.key === 'ArrowDown') {
      const locatedCharacter = sortedCharacters.find((character) => character.id === currentCharacterId);
      if (!locatedCharacter) {
        handleChangeSteps(sortedCharacters[sortedCharacters.length - 1].id, stepIndex, 'characterId');
        return;
      }
      const index = sortedCharacters.indexOf(locatedCharacter);
      if (index !== sortedCharacters.length - 1) {
        handleChangeSteps(sortedCharacters[index + 1].id, stepIndex, 'characterId');
        return;
      }
    }

    try {
      const filteredCharacters = sortedCharacters.filter(
        (character) => character.name.toLowerCase()
          .startsWith(event.key.toLowerCase()),
      );

      if (filteredCharacters.length === 0) {
        return;
      }

      const currentIndex = filteredCharacters.findIndex((character) => character.id === currentCharacterId);

      if (currentIndex === -1) {
        handleChangeSteps(filteredCharacters[0].id, stepIndex, 'characterId');
        return;
      }

      if (currentIndex === filteredCharacters.length - 1) {
        handleChangeSteps(filteredCharacters[0].id, stepIndex, 'characterId');
        return;
      }

      const slicedCharacters = filteredCharacters.slice(currentIndex + 1);
      handleChangeSteps(slicedCharacters[0].id, stepIndex, 'characterId');
    } catch (e) {
      bugTracker().reportError(e);
    }
  };

  render() {
    const {
      stepType,
      disabled,
      handleChangeSteps,
      story,
      stepIndex,
      displayControl,
      update,
      characters,
      characterId,
    } = this.props;
    const { show, isShowCharacterDropDown } = this.state;

    if (displayControl !== true) {
      return null;
    }
    const charactersGroups = createGroupsWithCharacters(characters);
    const characterSelected = characters.find(
      (character) => Number(character.id) === Number(characterId),
    );

    return (
      <>
        <Form.Group
          md={stepType === StepTypes.Reaction ? null : 2}
          as={Col}
          controlId={`stepCharacterId${stepIndex}`}
          className={cs('mb-0 px-1', 'd-block')}
        >
          <Dropdown
            className="characterExpressionDropdown"
            onToggle={(isOpened, _, meta) => this.handleToggleDropDown(isOpened, meta)}
            show={isShowCharacterDropDown}
          >
            <Dropdown.Toggle
              disabled={disabled}
              size="sm"
              title={characterSelected?.alias ?? ''}
              onKeyDown={this.handleCharacterSelectKeyDown}
            >
              {characterSelected ? `${characterSelected.name} (${characterSelected.alias})` : 'Select character'}
            </Dropdown.Toggle>
            <Dropdown.Menu
              as={CustomMenu}
              className="w-100 mw-75 overflow-auto"
              onAddNewClick={() => { this.setState({ show: true }); }}
            >
              {characters.map((character) => (
                <Dropdown.Item
                  key={character.id}
                  active={character.id === characterId}
                  eventKey={character.id}
                  onMouseDown={() => {
                    handleChangeSteps(character.id, stepIndex, 'characterId');
                    this.setState({ isShowCharacterDropDown: false });
                  }}
                >
                  {`${character.name} (${character.alias})`}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          <input
            type="hidden"
            name={`steps[${stepIndex}][characterId]`}
            value={characterId ?? ''}
          />
        </Form.Group>

        <AvatarToolModal
          charactersGroups={charactersGroups}
          show={show}
          title={story.title}
          bookid={story.bookId}
          update={(val) => {
            update(val);
            this.setState({
              show: false,
            });
            const e = {};
            e.target = {};
            e.target.value = val.id;
            handleChangeSteps(e.target.value, stepIndex, 'characterId');
          }}
          onHide={() => {
            this.setState({
              show: false,
            });
          }}
        />
      </>
    );
  }
}
