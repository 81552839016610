export const emptyKey = 'empty';
export const defaultExpressionName = 'neutral';
export const defaultExpressionValue = {
  imageURL: '',
  portraitCoordinates: {
    x: 240,
    y: 100,
    size: 350,
  },
};
