import React from 'react';
import { FileToUpload, LibraryType, ManageListCallbacks } from '../types';
import { UploadFilesBackgroundItem } from './UploadFilesItem/UploadFilesBackgroundItem/UploadFilesBackgroundItem';
import { UploadFilesCharacterItem } from './UploadFilesItem/UploadFilesCharacterItem/UploadFilesCharacterItem';
import classNames from './UploadFilesModalList.module.scss';

interface UploadFilesListProps {
    libraryType: LibraryType;
    filesToUpload: FileToUpload[];
    manageListCallbacks: ManageListCallbacks;
    isLoading?: boolean;
    isUploadFinished?: boolean;
}

export function UploadFilesList(props: UploadFilesListProps) {
  const {
    filesToUpload, manageListCallbacks, isLoading, isUploadFinished, libraryType,
  } = props;

  return (
    <ul className={classNames.filesList}>
      {libraryType === LibraryType.Background && filesToUpload.map((fileToUpload) => (
        <UploadFilesBackgroundItem
          key={fileToUpload.aliasName}
          fileToUpload={fileToUpload}
          manageListCallbacks={manageListCallbacks}
          isLoading={isLoading}
          disabled={isUploadFinished}
        />
      ))}
      {libraryType === LibraryType.Character && filesToUpload.map((fileToUpload) => (
        <UploadFilesCharacterItem
          key={fileToUpload.aliasName}
          fileToUpload={fileToUpload}
          manageListCallbacks={manageListCallbacks}
          isLoading={isLoading}
          disabled={isUploadFinished}
        />
      ))}
    </ul>
  );
}
