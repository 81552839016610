import isEqual from 'lodash-es/isEqual';
import {
  CardMemorySlotData, MemoryDTO, MemoryFormDTO, MemoryShowIn, MemoryValue, StepCheck,
} from './memoryBankTypes';

export function getMemoryBankSlotDataById(
  memoryBank: MemoryDTO[] | null,
  checkData?: StepCheck,
): CardMemorySlotData | null {
  if (!memoryBank || !checkData) {
    return null;
  }
  const slot = memoryBank.find(
    (memoryBankSlot) => memoryBankSlot.id === Number(checkData.variableId),
  );

  if (!slot) {
    return null;
  }

  return {
    name: slot.name,
    value: checkData.value as MemoryValue,
    operator: checkData.operator,
  };
}

export function convertMemoryDTOToFormDTO(memoryDTO: MemoryDTO): MemoryFormDTO {
  return {
    showIn: [...memoryDTO.showIn], // Need make a new object to avoid mutation. Used to compare if the value has changed.
    id: memoryDTO.id,
    type: memoryDTO.type,
    name: memoryDTO.name,
    value: memoryDTO.defaultValue,
    bookId: memoryDTO.bookId,
    displayName: memoryDTO.displayName,
    defaultChangeDescription: memoryDTO.defaultChangeDescription,
    icon: memoryDTO.icon,
    showInAlert: memoryDTO.showIn?.includes(MemoryShowIn.Alert) ?? false,
    showInPanel: memoryDTO.showIn?.includes(MemoryShowIn.Panel) ?? false,
  };
}

export function convertMemoryFormDTOToMemoryDTO(memoryFormDTO: MemoryFormDTO): MemoryDTO {
  return {
    id: memoryFormDTO.id,
    type: memoryFormDTO.type,
    name: memoryFormDTO.name,
    defaultValue: memoryFormDTO.value,
    bookId: memoryFormDTO.bookId,
    displayName: memoryFormDTO.displayName,
    defaultChangeDescription: memoryFormDTO.defaultChangeDescription,
    icon: memoryFormDTO.icon,
    showIn: [...memoryFormDTO.showIn], // Need make a new object to avoid mutation. Used to compare if the value has changed.
  };
}

export function isMemoryDTOEqual(memoryDTOA: MemoryDTO, memoryDTOB: MemoryDTO) {
  return isEqual(memoryDTOA, memoryDTOB);
}
