import cs from 'classnames';
import { FormikErrors } from 'formik';
import React from 'react';
import {
  Button, Form, Modal, Spinner,
} from 'react-bootstrap';
import { useMemoryBankForm } from './hooks/useMemoryBankForm';
import classes from './MemoryBank.module.scss';
import { MemoryFormDTO } from './memoryBankTypes';
import { MemoryForm } from './MemoryForm';

type MemoryBankFormProps = {
  onHide: () => void,
  bookId: number,
  userId: number,
}

export function MemoryBankForm(props: MemoryBankFormProps) {
  const { onHide, bookId, userId } = props;

  const {
    characters,
    isLoading,
    handleSubmit,
    values,
    errors,
    handleMemoryChange,
    handleMemoryDelete,
    handleMemoryCreate,
    handleMemoryIconAdd,
    memoryIcons,
  } = useMemoryBankForm(bookId, userId, onHide);

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>
            Memory Bank
          </Modal.Title>
        </Modal.Header>

        <Modal.Body
          className={classes.memoryBankModalBody}
        >
          <Form.Row className={classes.memoryBankTopPanel}>
            <Form.Label>
              Memory:
            </Form.Label>
            <Button
              size="sm"
              variant="secondary"
              onClick={handleMemoryCreate}
              className={classes.memoryBankAddSlotButton}
            >
              + Add memory
            </Button>
          </Form.Row>

          {values.memoryBankSlots.map((memoryFormDTO, memoryIndex) => {
            const memoryFieldErrors = errors.memoryBankSlots?.[memoryIndex] ?? {};
            return (
              <MemoryForm
                key={memoryFormDTO.id}
                memoryIndex={memoryIndex}
                memoryFormDTO={memoryFormDTO}
                onChange={handleMemoryChange}
                onDelete={handleMemoryDelete}
                errors={memoryFieldErrors as FormikErrors<MemoryFormDTO>}
                characters={characters ?? []}
                memoryIcons={memoryIcons ?? []}
                onIconAdd={handleMemoryIconAdd}
              />
            );
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button
            size="sm"
            type="reset"
            variant="secondary"
            onClick={onHide}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            size="sm"
            type="submit"
            variant="primary"
            disabled={isLoading}
          >
            Save
          </Button>
        </Modal.Footer>
      </Form>
      {isLoading
        && (
          <>
            <div className={cs({ overlay: isLoading })} />
            <Spinner
              variant="primary"
              animation="border"
              className="spinner"
            />
          </>
        )}
    </>
  );
}
